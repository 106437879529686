import React from "react";
import img1 from '../assets/images/faqs.webp'

const Faqs1 = () => {
    return (
        <>
            <div className="pb-5" style={{background: 'linear-gradient(359deg, rgba(224, 252, 228, 0.54) 37.45%, rgba(232, 237, 233, 0) 92.02%) no-repeat'}}>
                <div class="container">
                    <div className="row pt-5">
                        <div className="col-md-6">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item" style={{marginBottom: '10px', borderRadius: '4px', outline: 'none'}}>
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button f-16" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{fontWeight: '600', fontSize: '20px', color: 'rgb(28, 70, 26)', border: '1px solid #37A033'}}>
                                            What is Kivisa and what does it offer?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Kivisa is an Agri Tech platform that offers a suite of digital solutions tailored for the agricultural sector. Our platform provides tools for precision farming, data analytics, crop monitoring, and predictive insights to empower farmers and stakeholders in the agricultural value chain.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item" style={{marginBottom: '10px', borderRadius: '4px', outline: 'none'}}>
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed f-16" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{fontWeight: '600', fontSize: '20px', color: 'rgb(28, 70, 26)', border: '1px solid #37A033'}}>
                                            How can Kivisa benefit farmers?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Kivisa is designed to benefit farmers by offering data-driven insights that improve decision-making, increase efficiency, and optimise crop yields. Our tools help farmers make informed choices, thereby enhancing their overall productivity and sustainability.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item" style={{marginBottom: '10px', borderRadius: '4px', outline: 'none'}}>
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed f-16" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style={{fontWeight: '600', fontSize: '20px', color: 'rgb(28, 70, 26)', border: '1px solid #37A033'}}>
                                            Is Kivisa suitable for all types of farming operations?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Yes, Kivisa caters to a wide range of farming operations, from small-scale to large commercial setups. Our platform's flexible solutions are customizable to meet the diverse needs of various agricultural practices and crops</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6" style={{position: 'relative'}}>
                            <div className="position-for-mobile" style={{textAlign: 'right', paddingLeft: '40px', top: '24px', left: '34px', position: 'absolute'}}>
                                <div class="primary-color hover-effect" style={{ border: '1px solid #37A033', width: 'max-content', padding: '2px 12px', borderRadius: '4px', color: '#1C461A', fontWeight: '800', float: 'right' }}>FAQ</div>
                                <h3 style={{color: '#1C461A', fontWeight: '800', marginBottom: '5px', marginTop: '50px'}}>General</h3>
                                <h3 style={{color: '#37A033', fontWeight: '800'}}>Questions ?</h3>
                            </div>
                            <img className="faqs-image" src={img1} style={{ maxWidth: '280px', float: 'right' }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Faqs1