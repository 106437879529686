import React from 'react'


const Roadmap = () => {
    return (
        <>
            <div class="title">
                <h2>Responsive Vertical Timeline</h2>
                <p><a href="https://htmlcodex.com/css-timeline">More timeline designs</a></p>
            </div>
            <div class="timeline">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="timeline-container">
                                <div class="timeline-end">
                                    <p>Now</p>
                                </div>
                                <div class="timeline-continue">

                                    <div class="row timeline-right">
                                        <div class="col-md-6">
                                            <p class="timeline-date">
                                                01 Jun 2020
                                            </p>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="timeline-box">
                                                <div class="timeline-icon">
                                                    <i class="fa fa-gift"></i>
                                                </div>
                                                <div class="timeline-text">
                                                    <h3>Lorem ipsum dolor</h3>
                                                    <p>Lorem ipsum dolor sit amet elit ornare velit non</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row timeline-left">
                                        <div class="col-md-6 d-md-none d-block">
                                            <p class="timeline-date">
                                                01 Jan 2020
                                            </p>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="timeline-box">
                                                <div class="timeline-icon d-md-none d-block">
                                                    <i class="fa fa-business-time"></i>
                                                </div>
                                                <div class="timeline-text">
                                                    <h3>Lorem ipsum dolor</h3>
                                                    <p>Lorem ipsum dolor sit amet elit ornare velit non</p>
                                                </div>
                                                <div class="timeline-icon d-md-block d-none">
                                                    <i class="fa fa-business-time"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 d-md-block d-none">
                                            <p class="timeline-date">
                                                01 Jan 2020
                                            </p>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <div class="timeline-year">
                                                <p>2020</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row timeline-right">
                                        <div class="col-md-6">
                                            <p class="timeline-date">
                                                01 Dec 2019
                                            </p>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="timeline-box">
                                                <div class="timeline-icon">
                                                    <i class="fa fa-briefcase"></i>
                                                </div>
                                                <div class="timeline-text">
                                                    <h3>Lorem ipsum dolor</h3>
                                                    <p>Lorem ipsum dolor sit amet elit ornare velit non</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row timeline-left">
                                        <div class="col-md-6 d-md-none d-block">
                                            <p class="timeline-date">
                                                01 Sep  2019
                                            </p>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="timeline-box">
                                                <div class="timeline-icon d-md-none d-block">
                                                    <i class="fa fa-cogs"></i>
                                                </div>
                                                <div class="timeline-text">
                                                    <h3>Lorem ipsum dolor</h3>
                                                    <p>Lorem ipsum dolor sit amet elit ornare velit non</p>
                                                </div>
                                                <div class="timeline-icon d-md-block d-none">
                                                    <i class="fa fa-cogs"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 d-md-block d-none">
                                            <p class="timeline-date">
                                                01 Sep  2019
                                            </p>
                                        </div>
                                    </div>

                                    <div class="row timeline-right">
                                        <div class="col-md-6">
                                            <p class="timeline-date">
                                                01 Jun 2019
                                            </p>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="timeline-box">
                                                <div class="timeline-icon">
                                                    <i class="fa fa-running"></i>
                                                </div>
                                                <div class="timeline-text">
                                                    <h3>Lorem ipsum dolor</h3>
                                                    <p>Lorem ipsum dolor sit amet elit ornare velit non</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row timeline-left">
                                        <div class="col-md-6 d-md-none d-block">
                                            <p class="timeline-date">
                                                01 Mar 2019
                                            </p>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="timeline-box">
                                                <div class="timeline-icon d-md-none d-block">
                                                    <i class="fa fa-home"></i>
                                                </div>
                                                <div class="timeline-text">
                                                    <h3>Lorem ipsum dolor</h3>
                                                    <p>Lorem ipsum dolor sit amet elit ornare velit non</p>
                                                </div>
                                                <div class="timeline-icon d-md-block d-none">
                                                    <i class="fa fa-home"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 d-md-block d-none">
                                            <p class="timeline-date">
                                                01 Mar 2019
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="timeline-start">
                                    <p>Launch</p>
                                </div>
                                <div class="timeline-launch">
                                    <div class="timeline-box">
                                        <div class="timeline-text">
                                            <h3>Launched our company on 01 Jan 2019</h3>
                                            <p>Lorem ipsum dolor sit amet</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Roadmap