import React from 'react'
import img1 from '../assets/images/smart4.webp'
import img2 from '../assets/images/smart2.webp'
import img3 from '../assets/images/smart1.webp'
import img4 from '../assets/images/smart3.webp'
import img5 from '../assets/images/what-we-do.webp'
import leave from '../assets/images/leaves1.webp'

const Products1 = () => {
    return (
        <>
            <div className='position-relative' style={{backgroundColor: '#E0FCE4', overflowX: 'hidden', overflowY: 'hidden'}}>
                <div className='container'>
                    <div className='row' style={{ paddingTop: '100px' }}>
                        <div className='col-md-5'>
                            <div class="left-info text-left">
                                <h5 class="secondary-color">What we do</h5>
                                <h2 class="title secondary-color mb-0">Currently we are</h2>
                                <h3 class="primary-color" style={{fontWeight: '600', fontSize: '40px'}}>Selling Agriculture Products</h3>
                            </div>
                        </div>
                        <div className='col-md-7 position-relative'>
                            <div class="right-info text-left">
                                <img src={leave} style={{position: 'absolute', right: '0'}} />
                                <p>The primary goal of an Agri Tech Platform is to revolutionise agriculture by integrating technology and data-driven solutions to increase efficiency, productivity, and sustainability for farmers while connecting them to markets and valuable resources.</p>
                                <a class="btn btn-theme btn-md radius animation" href="#">Discover More</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row' style={{ paddingTop: '50px' }}>
                        <div className='col-md-3 d-flex align-items-stretch'>
                            <div class="card change-padding-css">
                                <div className='' style={{paddingLeft: '20px', paddingRight: '20px'}}>
                                    <img class="card-img-top" src={img3} alt="Card image cap" />
                                </div>
                                <div class="card-body">
                                    <h4 class="primary-color mb-1 text-center" style={{fontWeight: '800'}}>Smart Resource Management</h4>
                                    <p class="primary-color" style={{ fontSize: "14px", marginBottom: '0', paddingTop: '10px' }}>Providing tools for efficient resource allocation, including water, fertilisers, and pesticides, to minimise waste and maximise productivity while ensuring sustainability.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 d-flex align-items-stretch'>
                            <div class="card change-padding-css">
                                <div className='' style={{paddingLeft: '20px', paddingRight: '20px'}}>
                                    <img class="card-img-top" src={img2} alt="Card image cap" />
                                </div>
                                <div class="card-body">
                                    <h4 class="primary-color mb-1 text-center" style={{fontWeight: '800'}}>Data-Driven Insights</h4>
                                    <p className='primary-color' style={{ fontSize: '14px', marginBottom: '0', paddingTop: '10px' }}>
                                        Offering data analytics and predictive models to provide farmers with valuable insights for decision-making, including weather forecasting, soil health analysis, crop monitoring, and yield predictions.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 d-flex align-items-stretch'>
                            <div class="card change-padding-css">
                                <div className='' style={{paddingLeft: '20px', paddingRight: '20px'}}>
                                    <img class="card-img-top" src={img4} alt="Card image cap" />
                                </div>
                                <div class="card-body">
                                    <h4 class="primary-color mb-1 text-center" style={{fontWeight: '800'}}>Technology Integration</h4>
                                    <p className='primary-color' style={{ fontSize: '14px', marginBottom: '0', paddingTop: '10px' }}>
                                        Introducing and implementing the new technologies to enhance the precision farming and also the streamline agricultural processes.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 d-flex align-items-stretch'>
                            <div class="card change-padding-css">
                                <div className='' style={{paddingLeft: '20px', paddingRight: '20px'}}>
                                    <img class="card-img-top" src={img1} alt="Card image cap" />
                                </div>
                                <div class="card-body">
                                    <h4 class="primary-color mb-1 text-center" style={{fontWeight: '800'}}>Education and Community Building</h4>
                                    <p className='primary-color' style={{ fontSize: '14px', marginBottom: '0', paddingTop: '' }}>
                                        Providing educational resources, forums, and a community platform to share best practices, fostering collaboration and knowledge-sharing among farmers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row no-margin-mobile' style={{ marginTop: '-160px' }}>
                    <img src={img5} className='hide-mobile' />
                </div>
            </div>
        </>
    )
}
export default Products1