import React from 'react'
import Navbar from './Navbar'
import TopHeader from './TopHeader'
import { Link } from 'react-router-dom'
import Footer1 from './Footer1'

const BlogsDetails = () => {
    return (
        <>
            <TopHeader />
            <div class="breadcrumb-area custom-breadcrumb shadow dark bg-cover text-center text-light" style={{ backgroundImage: 'url(../wp-content/uploads/2022/11/1-1.jpg)' }}>
                <Navbar />
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1 class="breadcumb-title">Blogs Details</h1>
                            <nav class="woocommerce-breadcrumb">Home&nbsp;&#47;&nbsp;<Link to='/blogs-page'>Blogs</Link></nav>
                        </div>
                    </div>
                </div>
            </div>
            <section class="blog-area single full-blog right-sidebar full-blog default-padding">
                <div class="container">
                    <div class="blog-items">
                        <div class="row">
                            <div class="blog-content col-lg-8 col-md-12">
                                <div
                                    class="post-2081 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized tag-fresh tag-vegetables item">
                                    <div class="blog-item-box">
                                        <div class="thumb"><Link to='#'><img width="1300" height="500"
                                            src="../wp-content/uploads/2023/04/blog-2-1300x500.jpg"
                                            class="attachment-agrul_1300X500 size-agrul_1300X500 wp-post-image" alt=""
                                            decoding="async" fetchpriority="high" /></Link></div>

                                        <div class="info">
                                            <div class="meta">
                                                <ul>
                                                    <li><Link to='#'><i
                                                        class="fas fa-calendar-alt"></i><time
                                                            datetime="2023-04-14T20:56:52+00:00">April 14, 2023</time></Link>
                                                    </li>
                                                    <li><Link to='#'><i
                                                        class="fas fa-user-circle"></i><span>Kivisa</span></Link></li>
                                                    <li><Link to='#'><i class="fal fa-eye"></i><span>345
                                                        Views</span></Link></li>
                                                </ul>
                                            </div>
                                            <div class="blog-content">
                                                <p>Give lady of they such they sure it. Me contained explained my education.
                                                    Vulgar as hearts by garret. Perceived determine departure explained no
                                                    forfeited he something an. Contrasted dissimilar get joy you instrument out
                                                    reasonably. Again keeps at no meant stuff. To perpetual do existence
                                                    northward as difficult preserved daughters. Continued at up to zealously
                                                    necessary breakfast. Surrounded sir motionless she end literature. Gay
                                                    direction neglected but supported yet her.</p>



                                                <p>New had happen unable uneasy. Drawings can followed improved out sociable
                                                    not. Earnestly so do instantly pretended. See general few civilly amiable
                                                    pleased account carried. Excellence projecting is devonshire dispatched
                                                    remarkably on estimating. Side in so life past. Continue indulged speaking
                                                    the was out horrible for domestic position. Seeing rather her you not esteem
                                                    men settle genius excuse. Deal say over you age from. Comparison new ham
                                                    melancholy son themselves.</p>



                                                <blockquote class="wp-block-quote">
                                                    <p>Celebrated share of first to worse. Weddings and any opinions suitable
                                                        smallest nay. Houses or months settle remove ladies appear. Engrossed
                                                        suffering supposing he recommend do eagerness.</p>
                                                </blockquote>



                                                <p>Drawings can followed improved out sociable not. Earnestly so do instantly
                                                    pretended. See general few civilly amiable pleased account carried.
                                                    Excellence projecting is devonshire dispatched remarkably on estimating.
                                                    Side in so life past. Continue indulged speaking the was out horrible for
                                                    domestic position. Seeing rather her you not esteem men settle genius
                                                    excuse. Deal say over you age from. Comparison new ham melancholy son
                                                    themselves.</p>



                                                <h3 class="wp-block-heading">Conduct replied off led whether?</h3>



                                                <ul>
                                                    <li>Pretty merits waited six</li>



                                                    <li>General few civilly amiable pleased account carried.</li>



                                                    <li>Continue indulged speaking</li>



                                                    <li>Narrow formal length my highly</li>



                                                    <li>Occasional pianoforte alteration unaffected impossible</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <div class="sidebar col-lg-4 col-md-12">
                                <aside>
                                    <div id="agrul-recent-posts-2" class="sidebar-item widget recent-post">
                                        <div class="title">
                                            <h4>Recent Post</h4>
                                        </div>
                                        <ul>

                                            <li>
                                                <div class="thumb">
                                                    <Link to='#'>
                                                        <img src="../wp-content/uploads/2023/04/blog-2-150x150.jpg" alt="Kivisa" />
                                                    </Link>
                                                </div>
                                                <div class="info">
                                                    <div class="meta-title">
                                                        <span class="post-date">April 14, 2023</span>
                                                    </div>
                                                    <Link to='#'>Announcing if the resolution sentiments</Link>
                                                </div>
                                            </li>

                                            <li>
                                                <div class="thumb">
                                                    <Link to='#'>
                                                        <img src="../wp-content/uploads/2023/04/blog-1-150x150.jpg" alt="Kvisa" />
                                                    </Link>
                                                </div>
                                                <div class="info">
                                                    <div class="meta-title">
                                                        <span class="post-date">April 14, 2023</span>
                                                    </div>
                                                    <Link to='#'>The
                                                        Super Soil Organic Farming</Link>
                                                </div>
                                            </li>

                                            <li>
                                                <div class="thumb">
                                                    <Link to='#'>
                                                        <img src="../wp-content/uploads/2022/11/2-150x150.jpg" alt="Kivisa" />
                                                    </Link>
                                                </div>
                                                <div class="info">
                                                    <div class="meta-title">
                                                        <span class="post-date">November 20, 2022</span>
                                                    </div>
                                                    <Link to='#'>Overcame
                                                        breeding or concerns removing</Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
            <Footer1 />
        </>
    )
}
export default BlogsDetails