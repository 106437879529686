import React from 'react'
import Home from './Home'
import $ from 'jquery'
import logo from '../assets/images/Logo.png'
import { Link } from 'react-router-dom'
const Sidebar = () => {

    const toggleSidebar = (e) => {
        e.preventDefault()

        $('.dashboard-nav').toggle()
    }
    const openMenu = (e) => {
        e.preventDefault()
        var check = e.target
        var check1 = ($(check).parent().find('.dashboard-nav-dropdown-menu').toggle())
    }
    return (
        <>
            <div class='dashboard'>
                <div class="dashboard-nav">
                    <header><a href="#!" class="menu-toggle" onClick={toggleSidebar}>
                        <div className='toggle-buttons'>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a><a href="#"
                        class="brand-logo"> <span><img src={logo} style={{ width: '140px' }} /></span></a></header>
                    <nav class="dashboard-nav-list">
                        <div className='d-flex'>
                            <Link to="/register" class="dashboard-nav-item">
                                <i class="fas fa-user"></i>
                                Register
                            </Link>
                            <Link to="/login" class="dashboard-nav-item">
                                <i class="fas fa-user"></i>
                                Login
                            </Link>
                        </div>
                        <div class="nav-item-divider"></div>
                        <div class='dashboard-nav-dropdown'>
                            <a href="#!" class="dashboard-nav-item dashboard-nav-dropdown-toggle" onClick={openMenu}>
                                <i class="fas fa-photo-video"></i>
                                Company
                            </a>
                            <div class='dashboard-nav-dropdown-menu'>
                                <Link to="/about-us" class="dashboard-nav-dropdown-item">About Us</Link>
                                {/* <Link to="#" class="dashboard-nav-dropdown-item">Objective</Link> */}
                                <Link to="/team" class="dashboard-nav-dropdown-item">Team</Link>
                                <Link to="/mission" class="dashboard-nav-dropdown-item">Mission</Link>
                            </div>
                        </div>
                        <div class='dashboard-nav-dropdown'>
                            <a href="#!" class="dashboard-nav-item dashboard-nav-dropdown-toggle" onClick={openMenu}>
                                <i
                                    class="fas fa-users"></i>
                                Solutions
                            </a>
                            <div class='dashboard-nav-dropdown-menu'>
                                <Link to="/farmers" class="dashboard-nav-dropdown-item">Farmers</Link>
                                <Link to="/fpos" class="dashboard-nav-dropdown-item">FPOs</Link>
                                <Link to="/fcos" class="dashboard-nav-dropdown-item">FCOs</Link>
                                {/* <Link to="/about-us" class="dashboard-nav-dropdown-item">Traders</Link> */}
                            </div>
                        </div>
                        {/* <div class='dashboard-nav-dropdown'>
                            <a href="#!" class="dashboard-nav-item dashboard-nav-dropdown-toggle" onClick={openMenu}>
                                <i class="fas fa-money-check-alt"></i>
                                Services
                            </a>
                            <div class='dashboard-nav-dropdown-menu'>
                                <a href="#" class="dashboard-nav-dropdown-item">All</a>
                                <a href="#" class="dashboard-nav-dropdown-item">Recent</a>
                                <a href="#" class="dashboard-nav-dropdown-item"> Projections</a>
                            </div>
                        </div> */}
                        <Link to="/careers" class="dashboard-nav-item">
                            <i class="fas fa-cogs"></i> Careers
                        </Link>
                        <Link to="/blogs-page" class="dashboard-nav-item">
                            <i class="fas fa-user"></i>
                            Blogs
                        </Link>
                    </nav>
                </div>
            </div>
        </>
    )
}
export default Sidebar