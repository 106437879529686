import React, { Suspense } from 'react'
import img1 from '../assets/images/organic-farming.webp'
import img2 from '../assets/images/section3b.jpg'
import img3 from '../assets/images/section3c.jpg'
import img4 from '../assets/images/section3d.jpg'
import img5 from '../assets/images/support4.webp'
import img6 from '../assets/images/support3.webp'
import img7 from '../assets/images/support1.png'
import img8 from '../assets/images/support2.png'
import img9 from '../assets/images/support3.png'
import LazyLoad from 'react-lazy-load';
const FourthPanel = () => {
    return (
        <>
            <section className='mt-4 pt-4 mb-4 pb-5'>
                <div className='container-fluid'>
                    <div className='reponsive-background' loading='lazy' style={{ backgroundImage: 'url(' + img1 + ')', padding: '25px', borderRadius: '25px' }}>
                        <div className='text-center'>
                            <div style={{ textAlign: 'center', border: '1px solid white', borderRadius: '25px', width: '200px', height: '40px', color: 'white', fontWeight: '600', fontSize: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>Our Services</div>
                        </div>
                        <h3 className='mt-3 mobile-text' style={{ color: 'white ' }}>Cultivating tradition, embracing innovation: Bridging the gap with agri-tech solutions.</h3>
                        <div className='row'>
                            <div className='col-md-7'>
                                <div className='card mt-4 zoom-on-hover services-tabs' style={{padding: '0 0px 0 20px'}}>
                                    <div className=''>
                                        <div className='row'>
                                            <div className='col-md-7'>
                                                <div className='text-left' style={{paddingTop: '10px'}}>
                                                    <img src={img3} style={{ height: '40px', width: '40px' }} />
                                                </div>
                                                <h3 className='primary-color text-left mt-2' style={{ fontWeight: '700' }}>Agri Input</h3>
                                                <h6 className='f-14' style={{ color: 'rgba(17, 46, 16, 0.76)', textAlign: 'justify' }}>With Agri Input solutions at your fingertips, Kivisa equips farmers with the tools to maximise crop quality, minimise input wastage, and adapt to changing conditions for a more resilient and prosperous agricultural future</h6>
                                            </div>
                                            <div className='col-md-5' style={{ backgroundImage: 'url(' + img5 + ')', backgroundRepeat: 'no-repeat', backgroundSize: '273px' }}>
                                                {/* <img src={img6} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-5'>
                                <div className='card zoom-on-hover mt-4' style={{padding: '0 0px 0 20px'}}>
                                    <div className='row'>
                                        <div className='col-md-6 services-tabs'>
                                            <div className='text-left' style={{paddingTop: '10px'}}>
                                                <img src={img2} style={{ height: '40px', width: '40px' }} />
                                            </div>
                                            <h3 className='primary-color text-left mt-2' style={{ fontWeight: '700' }}>Agri Output</h3>
                                            <h6 className='text-left f-14' style={{ color: 'rgba(17, 46, 16, 0.76)', marginBottom: '65px' }}>Elevating agri output through technology</h6>
                                        </div>
                                        <div className='col-md-6' style={{ backgroundImage: 'url(' + img6 + ')', backgroundRepeat: 'no-repeat', backgroundSize: '273px', marginLeft: '-11px' }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-md-4 mb-mobile'>
                                <div className='card zoom-on-hover mt-4 h-100' style={{padding: '0 10px'}}>
                                    <div className='row'>
                                        <div className='col-md-12 services-tabs'>
                                            <div className='text-left'>
                                                <img src={img7} className='position-absolute' style={{ right: '0', top: '0', opacity: '0.2', maxHeight: '200px' }} />
                                            </div>
                                            <h3 className='primary-color text-left mt-4' style={{ fontWeight: '700' }}>Soil Health & Fertility</h3>
                                            <h6 className='text-left f-14' style={{ color: 'rgba(17, 46, 16, 0.76)' }}>With a focus on soil health and fertility, our platform offers tailored insights and recommendations, empowering farmers to make informed decisions, leading to healthier soils, increased productivity, and long-term agricultural sustainability</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mb-mobile'>
                                <div className='card zoom-on-hover mt-4 h-100' style={{padding: '0 10px'}}>
                                    <div className='row'>
                                        <div className='col-md-12 services-tabs'>
                                            <div className='text-left'>
                                                <img src={img8} className='position-absolute' style={{ right: '0', top: '0', opacity: '0.2', maxHeight: '200px' }} />
                                            </div>
                                            <h3 className='primary-color text-left mt-4' style={{ fontWeight: '700' }}>Yield Forecast</h3>
                                            <h6 className='text-left f-14' style={{ color: 'rgba(17, 46, 16, 0.76)' }}>Accurate yield forecasts provided through our platform enabling the farmers to anticipate the market demands, mitigate risks, and optimise production, fostering economic stability and the efficiency in agricultural operations.</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='card zoom-on-hover mt-4 h-100' style={{padding: '0 10px'}}>
                                    <div className='row'>
                                        <div className='col-md-12 services-tabs'>
                                            <div className='text-left'>
                                                <img src={img9} className='position-absolute' style={{ right: '0', top: '0', opacity: '0.2', maxHeight: '200px' }} />
                                            </div>
                                            <h3 className='primary-color text-left mt-4' style={{ fontWeight: '700' }}>Market Access</h3>
                                            <h6 className='text-left f-14' style={{ color: 'rgba(17, 46, 16, 0.76)' }}> Kivisa’s market access tools empower farmers by providing the broader market visibility and the direct connections, allowing farmers to diversify their sales channels, reach larger consumer bases, and gain fair value for their produce. </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default FourthPanel