import React from 'react'
import img1 from '../assets/images/app.png'
import img2 from '../assets/images/mobile.png'
import img3 from '../assets/images/download-1.png'
import img4 from '../assets/images/download-2.png'

const DownloadApplication = () => {
    return (
        <>
            <section className='pt-4 my-4 pb-4 responsive-background' style={{ backgroundImage: 'url(' + img1 + ')', borderRadius: '0'}}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-7' style={{margin: 'auto'}}>
                            <h2 className='text-right pt-5' style={{color: 'white'}}>Explore The Future of Farming With Our App.</h2>
                            <h4 className='text-right mt-4' style={{color: 'white'}}>Start your 30-day free trial today.</h4>
                            <div className='d-flex mt-4 justify-content-end'>
                                <img className='download-button mx-4' src={img3} style={{width: '150px', cursor: 'pointer'}} />
                                <img className='download-button' src={img4} style={{width: '150px', cursor: 'pointer'}} />
                                {/* <button className='mx-4 btn btn-theme animation download-btn' style={{ borderRadius: '4px', maxWidth: '150px', lineHeight: '16px'}}><span style={{fontSize: '12px'}}>Get it on</span> Google Play</button>
                                <button className='btn btn-theme animation download-btn' style={{ borderRadius: '4px', maxWidth: '150px', lineHeight: '16px'}}><span style={{fontSize: '12px'}}>Download on</span> App Store</button> */}
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <img src={img2} style={{maxHeight: '480px'}} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default DownloadApplication