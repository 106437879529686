import React from 'react'


const Blank = () => {
    return (
        <>
            <html lang="en-US">

                <meta http-equiv="content-type" content="text/html;charset=UTF-8" />

                <body
                    class="home page-template page-template-elementor_header_footer page page-id-9 wp-embed-responsive theme-agrul woocommerce-no-js tinvwl-theme-style right-sidebar elementor-default elementor-template-full-width elementor-kit-7 elementor-page elementor-page-9">

                    <div data-elementor-type="wp-post" data-elementor-id="1175" class="elementor elementor-1175">
                        <div class="elementor-inner">
                            <div class="elementor-section-wrap">
                                <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-1ecee0e elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                                    data-id="1ecee0e" data-element_type="section"
                                    data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
                                    <div class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-row">
                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c2bbf6a"
                                                data-id="c2bbf6a" data-element_type="column">
                                                <div class="elementor-column-wrap elementor-element-populated">
                                                    <div class="elementor-widget-wrap">
                                                        <div class="elementor-element elementor-element-f7602da elementor-widget elementor-widget-agrul_header_topbar"
                                                            data-id="f7602da" data-element_type="widget"
                                                            data-widget_type="agrul_header_topbar.default">
                                                            <div class="elementor-widget-container">
                                                                <div class="top-bar-area text-light">
                                                                    <div class="container">
                                                                        <div class="row align-center">
                                                                            <div class="col-lg-9">
                                                                                <div class="flex-item left">
                                                                                    <p>That&#039;s right, we only sell 100% organic </p>
                                                                                    <ul>
                                                                                        <li>
                                                                                            <i class="fas fa-map-marker-alt"></i>
                                                                                            California, TX 70240
                                                                                        </li>
                                                                                        <li>
                                                                                            <i class="fas fa-phone-alt"></i>
                                                                                            +4733378901
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 text-end">
                                                                                <div class="social">
                                                                                    <ul>
                                                                                        <li class="facebook">
                                                                                            <a href="https://www.facebook.com/">
                                                                                                <i class="fab fa-facebook-f"></i>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li class="twitter">
                                                                                            <a href="https://www.twitter.com/">
                                                                                                <i class="fab fa-twitter"></i>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li class="youtube">
                                                                                            <a href="https://www.youtube.com/">
                                                                                                <i class="fab fa-youtube"></i>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li class="instagram">
                                                                                            <a href="https://www.instagram.com/">
                                                                                                <i class="fab fa-instagram"></i>
                                                                                            </a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-7d76fb9 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                                    data-id="7d76fb9" data-element_type="section"
                                    data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
                                    <div class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-row">
                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-dcfb5b9"
                                                data-id="dcfb5b9" data-element_type="column">
                                                <div class="elementor-column-wrap elementor-element-populated">
                                                    <div class="elementor-widget-wrap">
                                                        <div class="elementor-element elementor-element-269e24a elementor-widget elementor-widget-agrul_header"
                                                            data-id="269e24a" data-element_type="widget"
                                                            data-widget_type="agrul_header.default">
                                                            <div class="elementor-widget-container">
                                                                <header>
                                                                    <nav
                                                                        class="navbar mobile-sidenav inc-shape navbar-common navbar-sticky navbar-default validnavs">

                                                                        <div class="top-search">
                                                                            <div class="container-xl">
                                                                                <div class="input-group">
                                                                                    <span class="input-group-addon"><i
                                                                                        class="fa fa-search"></i></span>
                                                                                    <input type="text" class="form-control"
                                                                                        placeholder="Search" />
                                                                                    <span class="input-group-addon close-search"><i
                                                                                        class="fa fa-times"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="container d-flex justify-content-between align-items-center">

                                                                            <div class="navbar-header">
                                                                                <button type="button" class="navbar-toggle"
                                                                                    data-toggle="collapse" data-target="#navbar-menu">
                                                                                    <i class="fa fa-bars"></i>
                                                                                </button>
                                                                                <a class="navbar-brand"
                                                                                    href="https://validthemes.net/themeforest/wp/agrul">
                                                                                    <img src="wp-content/uploads/2022/11/logo.png"
                                                                                        class="logo" alt="Agrul" />
                                                                                </a>
                                                                            </div>


                                                                            <div class="collapse navbar-collapse" id="navbar-menu">

                                                                                <img src="wp-content/uploads/2022/11/logo.png"
                                                                                    alt="Agrul" />
                                                                                <button type="button" class="navbar-toggle"
                                                                                    data-toggle="collapse" data-target="#navbar-menu">
                                                                                    <i class="fa fa-times"></i>
                                                                                </button>

                                                                                <ul data-in="fadeInDown" data-out="fadeOutUp"
                                                                                    class="nav navbar-nav navbar-right"
                                                                                    id="menu-primary-menu">
                                                                                    <li id="menu-item-979"
                                                                                        class="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-979 dropdown">
                                                                                        <a title="Home" href="#" class="dropdown-toggle"
                                                                                            data-toggle="dropdown">Home</a>
                                                                                        <ul class=" dropdown-menu">
                                                                                            <li id="menu-item-981"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-9 current_page_item menu-item-981 active">
                                                                                                <a title="Home One"
                                                                                                    href="index.html">Home One</a>
                                                                                            </li>
                                                                                            <li id="menu-item-980"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-980">
                                                                                                <a title="Home Two"
                                                                                                    href="home-two/index.html">Home
                                                                                                    Two</a>
                                                                                            </li>
                                                                                            <li id="menu-item-1821"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1821">
                                                                                                <a title="Home Three"
                                                                                                    href="home-three/index.html">Home
                                                                                                    Three</a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </li>
                                                                                    <li id="menu-item-1275"
                                                                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1275 dropdown">
                                                                                        <a title="Pages" href="#"
                                                                                            class="dropdown-toggle"
                                                                                            data-toggle="dropdown">Pages</a>
                                                                                        <ul class=" dropdown-menu">
                                                                                            <li id="menu-item-1279"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1279">
                                                                                                <a title="About Us"
                                                                                                    href="#">About
                                                                                                    Us</a>
                                                                                            </li>
                                                                                            <li id="menu-item-1278"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1278">
                                                                                                <a title="Team"
                                                                                                    href="team/index.html">Team</a>
                                                                                            </li>
                                                                                            <li id="menu-item-1280"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1280">
                                                                                                <a title="Team Details"
                                                                                                    href="team-details/index.html">Team
                                                                                                    Details</a>
                                                                                            </li>
                                                                                            <li id="menu-item-1308"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1308">
                                                                                                <a title="Contact Us"
                                                                                                    href="contact-us/index.html">Contact
                                                                                                    Us</a>
                                                                                            </li>
                                                                                            <li id="menu-item-1276"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1276">
                                                                                                <a title="Faq"
                                                                                                    href="faq/index.html">Faq</a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </li>
                                                                                    <li id="menu-item-1287"
                                                                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1287 dropdown">
                                                                                        <a title="Projects" href="#"
                                                                                            class="dropdown-toggle"
                                                                                            data-toggle="dropdown">Projects</a>
                                                                                        <ul class=" dropdown-menu">
                                                                                            <li id="menu-item-1288"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1288">
                                                                                                <a title="Project"
                                                                                                    href="project/index.html">Project</a>
                                                                                            </li>
                                                                                            <li id="menu-item-1309"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1309">
                                                                                                <a title="Project Details"
                                                                                                    href="organic-healthy-food/index.html">Project
                                                                                                    Details</a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </li>
                                                                                    <li id="menu-item-1303"
                                                                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1303 dropdown">
                                                                                        <a title="Services" href="#"
                                                                                            class="dropdown-toggle"
                                                                                            data-toggle="dropdown">Services</a>
                                                                                        <ul class=" dropdown-menu">
                                                                                            <li id="menu-item-1305"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1305">
                                                                                                <a title="Service Version One"
                                                                                                    href="service-version-one/index.html">Service
                                                                                                    Version One</a>
                                                                                            </li>
                                                                                            <li id="menu-item-1304"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1304">
                                                                                                <a title="Service Version Two"
                                                                                                    href="service-version-two/index.html">Service
                                                                                                    Version Two</a>
                                                                                            </li>
                                                                                            <li id="menu-item-1306"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1306">
                                                                                                <a title="Service Details"
                                                                                                    href="agricultural-product/index.html">Service
                                                                                                    Details</a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </li>
                                                                                    <li id="menu-item-2054"
                                                                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-2054 dropdown">
                                                                                        <a title="Blog" href="#" class="dropdown-toggle"
                                                                                            data-toggle="dropdown">Blog</a>
                                                                                        <ul class=" dropdown-menu">
                                                                                            <li id="menu-item-2053"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2053">
                                                                                                <a title="Blog"
                                                                                                    href="blog/index.html">Blog</a>
                                                                                            </li>
                                                                                            <li id="menu-item-2055"
                                                                                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2055">
                                                                                                <a title="Blog Details"
                                                                                                    href="overcame-breeding-or-concerns-removing-past/index.html">Blog
                                                                                                    Details</a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </li>
                                                                                    <li id="menu-item-1638"
                                                                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1638 dropdown">
                                                                                        <a title="Shop" href="agrul-shop/index.html"
                                                                                            class="dropdown-toggle"
                                                                                            data-toggle="dropdown">Shop</a>
                                                                                        <ul class=" dropdown-menu">
                                                                                            <li id="menu-item-1637"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1637">
                                                                                                <a title="Shop"
                                                                                                    href="agrul-shop/index.html">Shop</a>
                                                                                            </li>
                                                                                            <li id="menu-item-2159"
                                                                                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2159">
                                                                                                <a title="Product Details"
                                                                                                    href="product/organic-cucumber/index.html">Product
                                                                                                    Details</a>
                                                                                            </li>
                                                                                            <li id="menu-item-1635"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1635">
                                                                                                <a title="Checkout"
                                                                                                    href="checkout/index.html">Checkout</a>
                                                                                            </li>
                                                                                            <li id="menu-item-1636"
                                                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1636">
                                                                                                <a title="Cart"
                                                                                                    href="cart/index.html">Cart</a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>

                                                                            <div class="attr-right">
                                                                                <div class="attr-nav">
                                                                                    <ul>
                                                                                        <li class="wishlist">
                                                                                            <a href="wishlist/index.html"
                                                                                                class="wishlist_products_counter cart-icon me-4 me-lg-3 mr-xl-0 has-badge sideMenuToggler">
                                                                                                <i class="fal fa-heart"></i>
                                                                                                <span
                                                                                                    class="wishlist_products_counter_number badge"></span>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li class="dropdown">
                                                                                            <a href="#" class="dropdown-toggle"
                                                                                                data-toggle="dropdown">
                                                                                                <div class="basket-item-count">
                                                                                                    <span class="cart-items-count">
                                                                                                    </span>
                                                                                                </div>
                                                                                            </a>
                                                                                            <div class="widget_shopping_cart_content">



                                                                                                <div
                                                                                                    class="dropdown-menu cart-list woocommerce-mini-cart cart_list product_list_widget ">
                                                                                                    <div class="mini-cart-item-list">

                                                                                                        <div
                                                                                                            class="empty_cart text-center">

                                                                                                            <p>Your cart is currently
                                                                                                                empty.</p>
                                                                                                        </div>


                                                                                                    </div>
                                                                                                    <div class="total">
                                                                                                        <span>Sub Total: <strong><span
                                                                                                            class="woocommerce-Price-amount amount">0.00<span
                                                                                                                class="woocommerce-Price-currencySymbol">&#036;</span></span></strong></span>
                                                                                                        <a href="cart/index.html"
                                                                                                            class="button wc-forward">View
                                                                                                            cart</a><a
                                                                                                                href="checkout/index.html"
                                                                                                                class="button checkout wc-forward">Checkout</a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                        <div class="overlay-screen"></div>
                                                                    </nav>
                                                                </header>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div data-elementor-type="wp-page" data-elementor-id="9" class="elementor elementor-9">
                        <div class="elementor-inner">
                            <div class="elementor-section-wrap">
                                <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-a9179d0 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                                    data-id="a9179d0" data-element_type="section"
                                    data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
                                    <div class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-row">
                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4e8dddc"
                                                data-id="4e8dddc" data-element_type="column">
                                                <div class="elementor-column-wrap elementor-element-populated">
                                                    <div class="elementor-widget-wrap">
                                                        <div class="elementor-element elementor-element-659f045 elementor-widget elementor-widget-agrulbanner"
                                                            data-id="659f045" data-element_type="widget"
                                                            data-widget_type="agrulbanner.default">
                                                            <div class="elementor-widget-container">

                                                                <div
                                                                    class="banner-area navigation-circle text-light banner-style-one zoom-effect overflow-hidden">

                                                                    <div class="banner-fade">
                                                                        <div class="swiper-wrapper">
                                                                            <div class="swiper-slide banner-style-one">
                                                                                <div class="banner-thumb bg-cover shadow dark"
                                                                                    style={{background: "url(wp-content/uploads/2022/10/2.jpg)"}}>
                                                                                </div>
                                                                                <div class="container">
                                                                                    <div class="row align-center">
                                                                                        <div class="col-xl-7">
                                                                                            <div class="content">
                                                                                                <h4>Handmade Products</h4>
                                                                                                <h2><strong>Agriculture Matter</strong>
                                                                                                    Good production</h2>
                                                                                                <p>
                                                                                                    Dissuade ecstatic and properly saw
                                                                                                    entirely sir why laughter endeavor.
                                                                                                    In on my jointure horrible margaret
                                                                                                    suitable he followed. </p>
                                                                                                <div class="button">
                                                                                                    <a class="btn btn-theme secondary btn-md radius animation"
                                                                                                        href="#">Discover
                                                                                                        More</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="swiper-slide banner-style-one">
                                                                                <div class="banner-thumb bg-cover shadow dark"
                                                                                    style={{background: "url(wp-content/uploads/2022/10/17.jpg)"}}>
                                                                                </div>
                                                                                <div class="container">
                                                                                    <div class="row align-center">
                                                                                        <div class="col-xl-7">
                                                                                            <div class="content">
                                                                                                <h4>Original & Natural</h4>
                                                                                                <h2><strong>Organic Agriculture</strong>
                                                                                                    Farming Products</h2>
                                                                                                <p>
                                                                                                    Dissuade ecstatic and properly saw
                                                                                                    entirely sir why laughter endeavor.
                                                                                                    In on my jointure horrible margaret
                                                                                                    suitable he followed. </p>
                                                                                                <div class="button">
                                                                                                    <a class="btn btn-theme secondary btn-md radius animation"
                                                                                                        href="#">Discover
                                                                                                        More</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        <div class="swiper-button-prev"></div>
                                                                        <div class="swiper-button-next"></div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-6401898 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                                    data-id="6401898" data-element_type="section"
                                    data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                                    <div class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-row">
                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e58f7d6"
                                                data-id="e58f7d6" data-element_type="column"
                                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-column-wrap elementor-element-populated">
                                                    <div class="elementor-widget-wrap">
                                                        <section
                                                            class="elementor-section elementor-inner-section elementor-element elementor-element-e1436d4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                            data-id="e1436d4" data-element_type="section">
                                                            <div class="elementor-background-overlay"></div>
                                                            <div class="elementor-container elementor-column-gap-no">
                                                                <div class="elementor-row">
                                                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-414f56c"
                                                                        data-id="414f56c" data-element_type="column">
                                                                        <div class="elementor-column-wrap elementor-element-populated">
                                                                            <div class="elementor-widget-wrap">
                                                                                <div class="elementor-element elementor-element-9c3a2d5 elementor-widget elementor-widget-agrul_about_image"
                                                                                    data-id="9c3a2d5" data-element_type="widget"
                                                                                    data-widget_type="agrul_about_image.default">
                                                                                    <div class="elementor-widget-container">
                                                                                        <div class="about-style-one">
                                                                                            <div class="thumb">
                                                                                                <img decoding="async"
                                                                                                    src="wp-content/uploads/2022/10/5.jpg"
                                                                                                    alt="Agrul" />
                                                                                                <div class="sub-item">
                                                                                                    <img decoding="async"
                                                                                                        src="wp-content/uploads/2022/10/1.jpg"
                                                                                                        alt="Agrul" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5764184"
                                                                        data-id="5764184" data-element_type="column">
                                                                        <div class="elementor-column-wrap elementor-element-populated">
                                                                            <div class="elementor-widget-wrap">
                                                                                <div class="elementor-element elementor-element-554eab0 elementor-widget elementor-widget-agrul_about_content"
                                                                                    data-id="554eab0" data-element_type="widget"
                                                                                    data-widget_type="agrul_about_content.default">
                                                                                    <div class="elementor-widget-container">
                                                                                        <div class="about-style-one">
                                                                                            <div class="row align-center">
                                                                                                <div class="col-xl-7 col-lg-12">
                                                                                                    <h2 class="heading">Agriculture
                                                                                                        For<br />
                                                                                                        Future Development</h2>
                                                                                                    <p>
                                                                                                        There are many variations of
                                                                                                        passages of ipsum available but
                                                                                                        the majority have suffered
                                                                                                        alteration in some form by
                                                                                                        injected humor or random word
                                                                                                        which don’t look even.
                                                                                                        Comparison new ham melancholy
                                                                                                        son themselves. </p>
                                                                                                    <ul class="check-solid-list mt-20">
                                                                                                        <li>Organic food contains more
                                                                                                            vitamins</li>
                                                                                                        <li>Eat organic because supply
                                                                                                            meets demand</li>
                                                                                                        <li>Organic food is never
                                                                                                            irradiated</li>
                                                                                                    </ul>
                                                                                                </div>

                                                                                                <div
                                                                                                    class="col-xl-5 col-lg-12 pl-50 pl-md-15 pl-xs-15">
                                                                                                    <div class="top-product-item">
                                                                                                        <img decoding="async"
                                                                                                            src="wp-content/uploads/2022/10/1.png"
                                                                                                            alt="Agrul" />
                                                                                                        <h5><a href="#">Natural
                                                                                                            Farming</a></h5>
                                                                                                        <p>
                                                                                                            Resolve parties but trying
                                                                                                            she shewing of moment. </p>
                                                                                                    </div>
                                                                                                    <div class="top-product-item">
                                                                                                        <img decoding="async"
                                                                                                            src="wp-content/uploads/2022/10/2.png"
                                                                                                            alt="Agrul" />
                                                                                                        <h5><a href="#">Quality
                                                                                                            Products</a></h5>
                                                                                                        <p>
                                                                                                            Always parties but trying
                                                                                                            she shewing of moment. </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-a3e24e7 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                                    data-id="a3e24e7" data-element_type="section"
                                    data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                                    <div class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-row">
                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-42ddbcf"
                                                data-id="42ddbcf" data-element_type="column">
                                                <div class="elementor-column-wrap elementor-element-populated">
                                                    <div class="elementor-widget-wrap">
                                                        <div class="elementor-element elementor-element-730f01f elementor-widget elementor-widget-agrul_service"
                                                            data-id="730f01f" data-element_type="widget"
                                                            data-widget_type="agrul_service.default">
                                                            <div class="elementor-widget-container">

                                                                <div
                                                                    class="services-style-one-area default-padding bg-gray half-bg-theme">
                                                                    <div class="shape-extra">
                                                                        <img decoding="async" src="wp-content/uploads/2022/10/18.png"
                                                                            alt="Agrul" />
                                                                    </div>
                                                                    <div class="container">
                                                                        <div class="heading-left">
                                                                            <div class="row">
                                                                                <div class="col-lg-5">
                                                                                    <div class="left-info">
                                                                                        <h5 class="secondary-color">What we do</h5>
                                                                                        <h2 class="title">Currently we are<br />
                                                                                            selling organic food</h2>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-6 offset-lg-1">
                                                                                    <div class="right-info">
                                                                                        <p>Everything melancholy uncommonly but
                                                                                            solicitude inhabiting projection off.
                                                                                            Connection stimulated estimating excellence
                                                                                            an to impression. ladies she basket season
                                                                                            age her uneasy saw. Discourse unwilling am
                                                                                            no described.</p>
                                                                                        <a class="btn btn-theme btn-md radius animation"
                                                                                            href="#">Discover More</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="container">
                                                                        <div class="row">
                                                                            <div class="col-lg-12">
                                                                                <div class="services-style-one-carousel swiper">
                                                                                    <div class="swiper-wrapper">

                                                                                        <div class="swiper-slide">
                                                                                            <div class="services-style-one">
                                                                                                <div class="thumb">
                                                                                                    <img decoding="async"
                                                                                                        src="wp-content/uploads/2022/10/1-1.png"
                                                                                                        alt="Agrul" />
                                                                                                </div>
                                                                                                <h5><a
                                                                                                    href="agricultural-product/index.html">Fresh
                                                                                                    Vegetables</a></h5>
                                                                                                <p>
                                                                                                    Continue indulged speaking technical
                                                                                                    out horrible domestic position.
                                                                                                    Seeing rather you. </p>

                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="swiper-slide">
                                                                                            <div class="services-style-one">
                                                                                                <div class="thumb">
                                                                                                    <img decoding="async"
                                                                                                        src="wp-content/uploads/2022/10/2-1.png"
                                                                                                        alt="Agrul" />
                                                                                                </div>
                                                                                                <h5><a
                                                                                                    href="agricultural-product/index.html">Agricultural
                                                                                                    Products</a></h5>
                                                                                                <p>
                                                                                                    Continue indulged speaking technical
                                                                                                    out horrible domestic position.
                                                                                                    Seeing rather you. </p>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="swiper-slide">
                                                                                            <div class="services-style-one">
                                                                                                <div class="thumb">
                                                                                                    <img decoding="async"
                                                                                                        src="wp-content/uploads/2022/10/3.png"
                                                                                                        alt="Agrul" />
                                                                                                </div>
                                                                                                <h5><a
                                                                                                    href="agricultural-product/index.html">Organic
                                                                                                    Products</a></h5>
                                                                                                <p>
                                                                                                    Continue indulged speaking technical
                                                                                                    out horrible domestic position.
                                                                                                    Seeing rather you. </p>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="swiper-slide">
                                                                                            <div class="services-style-one">
                                                                                                <div class="thumb">
                                                                                                    <img decoding="async"
                                                                                                        src="wp-content/uploads/2022/10/4.png"
                                                                                                        alt="Agrul" />
                                                                                                </div>
                                                                                                <h5><a
                                                                                                    href="agricultural-product/index.html">Dairy
                                                                                                    Production</a></h5>
                                                                                                <p>
                                                                                                    Continue indulged speaking technical
                                                                                                    out horrible domestic position.
                                                                                                    Seeing rather you. </p>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="swiper-slide">
                                                                                            <div class="services-style-one">
                                                                                                <div class="thumb">
                                                                                                    <img decoding="async"
                                                                                                        src="wp-content/uploads/2022/10/6.png"
                                                                                                        alt="Agrul" />
                                                                                                </div>
                                                                                                <h5><a
                                                                                                    href="agricultural-product/index.html">Sweet
                                                                                                    Exotic Fruits</a></h5>
                                                                                                <p>
                                                                                                    Continue indulged speaking technical
                                                                                                    out horrible domestic position.
                                                                                                    Seeing rather you. </p>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-95d9374 elementor-section-full_width elementor-section-stretched bg-dark elementor-section-height-default elementor-section-height-default"
                                    data-id="95d9374" data-element_type="section"
                                    data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
                                    <div class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-row">
                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6f55c37"
                                                data-id="6f55c37" data-element_type="column">
                                                <div class="elementor-column-wrap elementor-element-populated">
                                                    <div class="elementor-widget-wrap">
                                                        <div class="elementor-element elementor-element-46b60f4 elementor-widget elementor-widget-agrul_product"
                                                            data-id="46b60f4" data-element_type="widget"
                                                            data-widget_type="agrul_product.default">
                                                            <div class="elementor-widget-container">

                                                                <div class="product-list-area text-center text-light">
                                                                    <div class="shape-bottom-right">
                                                                        <img decoding="async" src="wp-content/uploads/2022/10/21.png"
                                                                            alt="Agrul" />
                                                                    </div>
                                                                    <div class="container">
                                                                        <div class="row">
                                                                            <div class="col-xl-10 offset-xl-1 mb-50 mb-xs-30">
                                                                                <h2 class="mask-text"
                                                                                    style={{backgroundImage: "url(wp-content/uploads/2022/10/3.jpg)"}}>
                                                                                    Healthy life with fresh products</h2>
                                                                            </div>
                                                                            <div class="product-list-box">

                                                                                <div class="product-list-item">
                                                                                    <a href="agricultural-product/index.html">
                                                                                        <img decoding="async"
                                                                                            src="wp-content/uploads/2022/10/1-2.png"
                                                                                            alt="Agrul" />
                                                                                        <h5>Blueberry</h5>
                                                                                    </a>
                                                                                </div>
                                                                                <div class="product-list-item">
                                                                                    <a href="agricultural-product/index.html">
                                                                                        <img decoding="async"
                                                                                            src="wp-content/uploads/2022/10/2-2.png"
                                                                                            alt="Agrul" />
                                                                                        <h5>Strawberry</h5>
                                                                                    </a>
                                                                                </div>

                                                                                <div class="product-list-item">
                                                                                    <a href="agricultural-product/index.html">
                                                                                        <img decoding="async"
                                                                                            src="wp-content/uploads/2022/10/3-1.png"
                                                                                            alt="Agrul" />
                                                                                        <h5>Cabbage</h5>
                                                                                    </a>
                                                                                </div>

                                                                                <div class="product-list-item">
                                                                                    <a href="agricultural-product/index.html">
                                                                                        <img decoding="async"
                                                                                            src="wp-content/uploads/2022/10/4-1.png"
                                                                                            alt="Agrul" />
                                                                                        <h5>Eggplant</h5>
                                                                                    </a>
                                                                                </div>

                                                                                <div class="product-list-item">
                                                                                    <a href="agricultural-product/index.html">
                                                                                        <img decoding="async"
                                                                                            src="wp-content/uploads/2022/10/5.png"
                                                                                            alt="Agrul" />
                                                                                        <h5>Orange</h5>
                                                                                    </a>
                                                                                </div>

                                                                                <div class="product-list-item">
                                                                                    <a href="agricultural-product/index.html">
                                                                                        <img decoding="async"
                                                                                            src="wp-content/uploads/2022/10/6-1.png"
                                                                                            alt="Agrul" />
                                                                                        <h5>Apples</h5>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-b163e4f elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                                    data-id="b163e4f" data-element_type="section"
                                    data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
                                    <div class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-row">
                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e4f6fbd"
                                                data-id="e4f6fbd" data-element_type="column">
                                                <div class="elementor-column-wrap elementor-element-populated">
                                                    <div class="elementor-widget-wrap">
                                                        <section
                                                            class="elementor-section elementor-inner-section elementor-element elementor-element-1444165 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                            data-id="1444165" data-element_type="section">
                                                            <div class="elementor-container elementor-column-gap-no">
                                                                <div class="elementor-row">
                                                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ce5bd70"
                                                                        data-id="ce5bd70" data-element_type="column">
                                                                        <div class="elementor-column-wrap elementor-element-populated">
                                                                            <div class="elementor-widget-wrap">
                                                                                <div class="elementor-element elementor-element-c439305 elementor-widget elementor-widget-agrul_choose_us"
                                                                                    data-id="c439305" data-element_type="widget"
                                                                                    data-widget_type="agrul_choose_us.default">
                                                                                    <div class="elementor-widget-container">
                                                                                        <div class="choose-us-style-one">
                                                                                            <div class="thumb">

                                                                                                <img decoding="async"
                                                                                                    src="wp-content/uploads/2022/10/1-1.jpg"
                                                                                                    alt="Agrul" />
                                                                                                <div class="shape">
                                                                                                    <img decoding="async"
                                                                                                        class="wow fadeInDown"
                                                                                                        src="wp-content/uploads/2022/10/22.png"
                                                                                                        alt="Agrul"/>
                                                                                                </div>
                                                                                                <div class="product-produce">
                                                                                                    <div class="icon">
                                                                                                        <i class="flaticon-farmer"></i>
                                                                                                    </div>
                                                                                                    <div class="fun-fact">
                                                                                                        <div class="counter">
                                                                                                            <div class="timer" data-to="259
" data-speed="2000">259
                                                                                                            </div>
                                                                                                            <div class="operator">K
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <span
                                                                                                            class="medium">Agriculture,
                                                                                                            Organic Products</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-47f6c55"
                                                                        data-id="47f6c55" data-element_type="column">
                                                                        <div class="elementor-column-wrap elementor-element-populated">
                                                                            <div class="elementor-widget-wrap">
                                                                                <div class="elementor-element elementor-element-9b0a6fb elementor-widget elementor-widget-agrul_accordian_widget"
                                                                                    data-id="9b0a6fb" data-element_type="widget"
                                                                                    data-widget_type="agrul_accordian_widget.default">
                                                                                    <div class="elementor-widget-container">

                                                                                        <h5 class="sub-title">Get to know us</h5>
                                                                                        <h2 class="title">Agriculture matters to<br />
                                                                                            the future of development</h2>

                                                                                        <div class="choose-us-style-one mt-35">
                                                                                            <div class="accordion accordion-regular"
                                                                                                id="faqAccordion">
                                                                                                <div class="accordion-item">
                                                                                                    <h2 class="accordion-header"
                                                                                                        id="heading1">
                                                                                                        <button
                                                                                                            class="accordion-button "
                                                                                                            type="button"
                                                                                                            data-bs-toggle="collapse"
                                                                                                            data-bs-target="#collapse1"
                                                                                                            aria-expanded="true"
                                                                                                            aria-controls="collapse1">
                                                                                                            What do you add to the soil
                                                                                                            before you plant a crop?
                                                                                                        </button>
                                                                                                    </h2>
                                                                                                    <div id="collapse1"
                                                                                                        class="accordion-collapse collapse show"
                                                                                                        aria-labelledby="heading1"
                                                                                                        data-bs-parent="#faqAccordion">
                                                                                                        <div class="accordion-body">
                                                                                                            <p>
                                                                                                                Bennings appetite
                                                                                                                disposed me an at
                                                                                                                subjects an. To no
                                                                                                                indulgence diminution so
                                                                                                                discovered mr
                                                                                                                apartments. Are off
                                                                                                                under folly death wrote
                                                                                                                cause her way spite.
                                                                                                                Plan upon yet way get
                                                                                                                cold spot its week. </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="accordion-item">
                                                                                                    <h2 class="accordion-header"
                                                                                                        id="heading2">
                                                                                                        <button
                                                                                                            class="accordion-button collapsed"
                                                                                                            type="button"
                                                                                                            data-bs-toggle="collapse"
                                                                                                            data-bs-target="#collapse2"
                                                                                                            aria-expanded="true"
                                                                                                            aria-controls="collapse2">
                                                                                                            Do you use herbicides?
                                                                                                        </button>
                                                                                                    </h2>
                                                                                                    <div id="collapse2"
                                                                                                        class="accordion-collapse collapse "
                                                                                                        aria-labelledby="heading2"
                                                                                                        data-bs-parent="#faqAccordion">
                                                                                                        <div class="accordion-body">
                                                                                                            <p>
                                                                                                                Cennings appetite
                                                                                                                disposed me an at
                                                                                                                subjects an. To no
                                                                                                                indulgence diminution so
                                                                                                                discovered mr
                                                                                                                apartments. Are off
                                                                                                                under folly death wrote
                                                                                                                cause her way spite.
                                                                                                                Plan upon yet way get
                                                                                                                cold spot its week. </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="accordion-item">
                                                                                                    <h2 class="accordion-header"
                                                                                                        id="heading3">
                                                                                                        <button
                                                                                                            class="accordion-button collapsed"
                                                                                                            type="button"
                                                                                                            data-bs-toggle="collapse"
                                                                                                            data-bs-target="#collapse3"
                                                                                                            aria-expanded="true"
                                                                                                            aria-controls="collapse3">
                                                                                                            Where does the water come
                                                                                                            from that you use on your
                                                                                                            crops? </button>
                                                                                                    </h2>
                                                                                                    <div id="collapse3"
                                                                                                        class="accordion-collapse collapse "
                                                                                                        aria-labelledby="heading3"
                                                                                                        data-bs-parent="#faqAccordion">
                                                                                                        <div class="accordion-body">
                                                                                                            <p>
                                                                                                                Tennings appetite
                                                                                                                disposed me an at
                                                                                                                subjects an. To no
                                                                                                                indulgence diminution so
                                                                                                                discovered mr
                                                                                                                apartments. Are off
                                                                                                                under folly death wrote
                                                                                                                cause her way spite.
                                                                                                                Plan upon yet way get
                                                                                                                cold spot its week. </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-16dc7d3 elementor-section-full_width elementor-section-stretched bg-gray elementor-section-height-default elementor-section-height-default"
                                    data-id="16dc7d3" data-element_type="section"
                                    data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                                    <div class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-row">
                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7973991"
                                                data-id="7973991" data-element_type="column">
                                                <div class="elementor-column-wrap elementor-element-populated">
                                                    <div class="elementor-widget-wrap">
                                                        <div class="elementor-element elementor-element-ae19fb7 elementor-widget elementor-widget-agrul_testimoanial_carousel"
                                                            data-id="ae19fb7" data-element_type="widget"
                                                            data-widget_type="agrul_testimoanial_carousel.default">
                                                            <div class="elementor-widget-container">


                                                                <div class="testimonials-area">
                                                                    <div class="container">
                                                                        <div class="row align-center">
                                                                            <div class="col-lg-5">
                                                                                <div class="testimonial-info text-center">
                                                                                    <h4>Testimonial</h4>
                                                                                    <div class="thumb">
                                                                                        <img decoding="async"
                                                                                            src="wp-content/uploads/2022/10/1-2.jpg"
                                                                                            alt="Agrul" /> <img decoding="async"
                                                                                                src="wp-content/uploads/2022/10/2-1.jpg"
                                                                                                alt="Agrul" /> <img decoding="async"
                                                                                                    src="wp-content/uploads/2022/10/3-1.jpg"
                                                                                                    alt="Agrul" /> <img decoding="async"
                                                                                                        src="wp-content/uploads/2022/10/4.jpg"
                                                                                                        alt="Agrul" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-6 offset-lg-1">
                                                                                <div
                                                                                    class="testimonial-carousel testimonial-style-one swiper">

                                                                                    <div class="swiper-wrapper">
                                                                                        <div class="swiper-slide">
                                                                                            <div class="testimonial-style-two">

                                                                                                <div class="item">
                                                                                                    <div class="content">
                                                                                                        <p>
                                                                                                            “Targetingconsultation
                                                                                                            discover apartments.
                                                                                                            ndulgence off under folly
                                                                                                            death wrote cause her way
                                                                                                            spite. Plan upon yet way get
                                                                                                            cold spot its week. Almost
                                                                                                            do am or limits hearts.
                                                                                                            Resolve parties but why she
                                                                                                            shewing. She sang know now
                                                                                                            always remembering to the
                                                                                                            point.” </p>
                                                                                                    </div>
                                                                                                    <div class="provider">
                                                                                                        <div class="info">
                                                                                                            <h4>
                                                                                                                Matthew J. Wyman </h4>
                                                                                                            <span>
                                                                                                                Senior Consultant
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="swiper-slide">
                                                                                            <div class="testimonial-style-two">

                                                                                                <div class="item">
                                                                                                    <div class="content">
                                                                                                        <p>
                                                                                                            “Targetingconsultation
                                                                                                            discover apartments.
                                                                                                            ndulgence off under folly
                                                                                                            death wrote cause her way
                                                                                                            spite. Plan upon yet way get
                                                                                                            cold spot its week. Almost
                                                                                                            do am or limits hearts.
                                                                                                            Resolve parties but why she
                                                                                                            shewing. She sang know now
                                                                                                            always remembering to the
                                                                                                            point.” </p>
                                                                                                    </div>
                                                                                                    <div class="provider">
                                                                                                        <div class="info">
                                                                                                            <h4>
                                                                                                                Anthom Bu Spar </h4>
                                                                                                            <span>
                                                                                                                Marketing Manager
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="swiper-slide">
                                                                                            <div class="testimonial-style-two">

                                                                                                <div class="item">
                                                                                                    <div class="content">
                                                                                                        <p>
                                                                                                            “Targetingconsultation
                                                                                                            discover apartments.
                                                                                                            ndulgence off under folly
                                                                                                            death wrote cause her way
                                                                                                            spite. Plan upon yet way get
                                                                                                            cold spot its week. Almost
                                                                                                            do am or limits hearts.
                                                                                                            Resolve parties but why she
                                                                                                            shewing. She sang know now
                                                                                                            always remembering to the
                                                                                                            point.” </p>
                                                                                                    </div>
                                                                                                    <div class="provider">
                                                                                                        <div class="info">
                                                                                                            <h4>
                                                                                                                Metho k. Partho </h4>
                                                                                                            <span>
                                                                                                                Consultant </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="swiper-slide">
                                                                                            <div class="testimonial-style-two">

                                                                                                <div class="item">
                                                                                                    <div class="content">
                                                                                                        <p>
                                                                                                            “Targetingconsultation
                                                                                                            discover apartments.
                                                                                                            ndulgence off under folly
                                                                                                            death wrote cause her way
                                                                                                            spite. Plan upon yet way get
                                                                                                            cold spot its week. Almost
                                                                                                            do am or limits hearts.
                                                                                                            Resolve parties but why she
                                                                                                            shewing. She sang know now
                                                                                                            always remembering to the
                                                                                                            point.” </p>
                                                                                                    </div>
                                                                                                    <div class="provider">
                                                                                                        <div class="info">
                                                                                                            <h4>
                                                                                                                Kevin Martin </h4>
                                                                                                            <span>
                                                                                                                Senior Consultant
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-ed591d1 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                                    data-id="ed591d1" data-element_type="section"
                                    data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
                                    <div class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-row">
                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae167ba"
                                                data-id="ae167ba" data-element_type="column">
                                                <div class="elementor-column-wrap elementor-element-populated">
                                                    <div class="elementor-widget-wrap">
                                                        <div class="elementor-element elementor-element-5b94049 elementor-widget elementor-widget-agrul_gallery"
                                                            data-id="5b94049" data-element_type="widget"
                                                            data-widget_type="agrul_gallery.default">
                                                            <div class="elementor-widget-container">

                                                                <div class="gallery-style-one-area">
                                                                    <div class="container">
                                                                        <div class="row">
                                                                            <div class="col-lg-8 offset-lg-2">
                                                                                <div class="site-heading text-center">
                                                                                    <h5 class="sub-title">Awesome Gallery</h5>
                                                                                    <h2 class="title">Gallery Of Our Products</h2>
                                                                                    <div class="devider"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="container container-stage">
                                                                        <div class="row">
                                                                            <div class="col-xl-12">
                                                                                <div
                                                                                    class="carousel-stage-right carousel-style-one swiper">

                                                                                    <div class="swiper-wrapper">
                                                                                        <div class="swiper-slide">
                                                                                            <div class="gallery-style-one">
                                                                                                <img decoding="async"
                                                                                                    src="wp-content/uploads/2022/10/2-2.jpg"
                                                                                                    alt="Agrul" />
                                                                                                <div class="overlay">
                                                                                                    <span>Fruit</span>
                                                                                                    <h4><a
                                                                                                        href="organic-healthy-food/index.html">Healthy
                                                                                                        Food</a></h4>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="swiper-slide">
                                                                                            <div class="gallery-style-one">
                                                                                                <img decoding="async"
                                                                                                    src="wp-content/uploads/2022/10/7.jpg"
                                                                                                    alt="Agrul" />
                                                                                                <div class="overlay">
                                                                                                    <span>Organic</span>
                                                                                                    <h4><a
                                                                                                        href="organic-healthy-food/index.html">Cow
                                                                                                        Milk</a></h4>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="swiper-slide">
                                                                                            <div class="gallery-style-one">
                                                                                                <img decoding="async"
                                                                                                    src="wp-content/uploads/2022/10/3-2.jpg"
                                                                                                    alt="Agrul" />
                                                                                                <div class="overlay">
                                                                                                    <span>Vegetables</span>
                                                                                                    <h4><a
                                                                                                        href="organic-healthy-food/index.html">Organic
                                                                                                        Vegetables</a></h4>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="swiper-slide">
                                                                                            <div class="gallery-style-one">
                                                                                                <img decoding="async"
                                                                                                    src="wp-content/uploads/2022/10/5-1.jpg"
                                                                                                    alt="Agrul" />
                                                                                                <div class="overlay">
                                                                                                    <span>Cereals</span>
                                                                                                    <h4><a
                                                                                                        href="organic-healthy-food/index.html">Fresh
                                                                                                        Mandrains</a></h4>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="swiper-slide">
                                                                                            <div class="gallery-style-one">
                                                                                                <img decoding="async"
                                                                                                    src="wp-content/uploads/2022/10/10.jpg"
                                                                                                    alt="Agrul" />
                                                                                                <div class="overlay">
                                                                                                    <span>Havest</span>
                                                                                                    <h4><a
                                                                                                        href="organic-healthy-food/index.html">Crispy
                                                                                                        Cucumber</a></h4>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div class="swiper-pagination"></div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-bd09b46 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                                    data-id="bd09b46" data-element_type="section"
                                    data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
                                    <div class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-row">
                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b0f6ff0"
                                                data-id="b0f6ff0" data-element_type="column">
                                                <div class="elementor-column-wrap elementor-element-populated">
                                                    <div class="elementor-widget-wrap">
                                                        <div class="elementor-element elementor-element-1f65a0d elementor-widget elementor-widget-agrul_funfactor"
                                                            data-id="1f65a0d" data-element_type="widget"
                                                            data-widget_type="agrul_funfactor.default">
                                                            <div class="elementor-widget-container">

                                                                <div class="fun-facts-area default-padding">
                                                                    <div class="shape-left">
                                                                        <img decoding="async" src="wp-content/uploads/2022/10/27.png"
                                                                            alt="Agrul" />
                                                                    </div>
                                                                    <div class="container">
                                                                        <div class="item-inner">
                                                                            <div class="shape-right">
                                                                                <img decoding="async"
                                                                                    src="wp-content/uploads/2022/10/26.png"
                                                                                    alt="Agrul" />
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-lg-4 fun-fact-style-one">
                                                                                    <div class="heading">
                                                                                        <div class="sub-title">Achivements</div>
                                                                                        <h2 class="title">Delivering value<br/>
                                                                                            since 1956</h2>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-8 fun-fact-style-one text-end">
                                                                                    <div class="row">

                                                                                        <div class="col-lg-4 col-md-4 item">
                                                                                            <div class="fun-fact">
                                                                                                <div class="counter">
                                                                                                    <div class="timer" data-to="250"
                                                                                                        data-speed="2000">250</div>
                                                                                                    <div class="operator">M</div>
                                                                                                </div>
                                                                                                <span class="medium">Growth Tons of
                                                                                                    Harvest</span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-4 col-md-4 item">
                                                                                            <div class="fun-fact">
                                                                                                <div class="counter">
                                                                                                    <div class="timer" data-to="98
" data-speed="2000">98
                                                                                                    </div>
                                                                                                    <div class="operator">%</div>
                                                                                                </div>
                                                                                                <span class="medium">Happy
                                                                                                    clients</span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-4 col-md-4 item">
                                                                                            <div class="fun-fact">
                                                                                                <div class="counter">
                                                                                                    <div class="timer" data-to="688
" data-speed="2000">688
                                                                                                    </div>
                                                                                                    <div class="operator">K</div>
                                                                                                </div>
                                                                                                <span class="medium">Sales of our
                                                                                                    Products</span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-6659610 elementor-section-full_width elementor-section-stretched bg-gray elementor-section-height-default elementor-section-height-default"
                                    data-id="6659610" data-element_type="section"
                                    data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                                    <div class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-row">
                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-dc2eb9f"
                                                data-id="dc2eb9f" data-element_type="column"
                                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-column-wrap elementor-element-populated">
                                                    <div class="elementor-widget-wrap">
                                                        <section
                                                            class="elementor-section elementor-inner-section elementor-element elementor-element-f38b37c elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                            data-id="f38b37c" data-element_type="section">
                                                            <div class="elementor-container elementor-column-gap-no">
                                                                <div class="elementor-row">
                                                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7b75eaa"
                                                                        data-id="7b75eaa" data-element_type="column">
                                                                        <div class="elementor-column-wrap elementor-element-populated">
                                                                            <div class="elementor-widget-wrap">
                                                                                <div class="elementor-element elementor-element-e1579e8 elementor-widget elementor-widget-agrul_contact_form"
                                                                                    data-id="e1579e8" data-element_type="widget"
                                                                                    data-widget_type="agrul_contact_form.default">
                                                                                    <div class="elementor-widget-container">

                                                                                        <div class="col-tact-stye-one">
                                                                                            <div
                                                                                                class="contact-form-style-one mb-md-50">
                                                                                                <h5 class="sub-title">Have Questions?
                                                                                                </h5>
                                                                                                <h2 class="heading">Send us a Massage
                                                                                                </h2>

                                                                                                <div class="wpcf7 no-js"
                                                                                                    id="wpcf7-f397-p9-o1" lang="en-US"
                                                                                                    dir="ltr">
                                                                                                    <div class="screen-reader-response">
                                                                                                        <p role="status"
                                                                                                            aria-live="polite"
                                                                                                            aria-atomic="true"></p>
                                                                                                        <ul></ul>
                                                                                                    </div>
                                                                                                    <form
                                                                                                        action="https://validthemes.net/themeforest/wp/agrul/#wpcf7-f397-p9-o1"
                                                                                                        method="post"
                                                                                                        class="wpcf7-form init"
                                                                                                        aria-label="Contact form"
                                                                                                        novalidate="novalidate"
                                                                                                        data-status="init">
                                                                                                        <div style={{display: "none"}}>
                                                                                                            <input type="hidden"
                                                                                                                name="_wpcf7"
                                                                                                                value="397" />
                                                                                                            <input type="hidden"
                                                                                                                name="_wpcf7_version"
                                                                                                                value="5.8" />
                                                                                                            <input type="hidden"
                                                                                                                name="_wpcf7_locale"
                                                                                                                value="en_US" />
                                                                                                            <input type="hidden"
                                                                                                                name="_wpcf7_unit_tag"
                                                                                                                value="wpcf7-f397-p9-o1" />
                                                                                                            <input type="hidden"
                                                                                                                name="_wpcf7_container_post"
                                                                                                                value="9" />
                                                                                                            <input type="hidden"
                                                                                                                name="_wpcf7_posted_data_hash"
                                                                                                                value="" />
                                                                                                        </div>
                                                                                                        <div class="row">
                                                                                                            <div class="col-lg-12">
                                                                                                                <div class="form-group">
                                                                                                                    <p><span class="wpcf7-form-control-wrap"
                                                                                                                        data-name="text-82"><input
                                                                                                                            size="40"
                                                                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                                                                                                                            id="name"
                                                                                                                            aria-required="true"
                                                                                                                            aria-invalid="false"
                                                                                                                            placeholder="Name"
                                                                                                                            value=""
                                                                                                                            type="text"
                                                                                                                            name="text-82" /></span>
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="row">
                                                                                                            <div class="col-lg-6">
                                                                                                                <div class="form-group">
                                                                                                                    <p><span class="wpcf7-form-control-wrap"
                                                                                                                        data-name="email-162"><input
                                                                                                                            size="40"
                                                                                                                            class="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email form-control"
                                                                                                                            id="email"
                                                                                                                            aria-required="true"
                                                                                                                            aria-invalid="false"
                                                                                                                            placeholder="Email*"
                                                                                                                            value=""
                                                                                                                            type="email"
                                                                                                                            name="email-162" /></span>
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="col-lg-6">
                                                                                                                <div class="form-group">
                                                                                                                    <p><span class="wpcf7-form-control-wrap"
                                                                                                                        data-name="tel-519"><input
                                                                                                                            size="40"
                                                                                                                            class="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel form-control"
                                                                                                                            id="phone"
                                                                                                                            aria-required="true"
                                                                                                                            aria-invalid="false"
                                                                                                                            placeholder="Phone"
                                                                                                                            value=""
                                                                                                                            type="tel"
                                                                                                                            name="tel-519" /></span>
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="row">
                                                                                                            <div class="col-lg-12">
                                                                                                                <div
                                                                                                                    class="form-group comments">
                                                                                                                    <p><span class="wpcf7-form-control-wrap"
                                                                                                                        data-name="textarea-648"><textarea
                                                                                                                            cols="40"
                                                                                                                            rows="10"
                                                                                                                            class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required form-control"
                                                                                                                            id="comments"
                                                                                                                            aria-required="true"
                                                                                                                            aria-invalid="false"
                                                                                                                            placeholder="Tell Us About Project *"
                                                                                                                            name="textarea-648"></textarea></span>
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="row">
                                                                                                            <div class="col-lg-12">
                                                                                                                <p><button type="submit"
                                                                                                                    name="submit"
                                                                                                                    id="submit"><i
                                                                                                                        class="fa fa-paper-plane"></i>
                                                                                                                    Get in
                                                                                                                    Touch</button>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="wpcf7-response-output"
                                                                                                            aria-hidden="true"></div>
                                                                                                    </form>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-e381c17"
                                                                        data-id="e381c17" data-element_type="column">
                                                                        <div class="elementor-column-wrap elementor-element-populated">
                                                                            <div class="elementor-widget-wrap">
                                                                                <div class="elementor-element elementor-element-8a99888 elementor-widget elementor-widget-agrul_contact_info"
                                                                                    data-id="8a99888" data-element_type="widget"
                                                                                    data-widget_type="agrul_contact_info.default">
                                                                                    <div class="elementor-widget-container">

                                                                                        <div class="col-tact-stye-one">
                                                                                            <div class="contact-style-one-info">
                                                                                                <h2>
                                                                                                    Contact
                                                                                                    <span>
                                                                                                        Information
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                            viewBox="0 0 500 150"
                                                                                                            preserveAspectRatio="none">
                                                                                                            <path
                                                                                                                d="M14.4,111.6c0,0,202.9-33.7,471.2,0c0,0-194-8.9-397.3,24.7c0,0,141.9-5.9,309.2,0"
                                                                                                                style={{animationPlayState: "running"}}>
                                                                                                            </path>
                                                                                                        </svg>
                                                                                                    </span>
                                                                                                </h2>
                                                                                                <p>
                                                                                                    Plan upon yet way get cold spot its
                                                                                                    week. Almost do am or limits hearts.
                                                                                                    Resolve parties but why she shewing.
                                                                                                </p>
                                                                                                <ul>
                                                                                                    <li class="wow fadeInUp">
                                                                                                        <div class="icon">
                                                                                                            <i
                                                                                                                class="fas fa-phone-alt"></i>
                                                                                                        </div>
                                                                                                        <div class="content">
                                                                                                            <h5 class="title">Hotline
                                                                                                            </h5>
                                                                                                            <a href="#">+4733378901</a>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li class="wow fadeInUp">
                                                                                                        <div class="icon">
                                                                                                            <i
                                                                                                                class="fas fa-map-marker-alt"></i>
                                                                                                        </div>
                                                                                                        <div class="content">
                                                                                                            <h5 class="title">Our
                                                                                                                Location</h5>
                                                                                                            <a href="#">
                                                                                                                55 Main Street, The
                                                                                                                Grand Avenue 2nd Block,
                                                                                                                New York City</a>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li class="wow fadeInUp">
                                                                                                        <div class="icon">
                                                                                                            <i
                                                                                                                class="fas fa-envelope-open-text"></i>
                                                                                                        </div>
                                                                                                        <div class="content">
                                                                                                            <h5 class="title">Official
                                                                                                                Email
                                                                                                            </h5>
                                                                                                            <a
                                                                                                                href="#">info@agrul.com</a>
                                                                                                        </div>
                                                                                                    </li>

                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-17dd6f0 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                                    data-id="17dd6f0" data-element_type="section"
                                    data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
                                    <div class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-row">
                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-19fbf99"
                                                data-id="19fbf99" data-element_type="column">
                                                <div class="elementor-column-wrap elementor-element-populated">
                                                    <div class="elementor-widget-wrap">
                                                        <div class="elementor-element elementor-element-58b3d25 elementor-widget elementor-widget-agrul_blog"
                                                            data-id="58b3d25" data-element_type="widget"
                                                            data-widget_type="agrul_blog.default">
                                                            <div class="elementor-widget-container">

                                                                <div class="blog-area home-blog blog-grid default-padding bottom-less">
                                                                    <div class="container">
                                                                        <div class="row">

                                                                            <div class="col-lg-6 col-md-12 mb-30">
                                                                                <div class="blog-style-one">
                                                                                    <div class="thumb">
                                                                                        <a
                                                                                            href="announcing-if-the-resolution-sentiments-possession-ye-no-mr-unaffected-remarkably/index.html"><img
                                                                                                decoding="async"
                                                                                                src="wp-content/uploads/2023/04/blog-2.jpg"
                                                                                                class="w-100" alt="Agrul"/></a>
                                                                                        <div class="date"><strong>14</strong>
                                                                                            <span>Apr,23</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="info">
                                                                                        <div class="meta">
                                                                                            <ul>
                                                                                                <li>
                                                                                                    <a href="author/agrul/index.html"><i
                                                                                                        class="fas fa-user-circle"></i>
                                                                                                        Agrul</a>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <a
                                                                                                        href="announcing-if-the-resolution-sentiments-possession-ye-no-mr-unaffected-remarkably/index.html"><i
                                                                                                            class="fas fa-comments"></i>
                                                                                                        2 Comments</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                        <h4 class="title">
                                                                                            <a
                                                                                                href="announcing-if-the-resolution-sentiments-possession-ye-no-mr-unaffected-remarkably/index.html">Announcing
                                                                                                if the resolution sentiments Possession
                                                                                                ye no mr unaffected remarkably</a>
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 col-md-6 mb-30">
                                                                                <div class="blog-style-one">
                                                                                    <div class="thumb">
                                                                                        <a
                                                                                            href="the-super-soil-organic-farming-strong-plants/index.html"><img
                                                                                                decoding="async"
                                                                                                src="wp-content/uploads/2023/04/blog-1-284x355.jpg"
                                                                                                class="w-100" alt="Agrul"/></a>
                                                                                        <div class="date"><strong>14</strong>
                                                                                            <span>Apr,23</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="info">
                                                                                        <div class="meta">
                                                                                            <ul>
                                                                                                <li>
                                                                                                    <a href="author/agrul/index.html"><i
                                                                                                        class="fas fa-user-circle"></i>
                                                                                                        Agrul</a>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <a
                                                                                                        href="the-super-soil-organic-farming-strong-plants/index.html"><i
                                                                                                            class="fas fa-comments"></i>
                                                                                                        2 Comments</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                        <h4 class="title">
                                                                                            <a
                                                                                                href="the-super-soil-organic-farming-strong-plants/index.html">The
                                                                                                Super Soil Organic Farming Strong
                                                                                                plants</a>
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 col-md-6 mb-30">
                                                                                <div class="blog-style-one">
                                                                                    <div class="thumb">
                                                                                        <a
                                                                                            href="overcame-breeding-or-concerns-removing-past/index.html"><img
                                                                                                decoding="async"
                                                                                                src="wp-content/uploads/2022/11/2-284x355.jpg"
                                                                                                class="w-100" alt="Agrul"/></a>
                                                                                        <div class="date"><strong>20</strong>
                                                                                            <span>Nov,22</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="info">
                                                                                        <div class="meta">
                                                                                            <ul>
                                                                                                <li>
                                                                                                    <a href="author/agrul/index.html"><i
                                                                                                        class="fas fa-user-circle"></i>
                                                                                                        Agrul</a>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <a
                                                                                                        href="overcame-breeding-or-concerns-removing-past/index.html"><i
                                                                                                            class="fas fa-comments"></i>
                                                                                                        2 Comments</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                        <h4 class="title">
                                                                                            <a
                                                                                                href="overcame-breeding-or-concerns-removing-past/index.html">Overcame
                                                                                                breeding or concerns removing past</a>
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    <footer>
                        <div data-elementor-type="wp-post" data-elementor-id="317" class="elementor elementor-317">
                            <div class="elementor-inner">
                                <div class="elementor-section-wrap">
                                    <section
                                        class="elementor-section elementor-top-section elementor-element elementor-element-b933d34 elementor-section-full_width elementor-section-stretched bg-dark text-light elementor-section-height-default elementor-section-height-default"
                                        data-id="b933d34" data-element_type="section"
                                        data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-background-overlay"></div>
                                        <div class="elementor-container elementor-column-gap-no">
                                            <div class="elementor-row">
                                                <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-442865f"
                                                    data-id="442865f" data-element_type="column"
                                                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                    <div class="elementor-column-wrap elementor-element-populated">
                                                        <div class="elementor-widget-wrap">
                                                            <section
                                                                class="elementor-section elementor-inner-section elementor-element elementor-element-56e1f30 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                                data-id="56e1f30" data-element_type="section"
                                                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                                <div class="elementor-background-overlay"></div>
                                                                <div class="elementor-container elementor-column-gap-no">
                                                                    <div class="elementor-row">
                                                                        <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-1013c9c footer-item about"
                                                                            data-id="1013c9c" data-element_type="column">
                                                                            <div
                                                                                class="elementor-column-wrap elementor-element-populated">
                                                                                <div class="elementor-widget-wrap">
                                                                                    <div class="elementor-element elementor-element-7482b25 elementor-widget elementor-widget-image"
                                                                                        data-id="7482b25" data-element_type="widget"
                                                                                        data-widget_type="image.default">
                                                                                        <div class="elementor-widget-container">
                                                                                            <div class="elementor-image">
                                                                                                <img src="wp-content/uploads/elementor/thumbs/logo-light-pwjxht66hxa1r82llz663zfue5n94x7n4y18rs8ejc.png"
                                                                                                    title="logo-light" alt="logo-light"
                                                                                                    loading="lazy" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="elementor-element elementor-element-555fad3 elementor-widget elementor-widget-text-editor"
                                                                                        data-id="555fad3" data-element_type="widget"
                                                                                        data-widget_type="text-editor.default">
                                                                                        <div class="elementor-widget-container">
                                                                                            <div
                                                                                                class="elementor-text-editor elementor-clearfix">
                                                                                                <p>Happen active county. Winding morning
                                                                                                    ambition shyness evident to poor.
                                                                                                    Because elderly new to the point to
                                                                                                    main success.</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="elementor-element elementor-element-9fe6fa1 elementor-widget elementor-widget-agrulnewsletter"
                                                                                        data-id="9fe6fa1" data-element_type="widget"
                                                                                        data-widget_type="agrulnewsletter.default">
                                                                                        <div class="elementor-widget-container">
                                                                                            <form class="newsletter-form"><input
                                                                                                type="email"
                                                                                                placeholder="Enter Your Email"
                                                                                                class="form-control"
                                                                                                name="email" /><button type="submit">
                                                                                                    Go</button> </form>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-0031640 footer-item link"
                                                                            data-id="0031640" data-element_type="column">
                                                                            <div
                                                                                class="elementor-column-wrap elementor-element-populated">
                                                                                <div class="elementor-widget-wrap">
                                                                                    <div class="elementor-element elementor-element-9f62eaa elementor-widget elementor-widget-heading"
                                                                                        data-id="9f62eaa" data-element_type="widget"
                                                                                        data-widget_type="heading.default">
                                                                                        <div class="elementor-widget-container">
                                                                                            <h4
                                                                                                class="elementor-heading-title elementor-size-default">
                                                                                                Explore</h4>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="elementor-element elementor-element-7e30246 elementor-widget elementor-widget-wp-widget-nav_menu"
                                                                                        data-id="7e30246" data-element_type="widget"
                                                                                        data-widget_type="wp-widget-nav_menu.default">
                                                                                        <div class="elementor-widget-container">
                                                                                            <div class="menu-explore-container">
                                                                                                <ul id="menu-explore" class="menu">
                                                                                                    <li id="menu-item-1532"
                                                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1532">
                                                                                                        <a href="#">About
                                                                                                            Us</a>
                                                                                                    </li>
                                                                                                    <li id="menu-item-1533"
                                                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1533">
                                                                                                        <a href="team/index.html">Meet
                                                                                                            Our Team</a>
                                                                                                    </li>
                                                                                                    <li id="menu-item-1534"
                                                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1534">
                                                                                                        <a
                                                                                                            href="service-version-one/index.html">Services</a>
                                                                                                    </li>
                                                                                                    <li id="menu-item-1537"
                                                                                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1537">
                                                                                                        <a href="#">News
                                                                                                            &#038; Media</a>
                                                                                                    </li>
                                                                                                    <li id="menu-item-1535"
                                                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1535">
                                                                                                        <a href="contact-us/index.html">Contact
                                                                                                            Us</a>
                                                                                                    </li>
                                                                                                    <li id="menu-item-1536"
                                                                                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1536">
                                                                                                        <a
                                                                                                            href="#">Volunteers</a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-61b896d footer-item recent-post"
                                                                            data-id="61b896d" data-element_type="column">
                                                                            <div
                                                                                class="elementor-column-wrap elementor-element-populated">
                                                                                <div class="elementor-widget-wrap">
                                                                                    <div class="elementor-element elementor-element-15531dd elementor-widget elementor-widget-heading"
                                                                                        data-id="15531dd" data-element_type="widget"
                                                                                        data-widget_type="heading.default">
                                                                                        <div class="elementor-widget-container">
                                                                                            <h4
                                                                                                class="elementor-heading-title elementor-size-default">
                                                                                                Recent Posts</h4>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="elementor-element elementor-element-f6ea3ad elementor-widget elementor-widget-wp-widget-agrul-recent-posts"
                                                                                        data-id="f6ea3ad" data-element_type="widget"
                                                                                        data-widget_type="wp-widget-agrul-recent-posts.default">
                                                                                        <div class="elementor-widget-container">
                                                                                            <ul>

                                                                                                <li>
                                                                                                    <div class="thumb">
                                                                                                        <a
                                                                                                            href="announcing-if-the-resolution-sentiments-possession-ye-no-mr-unaffected-remarkably/index.html">
                                                                                                            <img src="wp-content/uploads/2023/04/blog-2-150x150.jpg"
                                                                                                                alt="agrul" />
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <div class="info">
                                                                                                        <div class="meta-title">
                                                                                                            <span
                                                                                                                class="post-date">April
                                                                                                                14, 2023</span>
                                                                                                        </div>
                                                                                                        <a
                                                                                                            href="announcing-if-the-resolution-sentiments-possession-ye-no-mr-unaffected-remarkably/index.html">Announcing
                                                                                                            if the resolution
                                                                                                            sentiments</a>
                                                                                                    </div>
                                                                                                </li>

                                                                                                <li>
                                                                                                    <div class="thumb">
                                                                                                        <a
                                                                                                            href="the-super-soil-organic-farming-strong-plants/index.html">
                                                                                                            <img src="wp-content/uploads/2023/04/blog-1-150x150.jpg"
                                                                                                                alt="agrul" />
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <div class="info">
                                                                                                        <div class="meta-title">
                                                                                                            <span
                                                                                                                class="post-date">April
                                                                                                                14, 2023</span>
                                                                                                        </div>
                                                                                                        <a
                                                                                                            href="the-super-soil-organic-farming-strong-plants/index.html">The
                                                                                                            Super Soil Organic
                                                                                                            Farming</a>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-ba2227e"
                                                                            data-id="ba2227e" data-element_type="column"
                                                                            data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                                            <div
                                                                                class="elementor-column-wrap elementor-element-populated">
                                                                                <div class="elementor-widget-wrap">
                                                                                    <div class="elementor-element elementor-element-8cd23b0 elementor-widget elementor-widget-text-editor"
                                                                                        data-id="8cd23b0" data-element_type="widget"
                                                                                        data-widget_type="text-editor.default">
                                                                                        <div class="elementor-widget-container">
                                                                                            <div
                                                                                                class="elementor-text-editor elementor-clearfix">
                                                                                                <div class="footer-item contact">
                                                                                                    <h4 class="widget-title">Contact
                                                                                                        Info</h4>
                                                                                                    <ul>
                                                                                                        <li>
                                                                                                            <div class="icon">
                                                                                                                <i
                                                                                                                    class="fas fa-home"></i>
                                                                                                            </div>
                                                                                                            <div class="content">
                                                                                                                <strong>Address:</strong>
                                                                                                                5919 Trussville
                                                                                                                Crossings Pkwy,
                                                                                                                Birmingham
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div class="icon">
                                                                                                                <i
                                                                                                                    class="fas fa-envelope"></i>
                                                                                                            </div>
                                                                                                            <div class="content">
                                                                                                                <strong>Email:</strong>
                                                                                                                <a
                                                                                                                    href="mailto:info@validtheme.com">info@validtheme.com</a>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div class="icon">
                                                                                                                <i
                                                                                                                    class="fas fa-phone"></i>
                                                                                                            </div>
                                                                                                            <div class="content">
                                                                                                                <strong>Phone:</strong>
                                                                                                                <a
                                                                                                                    href="tel:2151234567">+123
                                                                                                                    34598768</a>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                            <section
                                                                class="elementor-section elementor-inner-section elementor-element elementor-element-f5c3c4c footer-bottom elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                                data-id="f5c3c4c" data-element_type="section"
                                                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                                <div class="elementor-container elementor-column-gap-no">
                                                                    <div class="elementor-row">
                                                                        <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-216befc"
                                                                            data-id="216befc" data-element_type="column"
                                                                            data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                                            <div
                                                                                class="elementor-column-wrap elementor-element-populated">
                                                                                <div class="elementor-widget-wrap">
                                                                                    <div class="elementor-element elementor-element-b14686c elementor-widget elementor-widget-text-editor"
                                                                                        data-id="b14686c" data-element_type="widget"
                                                                                        data-widget_type="text-editor.default">
                                                                                        <div class="elementor-widget-container">
                                                                                            <div
                                                                                                class="elementor-text-editor elementor-clearfix">
                                                                                                © Copyright 2023. All Rights Reserved by
                                                                                                <a
                                                                                                    href="#">validthemes</a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-31e8334 text-end"
                                                                            data-id="31e8334" data-element_type="column">
                                                                            <div
                                                                                class="elementor-column-wrap elementor-element-populated">
                                                                                <div class="elementor-widget-wrap">
                                                                                    <div class="elementor-element elementor-element-c4f6928 elementor-widget elementor-widget-text-editor"
                                                                                        data-id="c4f6928" data-element_type="widget"
                                                                                        data-widget_type="text-editor.default">
                                                                                        <div class="elementor-widget-container">
                                                                                            <div
                                                                                                class="elementor-text-editor elementor-clearfix">
                                                                                                <ul class="footer-bottom-link">
                                                                                                    <li><a
                                                                                                        href="#">Terms</a>
                                                                                                    </li>
                                                                                                    <li><a
                                                                                                        href="#">Privacy</a>
                                                                                                    </li>
                                                                                                    <li><a
                                                                                                        href="#">Support</a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </footer>

                </body>

            </html>
        </>
    )
}
export default Blank