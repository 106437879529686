import React from 'react'
import banner from '../assets/images/banner.webp'
import banner1 from '../assets/images/top-banner1.webp'
import banner2 from '../assets/images/banner-img2.webp'
// import bannerVideo from '../assets/images/video-banner.mp4'
import logo from '../assets/images/Logo.webp'
import '../assets/css/style.css'
import { Link } from 'react-router-dom'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const homeBanner = () => {
    return (
        <div className='video-banner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-5'>
                        <div className='container'>
                            <div style={{ textAlign: 'center' }}>
                                <div className='card video-card mt-3 p-4' style={{ maxWidth: '350px', background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.59) 0%, rgba(255, 255, 255, 0.46) 100%)', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', zIndex: '99991' }}>
                                    <div className='text-center desktop-only'>

                                        <Link to='/'><img src={logo} style={{ width: '200px', marginBottom: '20px', cursor: 'pointer' }} /></Link>

                                    </div>
                                    {/* <h1 className='primary-color' style={{ fontWeight: '800', textAlign: 'left' }}>KiVISA</h1> */}
                                    <h4 className='primary-color mb-0 mt-4' style={{ fontWeight: '700' }}>Agriculture Is Our Culture</h4>
                                    <h5 className='normal-color mt-4' style={{ lineHeight: '32px' }}>Seeding Innovation, Harvesting Progress: Empowering Agriculture through Technology.</h5>
                                    <div className='text-center mt-4 only-desktop-margin'>
                                        <Link class="btn btn-theme btn-md radius animation" to="/about-us">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <nav class="navbar navbar-expand-lg" style={{ position: 'absolute', top: '0', width: '100%', right: '100px', zIndex: '99991' }}>
                            <div class="desktop-show pt-3">
                                <form class="d-flex">
                                    <div class="collapse navbar-collapse main-page-nav" id="navbarNavDropdown">
                                        <ul class="navbar-nav">
                                            <li class="nav-item dropdown">
                                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Company
                                                </a>
                                                <ul class="dropdown-menu change-lineh" aria-labelledby="navbarDropdownMenuLink">
                                                    <li><Link class="dropdown-item" to='/about-us'>About Us</Link></li>
                                                    {/* <li><a class="dropdown-item" href="#">Objective</a></li> */}
                                                    <li><Link class="dropdown-item" to='/team'>Leadership Team</Link></li>
                                                    <li><Link class="dropdown-item" to='/mission'>Mission</Link></li>
                                                </ul>
                                            </li>
                                            <li class="nav-item dropdown">
                                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Solutions
                                                </a>
                                                <ul class="dropdown-menu change-lineh" aria-labelledby="navbarDropdownMenuLink">
                                                    <li><Link class="dropdown-item" to='/farmers'>Farmers</Link></li>
                                                    <li><Link class="dropdown-item" to='/fpos'>FPOs</Link></li>
                                                    <li><Link class="dropdown-item" to='/fcos'>FPCs</Link></li>
                                                    {/* <li><Link class="dropdown-item" to="/traders">Traders</Link></li> */}
                                                </ul>
                                            </li>
                                            {/* <li class="nav-item dropdown">
                                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Services
                                                </a>
                                                <ul class="dropdown-menu change-lineh" aria-labelledby="navbarDropdownMenuLink">
                                                    <li><a class="dropdown-item" href="#">To be discussed</a></li>
                                                    <li><a class="dropdown-item" href="#">To be discussed</a></li>
                                                    <li><a class="dropdown-item" href="#">To be discussed</a></li>
                                                </ul>
                                            </li> */}
                                            <li class="nav-item">
                                                <Link class="nav-link" to="/careers">Careers</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" to='/blogs-page'>Blogs</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <img src={banner} className='videoTag' /> */}
            <Carousel className='top-carousel' autoPlay showArrows={false} showThumbs={false} showIndicators={false} interval={5000} infiniteLoop showStatus={false} stopOnHover={false}>
                <div>
                    <img src={banner} />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <img src={banner1} />
                </div>
                <div>
                    <img src={banner2} />
                </div>
            </Carousel>
        </div>
    )
}
export default homeBanner