import './App.css';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Home from './Components.js/Home';
import HomeBanner from './Components.js/HomeBanner';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import SecondPanel from './Components.js/SecondPanel';
import ThirdPanel from './Components.js/ThirdPanel';
import FourthPanel from './Components.js/FourthPanel';
import FifthPanel from './Components.js/FifthPanel';
import Blank from './Components.js/Blank';
import OurTeam from './Components.js/Ourteam';
import Products from './Components.js/Products';
import Testimonials from './Components.js/Testimonials';
import DownloadApplication from './Components.js/DownloadApplication';
import FAQs from './Components.js/FAQs';
import Blogs from './Components.js/Blogs';
import Register from './Components.js/Register';
import AboutUs from './Components.js/AboutUs';
import Navbar from './Components.js/Navbar';
import Team from './Components.js/Team';
import Farmers from './Components.js/Farmers';
import Mission from './Components.js/Mission';
import BlogsPage from './Components.js/BlogsPage';
import BlogsDetails from './Components.js/BlogDetails';
import FPOS from './Components.js/FPOS';
import FCOS from './Components.js/FCOS';
import Sidebar from './Components.js/Sidebar';
import Roadmap from './Components.js/Roadmap';
import Traders from './Components.js/Traders';
import Career from './Components.js/Career';
import TermsConditions from './Components.js/TermsConditions';
import PrivacyPolicy from './Components.js/PrivacyPolicy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Products1 from './Components.js/Products1';


function App() {
  return (
    <div className="App">
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route exact path="/home-banner" element={<HomeBanner />} />
            <Route exact path="/second-panel" element={<SecondPanel />} />
            <Route exact path="/third-panel" element={<ThirdPanel />} />
            <Route exact path="/fourth-panel" element={<FourthPanel />} />
            <Route exact path="/fifth-panel" element={<FifthPanel />} />
            <Route exact path="/blank" element={<Blank />} />
            <Route exact path="/our-team" element={<OurTeam />} />
            <Route exact path="/our-products" element={<Products />} />
            <Route exact path="/testimonials" element={<Testimonials />} />
            <Route exact path="/download-app" element={<DownloadApplication />} />
            <Route exact path="/faqs" element={<FAQs />} />
            <Route exact path="/blogs" element={<Blogs />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/about-us" element={<AboutUs />} />
            <Route exact path="/navbar" element={<Navbar />} />
            <Route exact path="/team" element={<Team />} />
            <Route exact path="/farmers" element={<Farmers />} />
            <Route exact path="/mission" element={<Mission />} />
            <Route exact path="/blogs-page" element={<BlogsPage />} />
            <Route exact path="/blogs-details" element={<BlogsDetails />} />
            <Route exact path="/fpos" element={<FPOS />} />
            <Route exact path="/fcos" element={<FCOS />} />
            <Route exact path="/sidebar" element={<Sidebar />} />
            <Route exact path="/roadmap" element={<Roadmap />} />
            <Route exact path="/traders" element={<Traders />} />
            <Route exact path="/careers" element={<Career />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/terms-conditions" element={<TermsConditions />} />
            <Route exact path="/products1" element={<Products1/>} />
          </Routes>
        </BrowserRouter>
      </SkeletonTheme>
      <ToastContainer position='bottom-right' />
    </div>
  );
}

export default App;
