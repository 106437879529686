import React, { useEffect, useState } from 'react'
import HomeBanner from './HomeBanner'
// import SecondPanel from './SecondPanel'
// import ThirdPanel from './ThirdPanel'
// import FourthPanel from './FourthPanel'
// import FifthPanel from './FifthPanel'
import OurTeam from './Ourteam'
// import Products from './Products'
import Testimonials from './Testimonials'
import DownloadApplication from './DownloadApplication'
// import FAQs from './FAQs'
// import Blogs from './Blogs'
import img1 from '../assets/images/register.png'
import img2 from '../assets/images/login.png'
import { Link } from 'react-router-dom'
import Sidebar from './Sidebar'
import logo from '../assets/images/Logo.webp'
import $ from 'jquery'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import InfiniteScroll from 'react-infinite-scroll-component';
import Products1 from './Products1'
import Faqs1 from './Faqs1'
import Footer1 from './Footer1'
const SecondPanel = React.lazy(() => import('./SecondPanel'));
const ThirdPanel = React.lazy(() => import('./ThirdPanel'));
const FourthPanel = React.lazy(() => import('./FourthPanel'));
const FifthPanel = React.lazy(() => import('./FifthPanel'));
const Products = React.lazy(() => import('./Products'));
const FAQs = React.lazy(() => import('./FAQs'));
const Blogs = React.lazy(() => import('./Blogs'));

const Home = () => {

    const [loading, setLoading] = useState(true)
    const toggleSidebar = (e) => {
        e.preventDefault()

        $('.dashboard-nav').toggle()
    }
    useEffect(() => {
        setLoading(false)
    }, [])
    $(window).scroll(function(){
        var sticky = $('.sticky-top-header'),
            scroll = $(window).scrollTop();
      
        if (scroll >= 100) {sticky.addClass('fixed-head'); $('.navbar-hide').hide();}
        else {sticky.removeClass('fixed-head'); $('.navbar-hide').show();}
      });
    // useEffect(() => {
    //     var addScript = document.createElement("script");
    //     addScript.setAttribute(
    //         "src",
    //         "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    //     );
    //     document.body.appendChild(addScript);
    //     window.googleTranslateElementInit = googleTranslateElementInit;
    // }, []);

    // const googleTranslateElementInit = () => {
    //     new window.google.translate.TranslateElement(
    //         {
    //             pageLanguage: "en",
    //             autoDisplay: false
    //         },
    //         "google_translate_element"
    //     )
    // }
    const data = [[<SecondPanel />],
    [<ThirdPanel />],
    [<FourthPanel />],
    [<FifthPanel />],
    // [<Products />],
    [<Products1 />],
    // [<FAQs />],
    [<Faqs1 />],
    [<Blogs />],
    ];
    return (
        <>
            {/* <div id="google_translate_element"></div> */}
            <nav class="navbar sticky-top-header" style={{ backgroundColor: 'white', padding: '10px 0', display: 'none', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 4px 5px' }}>
                <div class="container desktop-show">
                    <Link to="/" style={{ color: 'white' }}>
                        <img src={logo} style={{width: '120px'}} loading='lazy' />
                    </Link>
                    <form class="d-flex">
                        <Link to='/register'><div className='mx-4' style={{ color: '#1C461A', cursor: 'pointer', fontWeight: '700' }}>
                            <img src={img1} style={{ marginRight: "5px", height: '15px' }} />Registration
                        </div></Link>
                        <div className='mx-4' style={{ color: '#1C461A', cursor: 'pointer', fontWeight: '700' }}>
                            <img src={img2} style={{ marginRight: "5px", height: '15px' }} />Login
                        </div>
                        {/* <div className='mx-4' style={{ color: 'white' }}>
                                Language
                            </div> */}
                    </form>
                </div>
                <div className='mobile-top-header py-2'>
                    <a href="#!" class="menu-toggle" onClick={toggleSidebar}>
                        <div className='toggle-buttons'>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a><a href="#"
                        class="brand-logo">
                        <span>
                            <img src={logo} style={{ width: '140px' }} />
                        </span></a>
                </div>
            </nav>
            <nav class="navbar hide-navbar" style={{ backgroundColor: '#1c461a', padding: '0' }}>
                <div class="container desktop-show">
                    <a class="" style={{ color: 'white' }}></a>
                    <form class="d-flex">
                        <Link to='/register'><div className='mx-4' style={{ color: 'white', cursor: 'pointer' }}>
                            <img src={img1} style={{ marginRight: "5px", height: '15px' }} />Registration
                        </div></Link>
                        <div className='mx-4' style={{ color: 'white', cursor: 'pointer' }}>
                            <img src={img2} style={{ marginRight: "5px", height: '15px' }} />Login
                        </div>
                        {/* <div className='mx-4' style={{ color: 'white' }}>
                                Language
                            </div> */}
                    </form>
                </div>
                <div className='mobile-top-header py-2'>
                    <a href="#!" class="menu-toggle" onClick={toggleSidebar}>
                        <div className='toggle-buttons'>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a><a href="#"
                        class="brand-logo">
                        <span>
                            <img src={logo} style={{ width: '140px' }} />
                        </span></a>
                </div>
            </nav>
            <Sidebar />
            <HomeBanner />
            <InfiniteScroll dataLength={data.length} //This is important field to render the next data
                hasMore={true}>
                {data.map((item) => {
                    return item
                })}
            </InfiniteScroll>
        </>
    )
}
export default Home