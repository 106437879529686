import React from 'react'
import img1 from '../assets/images/register.png'
import img2 from '../assets/images/login.png'
import { Link } from 'react-router-dom'
import logo from '../assets/images/Logo.webp'
import $ from 'jquery'
import Sidebar from './Sidebar'
const TopHeader = () => {
    const toggleSidebar = (e) => {
        e.preventDefault()

        $('.dashboard-nav').toggle()
    }
    $(window).scroll(function(){
        var sticky = $('.sticky-top-header'),
            scroll = $(window).scrollTop();
      
        if (scroll >= 100) {sticky.addClass('fixed-head'); $('.navbar-hide').hide();}
        else {sticky.removeClass('fixed-head'); $('.navbar-hide').show();}
      });
    return (
        <>
            <Sidebar />
            <nav class="navbar sticky-top-header" style={{ backgroundColor: 'white', padding: '10px 0', display: 'none', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 4px 5px' }}>
                <div class="container desktop-show">
                    <Link to="/" style={{ color: 'white' }}>
                        <img src={logo} style={{width: '120px'}} loading='lazy' />
                    </Link>
                    <form class="d-flex">
                        <Link to='/register'><div className='mx-4' style={{ color: '#1C461A', cursor: 'pointer', fontWeight: '700' }}>
                            <img src={img1} style={{ marginRight: "5px", height: '15px' }} />Registration
                        </div></Link>
                        <div className='mx-4' style={{ color: '#1C461A', cursor: 'pointer', fontWeight: '700' }}>
                            <img src={img2} style={{ marginRight: "5px", height: '15px' }} />Login
                        </div>
                        {/* <div className='mx-4' style={{ color: 'white' }}>
                                Language
                            </div> */}
                    </form>
                </div>
                <div className='mobile-top-header py-2'>
                    <a href="#!" class="menu-toggle" onClick={toggleSidebar}>
                        <div className='toggle-buttons'>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a><a href="#"
                        class="brand-logo">
                        <span>
                            <img src={logo} style={{ width: '140px' }} loading='lazy' />
                        </span></a>
                </div>
            </nav>
            <nav class="navbar hide-navbar" style={{ backgroundColor: '#1c461a', padding: '0' }}>
                <div class="container desktop-show">
                    <a class="" style={{ color: 'white' }}></a>
                    <form class="d-flex">
                        <Link to='/register'><div className='mx-4' style={{ color: 'white', cursor: 'pointer' }}>
                            <img src={img1} style={{ marginRight: "5px", height: '15px' }} />Registration
                        </div></Link>
                        <div className='mx-4' style={{ color: 'white', cursor: 'pointer' }}>
                            <img src={img2} style={{ marginRight: "5px", height: '15px' }} />Login
                        </div>
                        {/* <div className='mx-4' style={{ color: 'white' }}>
                            Language
                        </div> */}
                    </form>
                </div>
                <div className='mobile-top-header py-2'>
                    <a href="#!" class="menu-toggle" onClick={toggleSidebar}>
                        <div className='toggle-buttons'>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a><a href="#"
                        class="brand-logo">
                        <span>
                            <Link to='/'><img src={logo} style={{ width: '140px' }} /></Link>
                        </span></a>
                </div>
            </nav>
        </>
    )
}
export default TopHeader