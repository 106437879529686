import React, { Suspense } from 'react'
import img1 from '../assets/images/about-us1.webp'
import img2 from '../assets/images/section2b.webp'
import img3 from '../assets/images/tick.jpg'
import img4 from '../assets/images/about-us2.png'
import LazyLoad from 'react-lazy-load';

const ThirdPanel = () => {
    return (
        <>
            <section className='mt-4 pt-4 mb-4 position-relative' style={{ background: 'linear-gradient(359deg, rgba(224, 252, 228, 0.54) 37.45%, rgba(232, 237, 233, 0.00) 92.02%)', backgroundRepeat: 'no-repeat', paddingBottom: '120px' }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div style={{ position: 'relative' }}>

                                <img src={img1} loading='lazy' />


                                <img src={img2} loading='lazy' style={{ position: 'absolute', left: '-16px', bottom: '-116px', borderRadius: '50%' }} />

                            </div>
                        </div>
                        <div className='col-md-6 margin-mobile'>
                            <div className=''>
                                <div className='hover-effect' style={{ textAlign: 'center', border: '1px solid #37A033', borderRadius: '25px', width: '200px', height: '40px', color: '#37A033', fontWeight: '600', fontSize: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>About Us</div>
                                <h4 className='primary-color mt-3 f-16' style={{textAlign: 'justify'}}>Kivisa provides a comprehensive suite of digital tools and resources designed to cater to the diverse needs of modern agriculture</h4>
                                {/* <h5 className='secondary-color text-left mt-4'>At Kivisa, we are on a mission to transform the agricultural landscape through cutting-edge technology and innovative solutions.</h5> */}
                                <ul className='custom-icon mt-4' style={{ listStyleType: 'none' }}>
                                    <li className='f-14' style={{display: 'flex'}}><img src={img3} style={{ marginRight: '16px', maxHeight: '24px' }} />We are passionate about revolutionising the agricultural landscape through innovative technology.</li>
                                    <li className='f-14' style={{display: 'flex'}}><img src={img3} style={{ marginRight: '16px', maxHeight: '24px' }} />Kivisa is committed to supporting farmers in their journey towards more efficient, profitable, and environmentally conscious farming practices.</li>
                                    <li className='f-14' style={{display: 'flex'}}><img src={img3} style={{ marginRight: '16px', maxHeight: '24px' }} />Dedicated to fostering a community where knowledge sharing, sustainable practices, and technological advancements converge.</li>
                                </ul>
                                <div className='about-us-fixed-text' style={{ position: 'absolute', top: '68px', right: '20px', opacity: '0.4' }}>
                                    <p>Natural Farming</p>
                                </div>
                                <div className='about-us-image' style={{ position: 'absolute', top: '0', right: '0', opacity: '0.4' }}>
                                    <img src={img4} style={{ width: '250px', height: '250px' }} />
                                    {/* <p className='position-absolute' style={{ top: '34%', left: '118px' }}>Natural Planting</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ThirdPanel