import React, { useEffect, useState } from 'react'
import TopHeader from './TopHeader'
import Navbar from './Navbar'
import img1 from '../assets/images/FARMER.png'
import axios from '../axiosInstance'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from 'react-modal';
import TermsConditions from './TermsConditions'
import $ from 'jquery'
import moment from 'moment'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const MODAL_STYLES = {
    position: "absolute",
    backgroundColor: "#FFF",
    padding: "15px",
    zIndex: "1000",
    width: "55%",
    margin: '10px 0',
    borderRadius: ".5em"
};
const OVERLAY_STYLE = {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0, .8)",
    zIndex: "1000",
    overflowY: "auto"
};


const buyerData = [{ id: 1, value: "Trader" }, { id: 2, value: "Commission Agent" }, { id: 3, value: "Co-Operative" }, { id: 4, value: "Exporter" }, { id: 5, value: "Processor" }, { id: 6, value: "Government Agency" }, { id: 7, value: "Retailer" }, { id: 8, value: "Aggregator" }, { id: 9, value: "Others" }]
const sellerData = [{ id: 1, value: "Individual Farmer" }, { id: 2, value: "Farmer Producer Company (FPC)" }, { id: 3, value: "Co-Operative" }, { id: 4, value: "Farmer Group (SHG)" }, { id: 5, value: "Government Agency" }, { id: 6, value: "Other (Aggregator etc.)" }]
const Register = () => {

    const [level, setLevel] = useState('')
    const [seeImg1, setSeeImg1] = useState('hidden')
    const [seeImg2, setSeeImg2] = useState('hidden')
    const [typeSelect, setTypeSelect] = useState([])
    const [subCategory, setSubCategory] = useState([])
    const [state, setState] = useState([])
    const [mandi, setMandi] = useState([])
    const [district, setDistrict] = useState([])
    const [subDistrict, setSubDistrict] = useState([])
    const [currentState, setCurrentState] = useState()
    const [checkTerms, setCheckTerms] = useState(true)

    const [modalIsOpen, setIsOpen] = React.useState(false);

    let subtitle;
    function openModal() {
        setIsOpen(true);
    }

    // function afterOpenModal() {
    //     // references are now sync'd and can be accessed.
    //     subtitle.style.color = '#f00';
    // }

    function closeModal() {
        setIsOpen(false);
    }


    $("#date").on("change", function () {
        this.setAttribute(
            "data-date",
            moment(this.value, "YYYY-MM-DD")
                .format(this.getAttribute("data-date-format"))
        )
    }).trigger("change")

    //data to be send

    const [regtype, setRegType] = useState('')
    const [type, setType] = useState('')
    const [regCategory, setRegCategory] = useState('')
    const [regLevel, setRegLevel] = useState('')
    const [regState, setRegState] = useState('')
    const [regMandi, setRegMandi] = useState('')
    const [title, setTitle] = useState('')
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [gender, setGender] = useState('')
    const [dob, setDob] = useState()
    const [relationType, setRelationType] = useState('')
    const [relationName, setRelationName] = useState('')
    const [address, setAddress] = useState('')
    const [pincode, setPincode] = useState('')
    const [allState, setAllState] = useState('')
    const [valueDistrict, setValueDistrict] = useState('')
    const [valueSubDistrict, setValueSubDistrict] = useState('')
    const [city, setCity] = useState('')
    const [post, setPost] = useState('')
    const [photoType, setPhotoType] = useState('')
    const [photoNo, setPhotoNo] = useState('')
    const [mobileNo, setMobileNo] = useState('')
    const [emailId, setEmailId] = useState('')
    const [licenseNo, setLicenseNo] = useState('')
    const [ifscCode, setIfscCode] = useState('')
    const [holderName, setHolderName] = useState('')
    const [bankName, setBankName] = useState('')
    const [bankAccount, setBankAccount] = useState('')
    const [confirmBankAccount, setConfirmBankAccount] = useState('')
    const [branchName, setBranchName] = useState('')
    const [branchAddress, setBranchAddress] = useState('')
    const [chequeImg, setChequeImg] = useState('')
    const [idImg, setIdImg] = useState('')
    const [getEmail, setGetEmail] = useState('')
    const [checkboxValue, setCheckboxValue] = useState(false)

    const [formDetails, setFormDetails] = useState([])
    //end

    const submitForm = (e) => {
        axios.post(`api/users/register-user`, {
            "categoryId": type,
            "subcategoryId": regCategory,
            "rLevel": level, //////////"State", "Mandi"
            "r_stateId": regState,
            "mandiId": regMandi,
            "title": title, ///"Mr.", "Mrs.", "Ms."
            "f_name": firstName,
            "m_name": middleName,
            "l_name": lastName,
            "gender": gender, ///Male', 'Female', 'Others
            "dob": dob,
            "r_type": relationType,
            "r_name": relationName,
            "address": address,
            "stateId": currentState,
            "tehsilId": valueSubDistrict,
            "districtId": valueDistrict,
            "city": city,
            "pincode": pincode,
            "post": post,
            "d_type": photoType, //"Aadhar Card","PAN Card","Ration Card","Driving License","Voter ID","Passport"
            "d_no": photoNo,
            "mobile_no": mobileNo,
            "email": emailId,
            "bank_name": bankName,
            "branch_code": branchName,
            "ac_name": holderName,
            "ac_no": bankAccount,
            "passbook": chequeImg,
            "d_photo": idImg,
            "email_notify": "1", ///0 1
            "sms_notify": "1" ///0 1
        }).
            then((resp) => {
                console.log(resp.data)
                if (!resp.data.status) {
                    toast.error(resp.data.msg, { toastId: "error1" });
                }
                else {
                    toast(
                        <div>
                            "Thanks for submitting documents to register with KIVISA, documents verification and approval may take 4-5 Business days. Our team will connect you soon."
                        </div>,
                        {
                            autoClose: false,
                            closeOnClick: false,
                            draggable: false,
                            position: "bottom-right",
                            toastId: "success53",
                        }
                    );
                    toast(resp.data.msg, { toastId: "success1" })
                    setFormDetails(resp.data.result)
                    setTimeout(() => {
                        window.location.reload();
                    }, 7000);
                }
            }).catch((err) => { console.log('eerr', err) });
    }

    function uploadFile1(file) {
        const formData = new FormData();
        formData.append('files', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        axios.post(`api/users/upload-a-photo`,
            formData,
        ).
            then((resp) => {
                setChequeImg(resp.data.result.image_url)
                console.log(resp.data)
            }).catch((err) => { console.log('eerr', err) });
    }

    function uploadFile2(file) {
        const formData = new FormData();
        formData.append('files', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        axios.post(`api/users/upload-a-photo`,
            formData,
        ).
            then((resp) => {
                setIdImg(resp.data.result.image_url)
                console.log(resp.data)
            }).catch((err) => { console.log('eerr', err) });
    }

    useEffect(() => {

        axios.get(`api/users/get-categories`).
            then((resp) => {
                console.log(resp.data.result)
                setTypeSelect(resp.data.result)
            }).catch((err) => { console.log('eerr', err) });

        axios.get(`api/users/get-states`).
            then((resp) => {
                console.log(resp.data.result)
                setState(resp.data.result)
            }).catch((err) => { console.log('eerr', err) });
    }, [])

    const imageUpload1 = async (event) => {
        setSeeImg1('visible')
        var output = document.getElementById('output');
        output.src = URL.createObjectURL(event.target.files[0]);
        output.onload = function () {
            URL.revokeObjectURL(output.src) // free memory
        }
        await uploadFile1(event.target.files[0])
        // setImg1('visible')
    }

    const imageUpload2 = async (event) => {
        setSeeImg2('visible')
        var output1 = document.getElementById('output1');
        output1.src = URL.createObjectURL(event.target.files[0]);
        output1.onload = function () {
            URL.revokeObjectURL(output1.src) // free memory
        }
        await uploadFile2(event.target.files[0])
    }
    const handleFirstSelect = (event) => {
        setType(event.target.value)
        axios.post(`api/users/get-sub-categories`, {
            "categoryId": event.target.value
        }).
            then((resp) => {
                console.log(resp.data.result)
                setSubCategory(resp.data.result)
            }).catch((err) => { console.log('eerr', err) });
    }

    const handleSecondSelect = (event) => {
        setCurrentState(event.target.value)
        setRegState(event.target.value)
        axios.post(`api/users/get-mandi`, {
            "stateId": event.target.value
        }).
            then((resp) => {
                console.log(resp.data.result)
                setMandi(resp.data.result)
            }).catch((err) => { console.log('eerr', err) });

        axios.post(`api/users/get-district`, {
            "stateId": event.target.value
        }).
            then((resp) => {
                console.log(resp.data.result)
                setDistrict(resp.data.result)
            }).catch((err) => { console.log('eerr', err) });
    }
    const handleThirdSelect = (event) => {
        setValueDistrict(event.target.value)
        axios.post(`api/users/get-tehsil`, {
            "districtId": event.target.value,
            "stateId": currentState
        }).
            then((resp) => {
                console.log(resp.data.result)
                setSubDistrict(resp.data.result)
            }).catch((err) => { console.log('eerr', err) });
    }
    return (
        <>

            <html lang="en-US">
                <meta http-equiv="content-type" content="text/html;charset=UTF-8" />
                <head>
                    <meta charset="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <title>Agrul &#8211; Organic Farm Agriculture Theme</title>
                    <meta name='robots' content='max-image-preview:large' />
                    <link rel='dns-prefetch' href='http://fonts.googleapis.com/' />
                    <link rel="alternate" type="application/rss+xml" title="Agrul &raquo; Feed" href="feed/index.html" />
                    <link rel="alternate" type="application/rss+xml" title="Agrul &raquo; Comments Feed"
                        href="comments/feed/index.html" />
                    <script type="text/javascript">

                    </script>

                    <link rel='stylesheet' id='wc-blocks-style-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/wc-blocks4031.css'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-active-filters-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/active-filters4031.css'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-add-to-cart-form-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/add-to-cart-form4031.css'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-packages-style-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/packages-style4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-all-products-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/all-products4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-all-reviews-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/all-reviews4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-attribute-filter-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/attribute-filter4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-breadcrumbs-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/breadcrumbs4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-catalog-sorting-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/catalog-sorting4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-customer-account-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/customer-account4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-featured-category-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/featured-category4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-featured-product-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/featured-product4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-mini-cart-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/mini-cart4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-price-filter-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/price-filter4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-add-to-cart-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-add-to-cart4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-button-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-button4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-categories-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-categories4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-image-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-image4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-image-gallery-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-image-gallery4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-query-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-query4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-results-count-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-results-count4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-reviews-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-reviews4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-sale-badge-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-sale-badge4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-search-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-search4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-sku-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-sku4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-stock-indicator-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-stock-indicator4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-summary-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-summary4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-title-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-title4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-rating-filter-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/rating-filter4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-reviews-by-category-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/reviews-by-category4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-reviews-by-product-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/reviews-by-product4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-details-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-details4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-single-product-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/single-product4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-stock-filter-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/stock-filter4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-cart-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/cart4031.css?ver=10.6.5' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-checkout-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/checkout4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-mini-cart-contents-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/mini-cart-contents4031.css?ver=10.6.5'
                        type='text/css' media='all' />

                    <link rel='stylesheet' id='redux-extendify-styles-css'
                        href='wp-content/plugins/redux-framework/redux-core/assets/css/extendify-utilities4235.css?ver=4.4.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='contact-form-7-css'
                        href='wp-content/plugins/contact-form-7/includes/css/styles41a3.css?ver=5.8' type='text/css' media='all' />

                    <link rel='stylesheet' id='slick-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/libs/slick/slicke35d.css?ver=6.3.2' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='perfect-scrollbar-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/libs/perfect-scrollbar/css/perfect-scrollbar.mine35d.css?ver=6.3.2'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='perfect-scrollbar-wpc-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/libs/perfect-scrollbar/css/custom-themee35d.css?ver=6.3.2'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='magnific-popup-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/libs/magnific-popup/magnific-popupe35d.css?ver=6.3.2'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='woosq-feather-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/libs/feather/feathere35d.css?ver=6.3.2' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='woosq-frontend-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/css/frontendb045.css?ver=3.4.8' type='text/css'
                        media='all' />
                    <link rel='preload' as='font' type='font/woff2' crossorigin='anonymous' id='tinvwl-webfont-font-css'
                        href='wp-content/plugins/ti-woocommerce-wishlist/assets/fonts/tinvwl-webfontffc1.woff2?ver=xu2uyi'
                        media='all' />
                    <link rel='stylesheet' id='tinvwl-webfont-css'
                        href='wp-content/plugins/ti-woocommerce-wishlist/assets/css/webfont.minae29.css?ver=2.7.4' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='tinvwl-css'
                        href='wp-content/plugins/ti-woocommerce-wishlist/assets/css/public.minae29.css?ver=2.7.4' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='elementor-icons-css'
                        href='wp-content/plugins/elementor/assets/lib/eicons/css/elementor-icons.min192d.css?ver=5.23.0' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='elementor-frontend-legacy-css'
                        href='wp-content/plugins/elementor/assets/css/frontend-legacy.min8420.css?ver=3.16.1' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='elementor-frontend-css'
                        href='wp-content/plugins/elementor/assets/css/frontend.min8420.css?ver=3.16.1' type='text/css' media='all' />
                    <link rel='stylesheet' id='swiper-css'
                        href='wp-content/plugins/elementor/assets/lib/swiper/css/swiper.min48f5.css?ver=5.3.6' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='elementor-post-7-css'
                        href='wp-content/uploads/elementor/css/post-7760a.css?ver=1692473830' type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-flaticons-css'
                        href='wp-content/plugins/agrul-core/assets/fonts/flaticon-sete35d.css?ver=6.3.2' type='text/css' media='all' />
                    <link rel='stylesheet' id='elementor-global-css'
                        href='wp-content/uploads/elementor/css/global760a.css?ver=1692473830' type='text/css' media='all' />
                    <link rel='stylesheet' id='elementor-post-9-css'
                        href='wp-content/uploads/elementor/css/post-9760a.css?ver=1692473830' type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-style-css' href='wp-content/themes/agrul/stylef269.css?ver=1.0.1' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='agrul-fonts-css'
                        href='https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&amp;family=Handlee&amp;display=swap'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='swiper-bundle-css'
                        href='wp-content/themes/agrul/assets/css/swiper-bundle.min5152.css?ver=1.0' type='text/css' media='all' />
                    <link rel='stylesheet' id='shop-css' href='wp-content/themes/agrul/assets/css/shop5152.css?ver=1.0' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='agrul-animate-css' href='wp-content/themes/agrul/assets/css/animate5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='bootstrap-css' href='wp-content/themes/agrul/assets/css/bootstrap.min5b31.css?ver=4.3.1'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='elegant-css' href='wp-content/themes/agrul/assets/css/elegant-icons5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='flaticon-css' href='wp-content/themes/agrul/assets/css/flaticon-set5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='fontawesome-css'
                        href='wp-content/themes/agrul/assets/css/font-awesome.minad76.css?ver=5.9.0' type='text/css' media='all' />
                    <link rel='stylesheet' id='themify-css' href='wp-content/themes/agrul/assets/css/themify-icons5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-validnavs-css' href='wp-content/themes/agrul/assets/css/validnavs5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-helper-css' href='wp-content/themes/agrul/assets/css/helper5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-main-style-css' href='wp-content/themes/agrul/assets/css/stylef269.css?ver=1.0.1'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-unittest-css' href='wp-content/themes/agrul/assets/css/unit-test5152.css'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-color-schemes-css'
                        href='wp-content/themes/agrul/assets/css/color.schemese35d.css?ver=6.3.2' type='text/css' media='all' />

                    <link rel='stylesheet' id='google-fonts-1-css'
                        href='https://fonts.googleapis.com/css?family=Roboto%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CRoboto+Slab%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&amp;display=auto&amp;ver=6.3.2'
                        type='text/css' media='all' />
                    <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />

                    <script type="text/template" id="tmpl-unavailable-variation-template">
                        <p>Sorry, this product is unavailable. Please choose a different combination.</p>
                    </script>
                    <script type='text/javascript' src='wp-includes/js/jquery/jquery.min3088.js?ver=3.7.0' id='jquery-core-js'></script>
                    <script type='text/javascript' src='wp-includes/js/jquery/jquery-migrate.min5589.js?ver=3.4.1'
                        id='jquery-migrate-js'></script>
                    <link rel="https://api.w.org/" href="wp-json/index.html" />
                    <link rel="alternate" type="application/json" href="wp-json/wp/v2/pages/9.json" />
                    <link rel="EditURI" type="application/rsd+xml" title="RSD" href="xmlrpc0db0.php?rsd" />
                    <meta name="generator" content="WordPress 6.3.2" />
                    <meta name="generator" content="WooCommerce 8.0.2" />
                    <link rel="canonical" href="index.html" />
                    <link rel='shortlink' href='index.html' />
                    <link rel="alternate" type="application/json+oembed"
                        href="wp-json/oembed/1.0/embed751c.json?url=https%3A%2F%2Fvalidthemes.net%2Fthemeforest%2Fwp%2Fagrul%2F" />
                    <link rel="alternate" type="text/xml+oembed"
                        href="wp-json/oembed/1.0/embeda759?url=https%3A%2F%2Fvalidthemes.net%2Fthemeforest%2Fwp%2Fagrul%2F&amp;format=xml" />
                    <meta name="generator" content="Redux 4.4.5" /> <noscript>

                    </noscript>
                    <meta name="generator"
                        content="Elementor 3.16.1; settings: css_print_method-external, google_font-enabled, font_display-auto" />
                    <link rel="icon" href="wp-content/uploads/2023/04/favicon.png" sizes="32x32" />
                    <link rel="icon" href="wp-content/uploads/2023/04/favicon.png" sizes="192x192" />
                    <link rel="apple-touch-icon" href="wp-content/uploads/2023/04/favicon.png" />
                    <meta name="msapplication-TileImage"
                        content="https://validthemes.net/themeforest/wp/agrul/wp-content/uploads/2023/04/favicon.png" />

                </head>
                <TopHeader />

                <div class="breadcrumb-area custom-breadcrumb shadow dark bg-cover text-center text-light" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                    <Navbar />
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <h1 class="breadcumb-title">Register</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='mb-5'>
                        <div className='form mt-4'>
                            <div className='card p-4' style={{ border: '1px solid #1C461A' }}>
                                <div className='card-header mb-4' style={{ backgroundColor: '#1C461A', padding: '5px 16px' }}>
                                    <h4 className='text-left' style={{ fontWeight: '600', color: 'white' }}>Registration Form</h4>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 text-left'>
                                        <label className='mb-2'>Registration Type</label>
                                        <select className='form-select' onChange={handleFirstSelect}>
                                            <option disabled selected>Select Type</option>
                                            {typeSelect.map((data) => {
                                                return (
                                                    <option value={data.id}>{data.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {subCategory !== '' ?
                                        <div className='col-md-6 text-left'>
                                            <label className='mb-2'>Registration Category</label>
                                            <select className='form-select' onChange={(event) => setRegCategory(event.target.value)}>
                                                <option disabled selected>Select Category</option>
                                                {subCategory?.map((data) => {
                                                    return (
                                                        <option value={data.id}>{data.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div> : ''}
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-md-6 text-left'>
                                        <div className='d-flex align-items-center' style={{ justifyContent: 'space-between' }}>
                                            <label className='mb-0'>Registration Level</label>
                                            {regtype !== 'seller' ?
                                                <div className='d-flex align-items-center'>
                                                    <input type="radio" name='level' onChange={() => setLevel('State')} />
                                                    <label for="vehicle1" className='mb-0'> State</label>
                                                </div>
                                                : ''}
                                            <div className='d-flex align-items-center'>
                                                <input type="radio" name='level' onChange={() => setLevel('Mandi')} />
                                                <label for="vehicle2" className='mb-0'> Mandi</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {level != '' ? <div className='row mt-4'>
                                    <div className='col-md-6 text-left'>
                                        <label className='mb-2'>Register With State</label>
                                        <select className='form-select' onChange={handleSecondSelect}>
                                            <option disabled selected>Select State</option>
                                            {state?.map((data) => {
                                                return (
                                                    <option value={data.id}>{data.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {level == 'Mandi' ? <div className='col-md-6 text-left'>
                                        <label className='mb-2'>Register With Mandi</label>
                                        <select className='form-select' onChange={(event) => setRegMandi(event.target.value)}>
                                            {mandi?.map((data) => {
                                                return (
                                                    <option value={data.id}>{data.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div> : ''}
                                </div> : ''}
                            </div>
                        </div>
                        <div className='form mt-4'>
                            <div className='card p-4' style={{ border: '1px solid #1C461A' }}>
                                <div className='card-header mb-4' style={{ backgroundColor: '#1C461A', padding: '5px 16px' }}>
                                    <h4 className='text-left' style={{ fontWeight: '600', color: 'white' }}>Personal Details</h4>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4 text-left'>
                                        <label className='mb-2'>Title</label>
                                        <select className='form-select' onChange={(event) => setTitle(event.target.value)}>
                                            <option disabled selected>Select Title</option>
                                            <option value='Mr.'>Mr.</option>
                                            <option value='Mrs.'>Mrs.</option>
                                            <option value='Ms.'>Ms.</option>
                                        </select>
                                    </div>
                                    <div className='col-md-4 text-left'>
                                        <label className='mb-2'>First Name</label>
                                        <input type='text' className='form-control' placeholder='Enter First Name' onChange={(event) => setFirstName(event.target.value)} />
                                    </div>
                                    <div className='col-md-4 text-left'>
                                        <label className='mb-2'>Middle Name</label>
                                        <input type='text' className='form-control' placeholder='Enter Middle Name' onChange={(event) => setMiddleName(event.target.value)} />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-4 text-left'>
                                        <label className='mb-2'>Last Name</label>
                                        <input type='text' className='form-control' placeholder='Enter Last Name' onChange={(event) => setLastName(event.target.value)} />
                                    </div>
                                    <div className='col-md-4 text-left'>
                                        <label className='mb-2'>Gender</label>
                                        <select className='form-select' onChange={(event) => setGender(event.target.value)}>
                                            <option disabled selected>Select Gender</option>
                                            <option value='Male'>Male</option>
                                            <option value='Female'>Female</option>
                                            <option value='Other'>Other</option>
                                        </select>
                                    </div>
                                    <div className='col-md-4 text-left'>
                                        <label className='mb-2'>Date of Birth</label><br />
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            className='form-control dob-input'
                                            selected={dob}
                                            placeholderText='Enter Date of Birth'
                                            onChange={(date) => setDob(date)}
                                        />
                                        {/* <input type='date' id='date' className='form-control'  data-date="" data-date-format="DD MMMM YYYY" value={dob} onChange={(event) => setDob(event.target.value)} /> */}
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-6 text-left'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <label className='mb-2'>Relation Type</label>
                                                <select className='form-select' onChange={(event) => setRelationType(event.target.value)}>
                                                    <option disabled selected>Select Relation Type</option>
                                                    <option value='son'>Son of</option>
                                                    <option value='daughter'>Daughter of</option>
                                                    <option value='wife'>Wife of</option>
                                                    <option value='father'>Father of</option>
                                                    <option value='mother'>Mother of</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <label className='mb-1 mt-1'>Relation Name</label>
                                                <input type='text' className='form-control' placeholder='Enter Relation Name' onChange={(event) => setRelationName(event.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 text-left'>
                                        <label className='mb-2'>Address</label>
                                        <textarea class="form-control" style={{ width: "100%" }} rows="4" cols="30" onChange={(event) => setAddress(event.target.value)}> </textarea>
                                    </div>
                                </div>

                                <div className='row mt-3 text-left'>
                                    <div className='col-md-4'>
                                        <label className='mb-1 mt-1'>Pincode</label>
                                        <input type='text' className='form-control' placeholder='Enter Pincode' onChange={(event) => setPincode(event.target.value)} />
                                    </div>
                                    <div className='col-md-4 text-left'>
                                        <label className='mb-2'>State</label>
                                        <select className='form-select' onChange={handleSecondSelect}>
                                            <option disabled selected>Select State</option>
                                            {state?.map((data) => {
                                                return (
                                                    <option value={data.id}>{data.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className='col-md-4 text-left'>
                                        <label className='mb-2'>District</label>
                                        <select className='form-select' onChange={handleThirdSelect}>
                                            <option disabled selected>Select District</option>
                                            {district?.map((data) => {
                                                return (
                                                    <option value={data.id}>{data.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className='row mt-3 text-left'>
                                    <div className='col-md-4 text-left'>
                                        <label className='mb-2'>Sub-district</label>
                                        <select className='form-select' onChange={(event) => setValueSubDistrict(event.target.value)}>
                                            <option disabled selected>Select Sub-district</option>
                                            {subDistrict?.map((data) => {
                                                return (
                                                    <option value={data.id}>{data.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className='col-md-4 text-left'>
                                        <label className='mb-2'>City/Village</label>
                                        <input type='text' className='form-control' placeholder='Enter City/Village' onChange={(event) => setCity(event.target.value)} />
                                    </div>
                                    <div className='col-md-4'>
                                        <label className='mb-1 mt-1'>Post</label>
                                        <input type='text' className='form-control' placeholder='Enter Post' onChange={(event) => setPost(event.target.value)} />
                                    </div>
                                </div>


                                <div className='row mt-3 text-left'>
                                    <div className='col-md-4 text-left'>
                                        <label className='mb-2'>Photo ID Type</label>
                                        <select className='form-select' onChange={(event) => setPhotoType(event.target.value)}>
                                            <option disabled selected>Select Photo Id Type</option>
                                            <option value='Aadhar Card'>Aadhar Card</option>
                                            <option value='PAN Card'>Pan Card</option>
                                            <option value='Ration Card'>Ration Card</option>
                                            <option value='Driving License'>Driving License</option>
                                            <option value='Passport'>Passport</option>
                                            <option value='Voter ID'>Voter ID</option>
                                        </select>
                                    </div>
                                    <div className='col-md-4'>
                                        <label className='mb-1 mt-1'>Photo ID Number</label>
                                        <input type='text' className='form-control' placeholder='Enter Photo ID Number' onChange={(event) => setPhotoNo(event.target.value)} />
                                    </div>
                                    <div className='col-md-4'>
                                        <label className='mb-1 mt-1'>Mobile Number</label>
                                        <input type='text' className='form-control' placeholder='Enter Mobile Number' onChange={(event) => setMobileNo(event.target.value)} />
                                    </div>
                                </div>
                                <div className='row mt-3 text-left'>
                                    <div className='col-md-4'>
                                        <label className='mb-1 mt-1'>Email ID</label>
                                        <input type='email' className='form-control' placeholder='Enter Email ID' onChange={(event) => setEmailId(event.target.value)} />
                                    </div>
                                    <div className='col-md-4'>
                                        <label className='mb-1 mt-1'>License Number</label>
                                        <input type='text' className='form-control' placeholder='Enter License Number' onChange={(event) => setLicenseNo(event.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form mt-4 mb-5'>
                            <div className='card p-4' style={{ border: '1px solid #1C461A', padding: '5px 16px' }}>
                                <div className='card-header mb-4' style={{ backgroundColor: '#1C461A' }}>
                                    <h4 className='text-left' style={{ fontWeight: '600', color: 'white' }}>Bank Details</h4>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 text-left'>
                                        <label className='mb-2'>IFSC Code</label>
                                        <input type='text' className='form-control' placeholder='Enter IFSC Code' onChange={(event) => setIfscCode(event.target.value)} />
                                    </div>
                                    <div className='col-md-6 text-left'>
                                        <label className='mb-2'>Account Holder Name as per Bank A/C </label>
                                        <input type='text' className='form-control' placeholder="Enter Holder's Name" onChange={(event) => setHolderName(event.target.value)} />
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-md-6 text-left'>
                                        <label className='mb-2'>Bank Name</label>
                                        <input type='text' className='form-control' placeholder='Enter Bank Name' onChange={(event) => setBankName(event.target.value)} />
                                    </div>
                                    <div className='col-md-6 text-left'>
                                        <label className='mb-2'>Bank Account Number</label>
                                        <input type='text' className='form-control' placeholder="Enter Bank Account Number" onChange={(event) => setBankAccount(event.target.value)} />
                                    </div>
                                </div>

                                <div className='row mt-4'>
                                    <div className='col-md-6 text-left'>
                                        <label className='mb-2'>Confirm Bank Account Number</label>
                                        <input type='text' className='form-control' placeholder="Enter Confirm Bank Account Number" onChange={(event) => setConfirmBankAccount(event.target.value)} />
                                    </div>
                                    <div className='col-md-6 text-left'>
                                        <label className='mb-2'>Branch Name</label>
                                        <input type='text' className='form-control' placeholder='Enter Branch Name' onChange={(event) => setBranchName(event.target.value)} />
                                    </div>
                                </div>

                                <div className='row mt-4'>
                                    <div className='col-md-6 text-left'>
                                        <label className='mb-2'>Branch Address</label>
                                        <textarea class="form-control" style={{ width: "100%" }} rows="4" cols="30" onChange={(event) => setBranchAddress(event.target.value)}> </textarea>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-md-6 text-left'>
                                        <label className='mb-2'>Upload Copy Of Passbook/Cancelled Cheque</label>
                                        <form runat="server">
                                            <input accept="image/*" type='file' name='cheque' id="imgInp" onChange={imageUpload1} />
                                            <img id="output" src="#" alt="your image" style={{ height: '100px', width: '100px', marginTop: '10px', objectFit: 'cover', visibility: seeImg1 }} />
                                        </form>
                                    </div>
                                    <div className='col-md-6 text-left'>
                                        <label className='mb-2'>Upload One Scan Copy Of Id Proof</label>
                                        <form runat="server">
                                            <input accept="image/*" type='file' id="imgInp" onChange={imageUpload2} />
                                            <img id="output1" src="#" alt="your image" style={{ height: '100px', width: '100px', marginTop: '10px', objectFit: 'cover', visibility: seeImg2 }} />
                                        </form>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-md-6 text-left'>
                                        <label className='mb-2'>Registration Acknowledgement</label>
                                        <div className=''>
                                            <div className='d-flex align-items-center'>
                                                <input type="checkbox" id="acknowledge3" name="acknowledge3" value="Car" onChange={(event) => setGetEmail(event.target.value)} />
                                                <label for="acknowledge3" className='mb-0 mx-2'> Get Email</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-md-6 text-left'>
                                        <div className=''>
                                            <div className='d-flex align-items-center'>
                                                <input type="checkbox" id="acknowledge2" name="acknowledge2" value="Car" disabled={checkboxValue} required onChange={(e) => { setCheckTerms(false); setIsOpen(true); setCheckboxValue(true); }} />
                                                <label for="acknowledge2" className='mb-0 mx-2' style={{ color: 'red' }}> I Agree to the Terms & Conditions</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-md-4 text-left'>
                                        <button className='btn btn-success' onClick={submitForm} disabled={checkTerms} style={{ zIndex: '0' }}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >


                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                    overlayClassName='registers-modal'
                    className='Modal'
                >
                    <div style={OVERLAY_STYLE}>
                        <div style={MODAL_STYLES}>
                            <div className='container'>
                                <h2 className='secondary-color text-left mt-5 mb-5'>Terms & Conditions</h2>
                                <h5 className='primary-color text-left'>Effective Date : 10-11-2023</h5>
                                <h5 className='primary-color text-left'>Welcome to KIVISA Agro Private Limited</h5>


                                <p className='text-left mt-5'>These terms of service outline the rules and regulations for the use of KIVISA Agro Private Limited's Website.</p>
                                <p className='text-left mt-3'>KIVISA Agro Private Limited is located at:</p>
                                <div className='text-left'>
                                    <p className='primary-color'>Unit No-1027, 10th Floor, Tower A, Spaze IT Park Sector-49, Gurgaon</p>
                                    <p className='primary-color'>Haryana-122018, India</p>
                                </div>

                                <p className='text-left mt-5'>By accessing this website we assume you accept these terms of service in full. Do
                                    not continue to use KIVISA Agro Private Limited's website if you do not accept all of
                                    the terms of service stated on this page.
                                </p>

                                <p className='text-left'>The following terminology applies to these Terms of Service, Privacy Statement and
                                    Disclaimer Notice and any or all Agreements: "Client", "You" and "Your" refers to you,
                                    the person accessing this website and accepting the Company's terms of service.
                                    "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party",
                                    "Parties", or "Us", refers to both the Client and ourselves, or either the Client or
                                    ourselves. All terms refer to the offer, acceptance and consideration of payment
                                    necessary to undertake the process of our assistance to the Client in the most
                                    appropriate manner, whether by formal meetings of a fixed duration, or any other
                                    means, for the express purpose of meeting the Client's needs in respect of provision
                                    of the Company's stated services/products, in accordance with and subject to,
                                    prevailing law of India. Any use of the above terminology or other words in the
                                    singular, plural, capitalisation and/or he/she or they, are taken as interchangeable
                                    and therefore as referring to the same.</p>

                                {/* <ul class="check-solid-list mt-4">
                    <li>Website Visitors</li>
                    <li>Personally-Identifying Information</li>
                    <li>Security</li>
                    <li>Protection of Certain Personally-Identifying Information</li>
                    <li>Aggregated Statistics</li>
                    <li>Affiliate Disclosure</li>
                    <li>Cookies</li>
                    <li>E-commerce</li>
                    <li>Privacy Policy Changes</li>
                    <li>Contact Information & Credit</li>
                </ul> */}
                                <div className='text-left mt-5'>
                                    <h3 className='secondary-color'>1. Cookies</h3>
                                    <p>We employ the use of cookies. By using KIVISA Agro Private Limited's website you
                                        consent to the use of cookies in accordance with KIVISA Agro Private Limited's
                                        privacy policy.</p>

                                    <p>Most of the modern day interactive websites use cookies to enable us to retrieve
                                        user details for each visit. Cookies are used in some areas of our site to enable the
                                        functionality of this area and ease of use for those people visiting. Some of our
                                        affiliate / advertising partners may also use cookies.
                                    </p>
                                </div>
                                <div className='text-left'>
                                    <h3 className='secondary-color'>2. License</h3>
                                    <p>Unless otherwise stated, KIVISA Agro Private Limited and/or its licensors own the
                                        intellectual property rights for all material on KIVISA Agro Private Limited including
                                        trademarks, copyrights, and proprietary information, associated with our Platform
                                        and its content are owned by us or our licensors. These rights are protected by
                                        applicable intellectual property laws.
                                    </p>
                                    <p>Limited License: We grant you a limited, non-exclusive, non-transferable, and
                                        revocable license to access and use our Platform for its intended purposes. This
                                        license does not grant you any rights to use our intellectual property except as
                                        expressly permitted by these Terms.
                                    </p>
                                    <p>You must not:</p>
                                    <ul class="check-solid-list mt-2 mb-4">
                                        <li>Republish material from kivisa.in</li>
                                        <li>Sell, rent or sub-license material from kivisa.in</li>
                                        <li>Reproduce, duplicate or copy material from kivisa.in</li>
                                    </ul>
                                    <p>Redistribute content from KIVISA Agro Private Limited (unless content is specifically
                                        made for redistribution).</p>
                                </div>
                                <div className='text-left'>
                                    <h3 className='secondary-color'>3. User Comments</h3>
                                    <p>This Agreement shall begin on the date 10/11/2023 hereof.
                                        Certain parts of this website offer the opportunity for users to post and
                                        exchange opinions, information, material and data in areas of the website.
                                        KIVISA Agro Private Limited does not screen, edit, publish or review
                                        Comments prior to their appearance on the website and Comments do not
                                        reflect the views or opinions of KIVISA Agro Private Limited, its agents or
                                        affiliates. Comments reflect the view and opinion of the person who posts
                                        such a view or opinion. To the extent permitted by applicable laws, KIVISA
                                        Agro Private Limited shall not be responsible or liable for the Comments or for
                                        any loss cost, liability, damages or expenses caused and or suffered as a
                                        result of any use of and/or posting of and/or appearance of the Comments on
                                        this website.
                                    </p>
                                    <p>KIVISA Agro Private Limited reserves the right to monitor all Comments and to
                                        remove any Comments which it considers in its absolute discretion to be
                                        inappropriate, offensive or otherwise in breach of these Terms of Service.
                                        You warrant and represent that:
                                    </p>
                                    <ul class="check-solid-list mt-2 mb-4">
                                        <li>You are entitled to post the Comments on our website and have all
                                            necessary licenses and consents to do so;</li>
                                        <li>The Comments do not infringe any intellectual property right, including
                                            without limitation copyright, patent or trademark, or other proprietary
                                            right of any third party;
                                        </li>
                                        <li>The Comments do not contain any defamatory, libelous, offensive,
                                            indecent or otherwise unlawful material or material which is an
                                            invasion of privacy</li>
                                        <li>The Comments will not be used to solicit or promote business or
                                            custom or present commercial activities or unlawful activity.
                                        </li>
                                    </ul>
                                    <p>You hereby grant to KIVISA Agro Private Limited a non-exclusive royalty-free
                                        license to use, reproduce, edit and authorise others to use, reproduce and edit
                                        any of your Comments in any and all forms, formats or media.
                                    </p>
                                </div>

                                <div className='text-left'>
                                    <h3 className='secondary-color'>4. Hyperlinking to our Content</h3>
                                    <p>The following organisations may link to our Web site without prior written
                                        approval:
                                    </p>
                                    <ul class="check-solid-list mt-2 mb-4">
                                        <li>Government agencies;</li>
                                        <li>Search engines;
                                        </li>
                                        <li>News organisations;</li>
                                        <li>Online directory distributors when they list us in the directory may link
                                            to our Web site in the same manner as they hyperlink to the Web sites
                                            of other listed businesses; and
                                        </li>
                                        <li>Systemwide Accredited Businesses except soliciting non-profit
                                            organisations, charity shopping malls, and charity fundraising groups
                                            which may not hyperlink to our Website.</li>
                                    </ul>
                                    <p>These organisations may link to our home page, to publications or to other
                                        Web site information so long as the link: (a) is not in any way misleading; (b)
                                        does not falsely imply sponsorship, endorsement or approval of the linking
                                        party and its products or services; and (c) fits within the context of the linking
                                        party's site.
                                    </p>
                                    <p>These organisations may link to our home page, to publications or to other
                                        Web site information so long as the link:
                                    </p>
                                    <ul class="check-solid-list mt-2 mb-4">
                                        <li>is not in any way misleading;</li>
                                        <li>does not falsely imply sponsorship, endorsement or approval of the linking
                                            party and its products or services; and
                                        </li>
                                        <li>fits within the context of the linking
                                            party's site.</li>
                                    </ul>
                                    <p>We may consider and approve in our sole discretion other link requests from
                                        the following types of organisations:
                                    </p>
                                    <ul class="check-solid-list mt-2 mb-4">
                                        <li>commonly-known consumer and/or business information sources
                                            such as Chambers of Commerce, American Automobile Association,
                                            AARP and Consumers Union;
                                        </li>
                                        <li>dot.com community sites;
                                        </li>
                                        <li>associations or other groups representing charities, including charity
                                            giving sites,
                                        </li>
                                        <li>online directory distributors;</li>
                                        <li>internet portals;
                                        </li>
                                        <li>accounting, law and consulting firms whose primary clients are
                                            businesses; and</li>
                                        <li>educational institutions and trade associations</li>
                                    </ul>

                                    <p>We will approve link requests from these organisations if we determine that:
                                    </p>
                                    <ul class="check-solid-list mt-2 mb-4">
                                        <li>the
                                            link would not reflect unfavourably on us or our accredited businesses (for example,
                                            trade associations or other organisations representing inherently suspect types of
                                            business, such as work-at-home opportunities, shall not be allowed to link);
                                        </li>
                                        <li>the
                                            organisation does not have an unsatisfactory record with us;
                                        </li>
                                        <li>the benefit to us
                                            from the visibility associated with the hyperlink outweighs the absence of KIVISA
                                            Agro Private Limited; and
                                        </li>
                                        <li>where the link is in the context of general resource
                                            information or is otherwise consistent with editorial content in a newsletter or similar
                                            product furthering the mission of the organisation.</li>
                                    </ul>


                                    <p>These organisations may link to our home page, to publications or to other Web site
                                        information so long as the link
                                    </p>
                                    <ul class="check-solid-list mt-2 mb-4">
                                        <li>is not in any way misleading;
                                        </li>
                                        <li>does not falsely
                                            imply sponsorship, endorsement or approval of the linking party and it products or
                                            services; and
                                        </li>
                                        <li> fits within the context of the linking party's site.
                                        </li>
                                    </ul>
                                    <p>If you are among the organisations listed in paragraph 2 above and are interested in
                                        linking to our website, you must notify us by sending an email to contact@kivisa.in .
                                        Please include your name, your organisation name, contact information (such as a
                                        phone number and/or e-mail address) as well as the URL of your site, a list of any
                                        URLs from which you intend to link to our Web site, and a list of the URL(s) on our
                                        site to which you would like to link. Allow 2-3 weeks for a response.</p>

                                    <p>Approved organisations may hyperlink to our Web site as follows:
                                    </p>
                                    <ul class="check-solid-list mt-2 mb-4">
                                        <li>By use of our corporate name; or
                                        </li>
                                        <li>By use of the uniform resource locator (Web address) being linked to; or
                                        </li>
                                        <li>By use of any other description of our Web site or material being linked to that
                                            makes sense within the context and format of content on the linking party's
                                            site.
                                        </li>
                                    </ul>
                                    <p>No use of KIVISA Agro Private Limited's logo or other artwork will be allowed for
                                        linking absent a trademark license agreement.</p>
                                </div>

                                <div className='text-left'>
                                    <h3 className='secondary-color'>5. Iframes</h3>
                                    <p>Without prior approval and express written permission, you may not create frames
                                        around our Web pages or use other techniques that alter in any way the visual
                                        presentation or appearance of our Web site.
                                    </p>
                                </div>
                                <div className='text-left'>
                                    <h3 className='secondary-color'>7. Content Liability</h3>
                                    <p>We shall have no responsibility or liability for any content appearing on your Web
                                        site. You agree to indemnify and defend us against all claims arising out of or based
                                        upon your Website. No link(s) may appear on any page on your Web site or within
                                        any context containing content or materials that may be interpreted as libelous,
                                        obscene or criminal, or which infringes, otherwise violates, or advocates the
                                        infringement or other violation of, any third party rights.
                                    </p>
                                </div>

                                <div className='text-left'>
                                    <h3 className='secondary-color'>8. Reservation of Rights</h3>
                                    <p>We reserve the right at any time and in its sole discretion to request that you remove
                                        all links or any particular link to our Web site. You agree to immediately remove all
                                        links to our Web site upon such request. We also reserve the right to amend these
                                        terms of service and its linking policy at any time. By continuing to link to our Web
                                        site, you agree to be bound to and abide by these linking terms of service.
                                    </p>
                                </div>
                                <div className='text-left'>
                                    <h3 className='secondary-color'>9. Removal of links from our website</h3>
                                    <p>If you find any link on our Web site or any linked web site objectionable for any
                                        reason, you may contact us about this. We will consider requests to remove links but
                                        will have no obligation to do so or to respond directly to you.
                                    </p>
                                    <p>Whilst we endeavour to ensure that the information on this website is correct, we do
                                        not warrant its completeness or accuracy; nor do we commit to ensuring that the
                                        website remains available or that the material on the website is kept up to date.
                                    </p>
                                </div>

                                <div className='text-left'>
                                    <h3 className='secondary-color'>10.  Disclaimer</h3>
                                    <p>To the maximum extent permitted by applicable law, we exclude all representations,
                                        warranties and conditions relating to our website and the use of this website
                                        (including, without limitation, any warranties implied by law in respect of satisfactory
                                        quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in
                                        this disclaimer will:
                                    </p>
                                    <ul class="check-solid-list mt-2 mb-4">
                                        <li>limit or exclude our or your liability for death or personal injury resulting from
                                            negligence;
                                        </li>
                                        <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;
                                        </li>
                                        <li>limit any of our or your liabilities in any way that is not permitted under
                                            applicable law; or
                                        </li>
                                        <li>exclude any of our or your liabilities that may not be excluded under
                                            applicable law.
                                        </li>
                                    </ul>
                                    <p>The limitations and exclusions of liability set out in this Section and elsewhere in this
                                        disclaimer
                                    </p>
                                    <ul class="check-solid-list mt-2 mb-4">
                                        <li>are subject to the preceding paragraph; and
                                        </li>
                                        <li>govern all liabilities
                                            arising under the disclaimer or in relation to the subject matter of this disclaimer,
                                            including liabilities arising in contract, in tort (including negligence) and for breach of
                                            statutory duty.
                                        </li>
                                    </ul>
                                    <p>To the extent that the website and the information and services on the website are
                                        provided free of charge, we will not be liable for any loss or damage of any nature.
                                    </p>
                                    <p>Product Listings and Transactions:</p>
                                    <ul class="check-solid-list mt-2 mb-4">
                                        <li><strong>Product Listings:</strong> We strive to provide accurate and up-to-date product
                                            information on our Platform. However, we do not guarantee the accuracy,
                                            completeness, or reliability of product listings. You are responsible for
                                            verifying the accuracy and suitability of any product before making a
                                            purchase.
                                        </li>
                                        <li><strong>Transactions:</strong> Your interactions and transactions with other users or
                                            third-party sellers on our Platform are solely between you and the
                                            respective party. We are not a party to those transactions and do not
                                            guarantee the quality, safety, or legality of the products or services offered
                                            by others
                                        </li>
                                    </ul>
                                </div>
                                <div className='text-left mb-5'>
                                    <h3 className='secondary-color'>11. Lender Onboarding Terms and Conditions</h3>
                                    <h4 className='primary-color'>Effective Date: 10/10/2023</h4>
                                    <h5>1. Acceptance of Terms
                                    </h5>
                                    <p>By using this website and its lending services, you agree to be bound by these Lender
                                        Onboarding Terms and Conditions ("Terms"). If you do not agree to these Terms, you
                                        may not use the lending services provided by this website.
                                    </p>
                                    <h5>2. Eligibility
                                    </h5>
                                    <p>You must meet the following eligibility criteria to become a lender on this platform:
                                    </p><ul class="check-solid-list mt-2 mb-4">
                                        <li>You must be of legal age in your jurisdiction.
                                        </li>
                                        <li> You must have a valid bank account.
                                        </li>
                                        <li>You must comply with all applicable laws and regulations in your jurisdiction.
                                        </li>
                                    </ul>

                                    <h5>3. Registration and Know Your Customer (KYC)
                                    </h5>
                                    <ul class="check-solid-list mt-2 mb-4">
                                        <li>To become a lender on this platform, you must complete the registration process,
                                            which includes providing accurate and up-to-date information.
                                        </li>
                                        <li> You agree to undergo the KYC verification process, and you consent to the
                                            collection and processing of your personal and financial information for KYC and
                                            anti-money laundering (AML) purposes.
                                        </li>
                                    </ul>
                                    <h5>4. Investment Limits
                                    </h5>
                                    <p>The platform may establish minimum and maximum investment limits. These limits
                                        may vary based on the type of loans and your account status.
                                    </p>
                                    <h5>5.  Acknowledgment of Risks
                                    </h5>
                                    <p>You acknowledge and understand that lending involves financial risks, including the
                                        potential loss of your capital. You are solely responsible for evaluating and managing
                                        these risks.

                                    </p>
                                    <h5>6. Interest Rates and Terms
                                    </h5>
                                    <p>Lender interest rates and loan terms may vary based on individual loans. You agree
                                        to abide by the specific terms associated with each loan you choose to fund.
                                    </p>
                                    <h5>7. Fees and Commissions
                                    </h5>
                                    <p>The platform may charge fees or commissions for using its services, including but
                                        not limited to origination fees, servicing fees, and withdrawal fees. These fees will be
                                        clearly communicated to you.
                                    </p>
                                    <h5>8. Withdrawal and Liquidity Terms
                                    </h5>
                                    <p>Withdrawal of your funds may be subject to specific terms, such as lock-in periods,
                                        withdrawal restrictions, and liquidity provisions.
                                    </p>
                                    <h5>9. Compliance with Applicable Laws
                                    </h5>
                                    <p>You are responsible for complying with all laws and regulations relevant to your
                                        lending activities, including tax reporting and obligations.
                                    </p>
                                    <h5>10. Data Privacy and Security
                                    </h5>
                                    <p>We take data privacy and security seriously. Please refer to our Privacy Policy for
                                        details on how we handle your personal and financial information.
                                    </p>
                                    <h5>11. Termination of the Agreement
                                    </h5>
                                    <p>This agreement may be terminated by either party under the conditions specified in
                                        the full Terms and Conditions.
                                    </p>
                                    <h5>12. Change in Terms
                                    </h5>
                                    <p>We reserve the right to change these Terms. Notice of any changes will be provided
                                        to you, and your continued use of the platform will indicate your acceptance of the
                                        revised Terms.
                                    </p>
                                    <h5>13. Communication and Notifications
                                    </h5>
                                    <p>We will communicate with you through the contact information provided during
                                        registration. It is your responsibility to keep your contact information updated.
                                    </p>
                                    <h5>14.  Dispute Resolution
                                    </h5>
                                    <p>Any disputes arising from this agreement will be resolved in accordance with the
                                        dispute resolution process outlined in the full Terms and Conditions.
                                    </p>
                                </div>

                                <div className='text-left mb-5'>
                                    <h3 className='secondary-color'>12. Governing Law and Dispute Resolution:</h3>
                                    <p>These Terms shall be governed by and construed in accordance with the laws of
                                        Haryana. Any disputes arising out of or in connection with these Terms shall be
                                        resolved through arbitration in accordance with the Rules of Arbitration and
                                        Conciliation Act 1996.
                                    </p>
                                    <h5>1. Termination:
                                    </h5>
                                    <p> We reserve the right to suspend or terminate your access to our
                                        Platform, in whole or in part, at any time and for any reason without prior notice or
                                        liability.
                                    </p>
                                    <h5>2. Changes to the Terms:
                                    </h5>
                                    <p>We may update these Terms periodically to reflect changes in
                                        our practices or legal requirements. The revised Terms will be posted on our
                                        Platform, and the effective date will be updated accordingly. We encourage you to
                                        review these Terms regularly for any updates.
                                    </p>
                                    <h5>3. Contact Information:
                                    </h5>
                                    <p>If you have any questions or concerns regarding our Terms or
                                        the operation of our Platform, please contact us at contact@kivisa.in We will
                                        address your inquiries to the best of our ability.
                                    </p>
                                </div>
                                <button className='btn btn-success' onClick={closeModal}>Agree & Continue</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <link rel='stylesheet' id='elementor-post-1175-css'
                    href='wp-content/uploads/elementor/css/post-1175760a.css?ver=1692473830' type='text/css' media='all' />
                <link rel='stylesheet' id='elementor-post-317-css'
                    href='wp-content/uploads/elementor/css/post-317760a.css?ver=1692473830' type='text/css' media='all' />
                <link rel='stylesheet' id='e-animations-css'
                    href='wp-content/plugins/elementor/assets/lib/animations/animations.min8420.css?ver=3.16.1' type='text/css'
                    media='all' />

                <script type='text/javascript' src='wp-content/plugins/agrul-core/assets/js/agrul.ajax5152.js?ver=1.0'
                    id='agrul-ajax-js'></script>
                <script type='text/javascript' src='wp-content/plugins/contact-form-7/includes/swv/js/index41a3.js?ver=5.8'
                    id='swv-js'></script>

                <script type='text/javascript' src='wp-content/plugins/contact-form-7/includes/js/index41a3.js?ver=5.8'
                    id='contact-form-7-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/jquery-blockui/jquery.blockUI.min144d.js?ver=2.7.0-wc.8.0.2'
                    id='jquery-blockui-js'></script>

                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/frontend/add-to-cart.min76d9.js?ver=8.0.2'
                    id='wc-add-to-cart-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/js-cookie/js.cookie.min1ff0.js?ver=2.1.4-wc.8.0.2'
                    id='js-cookie-js'></script>

                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/frontend/woocommerce.min76d9.js?ver=8.0.2'
                    id='woocommerce-js'></script>
                <script type='text/javascript' src='wp-includes/js/underscore.mind584.js?ver=1.13.4' id='underscore-js'></script>

                <script type='text/javascript' src='wp-includes/js/wp-util.mine35d.js?ver=6.3.2' id='wp-util-js'></script>

                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/frontend/add-to-cart-variation.min76d9.js?ver=8.0.2'
                    id='wc-add-to-cart-variation-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/woo-smart-quick-view/assets/libs/slick/slick.minb045.js?ver=3.4.8'
                    id='slick-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/woo-smart-quick-view/assets/libs/perfect-scrollbar/js/perfect-scrollbar.jquery.minb045.js?ver=3.4.8'
                    id='perfect-scrollbar-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/woo-smart-quick-view/assets/libs/magnific-popup/jquery.magnific-popup.minb045.js?ver=3.4.8'
                    id='magnific-popup-js'></script>

                <script type='text/javascript' src='wp-content/plugins/woo-smart-quick-view/assets/js/frontendb045.js?ver=3.4.8'
                    id='woosq-frontend-js'></script>

                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/frontend/cart-fragments.min76d9.js?ver=8.0.2'
                    id='wc-cart-fragments-js'></script>

                <script type='text/javascript'
                    src='wp-content/plugins/ti-woocommerce-wishlist/assets/js/public.minae29.js?ver=2.7.4' id='tinvwl-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/bootstrap.min8a54.js?ver=1.0.0'
                    id='bootstrap-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/count-to8a54.js?ver=1.0.0'
                    id='count-to-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/swiper-bundle.min8a54.js?ver=1.0.0'
                    id='swiper-bundle-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/circle-progress8a54.js?ver=1.0.0'
                    id='circle-progress-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/jquery.nice-select.min8a54.js?ver=1.0.0'
                    id='nice-select-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/TweenMax.min8a54.js?ver=1.0.0'
                    id='TweenMax-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/jquery.scrolla.min8a54.js?ver=1.0.0'
                    id='scrolla-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/isotope.pkgd.min8a54.js?ver=1.0.0'
                    id='isotope-pkgd-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/jquery.appear8a54.js?ver=1.0.0'
                    id='appear-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/jquery.easing.min8a54.js?ver=1.0.0'
                    id='easing-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/modernizr8a54.js?ver=1.0.0'
                    id='modernizr-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/progress-bar.min8a54.js?ver=1.0.0'
                    id='progress-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/loopcounter8a54.js?ver=1.0.0'
                    id='loopcounter-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/validnavs8a54.js?ver=1.0.0'
                    id='agrul-validnavs-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/wow.min8a54.js?ver=1.0.0'
                    id='wow-js'></script>
                <script type='text/javascript' src='wp-includes/js/imagesloaded.mineda1.js?ver=4.1.4' id='imagesloaded-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/YTPlayer.min8a54.js?ver=1.0.0'
                    id='YTPlayer-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/mainf269.js?ver=1.0.1'
                    id='agrul-main-script-js'></script>
                <script type='text/javascript' src='wp-content/plugins/elementor/assets/js/webpack.runtime.min8420.js?ver=3.16.1'
                    id='elementor-webpack-runtime-js'></script>
                <script type='text/javascript' src='wp-content/plugins/elementor/assets/js/frontend-modules.min8420.js?ver=3.16.1'
                    id='elementor-frontend-modules-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/elementor/assets/lib/waypoints/waypoints.min05da.js?ver=4.0.2'
                    id='elementor-waypoints-js'></script>
                <script type='text/javascript' src='wp-includes/js/jquery/ui/core.min3f14.js?ver=1.13.2'
                    id='jquery-ui-core-js'></script>
                <script type='text/javascript' src='wp-content/plugins/elementor/assets/lib/swiper/swiper.min48f5.js?ver=5.3.6'
                    id='swiper-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/elementor/assets/lib/share-link/share-link.min8420.js?ver=3.16.1'
                    id='share-link-js'></script>
                <script type='text/javascript' src='wp-content/plugins/elementor/assets/lib/dialog/dialog.mind227.js?ver=4.9.0'
                    id='elementor-dialog-js'></script>

                <script type='text/javascript' src='wp-content/plugins/elementor/assets/js/frontend.min8420.js?ver=3.16.1'
                    id='elementor-frontend-js'></script>
                <script type='text/javascript' src='wp-content/plugins/elementor/assets/js/preloaded-modules.min8420.js?ver=3.16.1'
                    id='preloaded-modules-js'></script>
                <script type='text/javascript' src='wp-content/plugins/agrul-core/assets/js/agrul-core5152.js?ver=1.0'
                    id='agrul-core-js'></script>
                <script type='text/javascript' src='wp-content/plugins/agrul-core/assets/js/agrul-frontende35d.js?ver=6.3.2'
                    id='agrul-frontend-script-js'></script>
            </html>
        </>
    )
}
export default Register