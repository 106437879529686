import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/Logo.webp'

const Navbar = () => {
    return (
        <>
            <nav class="navbar navbar-expand-lg" style={{ position: 'absolute', top: '0', width: '100%' }}>
                <div class="container desktop-show pt-0">
                    <Link class="navbar-brand" to='/'>
                        <img src={logo} style={{width: '200px', paddingTop: '10px'}} />
                    </Link>
                    <form class="d-flex">
                        <div class="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul class="navbar-nav">
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Company
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><Link class="dropdown-item" to='/about-us'>About Us</Link></li>
                                        {/* <li><a class="dropdown-item" href="#">Objective</a></li> */}
                                        <li><Link class="dropdown-item" to='/team'>Leadership Team</Link></li>
                                        <li><Link class="dropdown-item" to='/mission'>Mission</Link></li>
                                    </ul>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Solutions
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><Link class="dropdown-item" to='/farmers'>Farmers</Link></li>
                                        <li><Link class="dropdown-item" to='/fpos'>FPOs</Link></li>
                                        <li><Link class="dropdown-item" to='/fcos'>FPCs</Link></li>
                                        {/* <li><Link class="dropdown-item" to="/traders">Traders</Link></li> */}
                                    </ul>
                                </li>
                                {/* <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Services
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><a class="dropdown-item" href="#">To be discussed</a></li>
                                        <li><a class="dropdown-item" href="#">To be discussed</a></li>
                                        <li><a class="dropdown-item" href="#">To be discussed</a></li>
                                    </ul>
                                </li> */}
                                <li class="nav-item">
                                    <Link class="nav-link" to="/careers">Careers</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" to='/blogs-page'>Blogs</Link>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </nav>
        </>
    )
}
export default Navbar