import React from 'react'
import TopHeader from './TopHeader'
import Navbar from './Navbar'
import Footer1 from './Footer1'

const Traders = () => {
    return (
        <>
            <TopHeader />
            <div class="breadcrumb-area custom-breadcrumb shadow dark bg-cover text-center text-light" style={{ backgroundImage: 'url(../wp-content/uploads/2022/11/1-1.jpg)' }}>
                <Navbar />
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1 class="breadcumb-title">Traders</h1>
                            <nav class="woocommerce-breadcrumb">Home&nbsp;&#47;&nbsp;Traders</nav>
                        </div>
                    </div>
                </div>
            </div>

            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-d4ead59 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                data-id="d4ead59" data-element_type="section"
                data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
                <div class="elementor-container elementor-column-gap-no">
                    <div class="elementor-row">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ee346a1"
                            data-id="ee346a1" data-element_type="column">
                            <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-0f19ba6 elementor-widget elementor-widget-agrul_product_feature"
                                        data-id="0f19ba6" data-element_type="widget"
                                        data-widget_type="agrul_product_feature.default">
                                        <div class="elementor-widget-container">

                                            <div
                                                class="product-feature-style-one-area overflow-hidden default-padding">
                                                <div class="container">
                                                    <div class="row align-center">
                                                        <div class="col-lg-5 mb-xs-50 mb-md-120">
                                                            <div class="product-feature-style-one">
                                                                <div class="info" style={{ textAlign: 'left' }}>
                                                                    <h5 class="title mb-25"> Supply Chain Management</h5>
                                                                    <p style={{fontSize: '12px'}}>
                                                                        Offering tools and services for effective supply chain management. This includes features such as inventory tracking, logistics support, and demand forecasting to ensure a smooth flow of produce from farms to markets. Integration of technologies like blockchain for transparency and traceability could enhance trust and efficiency in the supply chain.
                                                                    </p>

                                                                    <h5 class="title mb-25"> Market Linkages and Access</h5>
                                                                    <p style={{fontSize: '12px'}}>
                                                                        Providing avenues to connect farm producer companies directly with markets and potential buyers. This could involve an online marketplace or a platform that facilitates direct sales or partnerships with retailers, wholesalers, or even export channels. Tools for pricing insights, market trends, and trade regulations could further support their market access
                                                                    </p>

                                                                    <h5 class="title mb-25"> Financial and Administrative Support</h5>
                                                                    <p style={{fontSize: '12px'}}>
                                                                        Offering solutions that assist farm producer companies with financial management, including budgeting, invoicing, and payments. Additionally, administrative tools for documentation, certifications, and compliance with agricultural standards and regulations could be integrated to streamline operations and ensure adherence to industry requirements.
                                                                    </p>
                                                                    {/* <a href="#"
                                                                        class="popup-youtube video-play-button with-text mt-15">
                                                                        <div class="effect"></div>
                                                                        <span><i class="fas fa-play"></i> Our
                                                                            Story</span>
                                                                    </a> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 offset-lg-1">
                                                            <div
                                                                class="product-features-style-one pl-100 pl-md-15 pl-xs-15 pt-md-120">
                                                                <div class="organic-badge">
                                                                    <img decoding="async"
                                                                        src="../wp-content/uploads/2022/11/badge.png"
                                                                        alt="Agrul" />
                                                                </div>
                                                                <div class="product-feature-item">
                                                                    <div class="thumb">
                                                                        <img decoding="async"
                                                                            src="../wp-content/uploads/2022/11/4.png"
                                                                            alt="Agrul" />
                                                                    </div>
                                                                    <h4><a href="#">Fresh Fruits</a></h4>
                                                                </div>
                                                                <div class="product-feature-item">
                                                                    <div class="thumb">
                                                                        <img decoding="async"
                                                                            src="../wp-content/uploads/2022/10/6.png"
                                                                            alt="Agrul" />
                                                                    </div>
                                                                    <h4><a href="#">Sweet Exotic</a></h4>
                                                                </div>
                                                                <div class="product-feature-item">
                                                                    <div class="thumb">
                                                                        <img decoding="async"
                                                                            src="../wp-content/uploads/2022/11/8.png"
                                                                            alt="Agrul" />
                                                                    </div>
                                                                    <h4><a href="#">Dairy Products</a></h4>
                                                                </div>
                                                                <div class="product-feature-item">
                                                                    <div class="thumb">
                                                                        <img decoding="async"
                                                                            src="../wp-content/uploads/2022/11/9.png"
                                                                            alt="Agrul" />
                                                                    </div>
                                                                    <h4><a href="#">Vegetables</a></h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer1 />
        </>
    )
}
export default Traders