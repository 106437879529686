import React from 'react'
import img1 from '../assets/images/teamc.png'
import img2 from '../assets/images/teamb.png'
import img3 from '../assets/images/teama.png'
import img4 from '../assets/images/our-team.png'

const OurTeam = () => {
    return (
        <>
            <section className='mt-4 pt-4 mb-4 pb-5'>
                <div className='container-fluid'>
                    <div className='responsive-background' style={{ backgroundImage: 'url(' + img4 + ')', padding: '25px' }}>
                        <div className='text-center'>
                            <div className='primary-color' style={{ textAlign: 'center', border: '1px solid white', borderRadius: '25px', width: '200px', height: '40px', fontWeight: '600', fontSize: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto', backgroundColor: 'white' }}>Our Team</div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-4 mobile-margin-2'>
                                <div className=''>
                                    <div className='text-left position-relative hover-div'>
                                        <p style={{ fontWeight: '800', fontSize: '20px', color: 'white', textAlign: 'center', marginBottom: '10px' }}>Niraj Kumar</p>
                                        <img src={img3} style={{ width: '100%', borderRadius: '10px' }} />
                                        <div className='position-absolute show-on-hover' style={{ background: 'rgba(255, 255, 255, 1)', width: '100%', textAlign: 'center', padding: '20px', bottom: '26px', color: '#1C461A' }}>
                                            20+ Years in Several Leadership Positions
                                        </div>
                                        <p className='mb-0' style={{ fontWeight: '500', fontSize: '16px', color: 'white', textAlign: 'center' }}>Chairman</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mobile-margin-2'>
                                <div className=''>
                                    <div className='text-left position-relative hover-div'>
                                        <p style={{ fontWeight: '800', fontSize: '20px', color: 'white', textAlign: 'center', marginBottom: '10px' }}>Dhananjay Madhav Kaushal</p>
                                        <img src={img2} style={{ width: '100%', borderRadius: '10px' }} />
                                        <div className='position-absolute show-on-hover' style={{ background: 'rgba(255, 255, 255, 1)', width: '100%', textAlign: 'center', padding: '20px', bottom: '26px', color: '#1C461A' }}>
                                            Many Years of Experience (Blinkit, EaseMyTrip)
                                        </div>
                                        <p className='mb-0' style={{ fontWeight: '500', fontSize: '16px', color: 'white', textAlign: 'center' }}>Co Founder & Director</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className=''>
                                    <div className='text-left position-relative hover-div'>
                                        <p style={{ fontWeight: '800', fontSize: '20px', color: 'white', textAlign: 'center', marginBottom: '10px' }}>Hardev Singh</p>
                                        <img src={img1} style={{ width: '100%', borderRadius: '10px' }} />

                                        <div className='position-absolute show-on-hover' style={{ background: 'rgba(255, 255, 255, 1)', width: '100%', textAlign: 'center', padding: '20px', bottom: '26px', color: '#1C461A' }}>
                                            20+ Financial Solutions & Business Development
                                        </div>
                                        <p className='mb-0' style={{ fontWeight: '500', fontSize: '16px', color: 'white', textAlign: 'center' }}>Co Founder & Director</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default OurTeam