import React from "react";
import footer from "../assets/images/Footer.png";
import logo from "../assets/images/Logo.webp";
import { Link } from "react-router-dom";
import instagram from "../assets/images/instagram.png";
import facebook from "../assets/images/facebook.png";
import youtube from "../assets/images/youtube.png";
import $ from "jquery";

const Footer1 = () => {
  function scrollToTop() {
    $(window).scrollTop(0);
  }
  return (
    <>
      <footer
        className="footer footer-image"
        style={{
          backgroundImage: "url(" + footer + ")",
          backgroundSize: "100%",
          overflowX: "hidden",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-3" style={{ textAlign: "left" }}>
              <Link to="/">
                <img
                  src={logo}
                  style={{ width: "200px" }}
                  onClick={scrollToTop}
                />
              </Link>
              <p
                className="mt-3"
                style={{ textAlign: "left", wordSpacing: "8px" }}
              >
                At Kivisa, we believe in the transformative power of technology
                to address the challenges faced by the agricultural industry
              </p>
            </div>
            <div
              className="col-md-3 footer-col-mobile second-col-footer"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div>
                <h4 style={{ textAlign: "left" }}>Explore</h4>
                <ul
                  style={{
                    paddingLeft: "0",
                    listStyleType: "none",
                    lineHeight: "28px",
                    marginTop: "10px",
                  }}
                >
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/mission">Mission</Link>
                  </li>
                  <li>
                    <Link to="/blogs-page">Blogs</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 footer-col-mobile">
              <h4 style={{ textAlign: "left" }}>Contact Info</h4>
              <ul
                style={{
                  paddingLeft: "0",
                  listStyleType: "none",
                  lineHeight: "28px",
                  marginTop: "10px",
                }}
              >
                <li>
                  <strong>Address :</strong>
                  <p>
                    4th Floor, Plot No. 149, Sec - 44, Gurugram, Haryana -
                    122002, India
                  </p>
                </li>
                <li>
                  <strong>Email :</strong>
                  <p>
                    <a href="mailto:contact@kivisa.in">contact@kivisa.in</a>
                  </p>
                </li>
              </ul>
            </div>

            <div className="col-md-3 footer-col-mobile">
              <h4>Connect With Us</h4>
              <div
                className="d-flex mt-4"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <a
                  href="https://www.youtube.com/channel/UCanpkf-hQWRBDOsGtWS1_Ig"
                  target="_blank"
                  title="Kivisa-Youtube-Channel"
                >
                  <img
                    src={youtube}
                    style={{ width: "32px", height: "32px" }}
                    title="Kivisa-Youtube-Channel"
                  />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61553086749685"
                  target="_blank"
                  title="Kivisa-Facebook"
                >
                  <img
                    src={facebook}
                    style={{
                      width: "42px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                    title="Kivisa-Facebook"
                  />
                </a>
                <a
                  href="https://www.instagram.com/kivisaagro/?next=%2F"
                  target="_blank"
                  title="Kivisa-Instagram"
                >
                  <img
                    src={instagram}
                    style={{ width: "32px", height: "32px" }}
                    title="Kivisa-Instagram"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer1;
