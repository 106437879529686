import React, { Suspense } from 'react'
import img1 from '../assets/images/farmer-welfare.webp'
import img2 from '../assets/images/module1b.webp'
import img3 from '../assets/images/module1c.webp'
import img4 from '../assets/images/module1d.webp'
import LazyLoad from 'react-lazy-load';

const SecondPanel = () => {
    return (
        <>
            <section className='pt-4 mb-4 pb-4' style={{ background: 'rgba(224, 252, 228, 0.50)' }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3 g-2 d-flex align-items-stretch'>
                            <div class="card">
                                <div class="card-body pb-0">
                                    <h4 class="card-title primary-color my-2" style={{ fontWeight: '700' }}>Agri Input</h4>
                                    <div>
                                        <h6>Nurturing growth with precision</h6>
                                    </div>
                                </div>
                                <div className='text-center'>
                                    <img src={img2} class="card-img-top" loading='lazy' alt="..." style={{ marginTop: '6px' }} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 g-2 d-flex align-items-stretch'>
                            <div class="card">
                                <div class="card-body pb-0">
                                    <h4 class="card-title primary-color my-2" style={{ fontWeight: '700' }}>Crop Testing</h4>
                                    <h6>Empowering farmers through advanced crop testing</h6>
                                </div>
                                <div className='text-center'>
                                    <img src={img3} class="card-img-top" loading='lazy' alt="..." style={{ marginTop: '6px' }} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 g-2 d-flex align-items-stretch'>
                            <div class="card">
                                <div class="card-body pb-0">
                                    <h4 class="card-title primary-color my-2" style={{ fontWeight: '700' }}>Soil Assessment</h4>
                                    <h6>Cultivating insights from the ground up: Transforming agriculture through advanced soil assessment.</h6>
                                </div>
                                <div className='text-center'>
                                    <img src={img4} class="card-img-top" loading='lazy' alt="..." style={{ marginTop: '6px' }} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 g-2 d-flex align-items-stretch'>
                            <div class="card">
                                <div class="card-body pb-0">
                                    <h4 class="card-title primary-color my-2" style={{ fontWeight: '700' }}>Farmer Welfare</h4>
                                    <h6>Our commitment to holistic farmer welfare</h6>
                                </div>
                                <div className='text-center'>
                                    <img src={img1} class="card-img-top" loading='lazy' alt="..." style={{ marginTop: '6px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default SecondPanel