import React from 'react'
import '../assets/css/style.css'
import img1 from '../assets/images/Testimonial.png'
import img2 from '../assets/images/user.png'
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

const Testimonials = () => {
    return (
        <>
            <section className='pt-4 my-4 pb-4' style={{ backgroundImage: 'url(' + img1 + ')' }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-5'>
                            <div className=''>
                                <div className='primary-color hover-effect' style={{ textAlign: 'center', border: '1px solid #81C38A', borderRadius: '25px', width: '200px', height: '40px', color: 'white', fontWeight: '600', fontSize: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white' }}>Testimonials</div>
                                <h2 className='primary-color mt-4 text-left'>Our <span className='secondary-color'>Farmer</span> Say</h2>
                                <h6 className='primary-color text-left' style={{ fontWeight: '400' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has bee</h6>
                            </div>
                        </div>
                        <div className='col-md-7'>
                            <Swiper 
                            autoplay={{
                                delay: 3000,
                              }}
                            loop={true}
                            modules={[Autoplay]}
                            >
                                <SwiperSlide>
                                    <div className='d-flex align-items-center'>
                                        <img src={img2} />
                                        <div className='primary-color text-left'>Ramkumar <br /><span className='secondary-color'>COO Farmland</span></div>
                                    </div>
                                    <div className='parimary-color text-left'>
                                        Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightlyThere are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='d-flex align-items-center'>
                                        <img src={img2} />
                                        <div className='primary-color text-left'>Ramkumar <br /><span className='secondary-color'>COO Farmland</span></div>
                                    </div>
                                    <div className='parimary-color text-left'>
                                        Lorem Ipsum available, but the majority have suffered alteration in some form
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='d-flex align-items-center'>
                                        <img src={img2} />
                                        <div className='primary-color text-left'>Ramkumar <br /><span className='secondary-color'>COO Farmland</span></div>
                                    </div>
                                    <div className='parimary-color text-left'>
                                        Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightlyThere are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Testimonials