import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import TopHeader from './TopHeader'
import img1 from '../assets/images/blog-inner.png'
import blog1 from '../assets/images/blog_new1.webp'
import blog2 from '../assets/images/blog_new2.avif'
import blog3 from '../assets/images/blog_new3.jpg'
import blog4 from '../assets/images/blog_new4.jpg'
import blog5 from '../assets/images/blog_new5.webp'
import Footer1 from './Footer1'
import { BallTriangle } from 'react-loader-spinner'
import bg from '../assets/images/all-pages-bg.webp';



const BlogsPage = () => {

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000);
    }, [])
    return (
        <>

            <html lang="en-US">
                <meta http-equiv="content-type" content="text/html;charset=UTF-8" />
                <head>
                    <meta charset="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <title>Agrul &#8211; Organic Farm Agriculture Theme</title>
                    <meta name='robots' content='max-image-preview:large' />
                    <link rel='dns-prefetch' href='http://fonts.googleapis.com/' />
                    <link rel="alternate" type="application/rss+xml" title="Agrul &raquo; Feed" href="feed/index.html" />
                    <link rel="alternate" type="application/rss+xml" title="Agrul &raquo; Comments Feed"
                        href="comments/feed/index.html" />
                    <script type="text/javascript">

                    </script>

                    <link rel='stylesheet' id='wc-blocks-style-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/wc-blocks4031.css'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-active-filters-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/active-filters4031.css'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-add-to-cart-form-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/add-to-cart-form4031.css'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-packages-style-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/packages-style4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-all-products-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/all-products4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-all-reviews-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/all-reviews4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-attribute-filter-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/attribute-filter4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-breadcrumbs-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/breadcrumbs4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-catalog-sorting-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/catalog-sorting4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-customer-account-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/customer-account4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-featured-category-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/featured-category4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-featured-product-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/featured-product4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-mini-cart-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/mini-cart4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-price-filter-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/price-filter4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-add-to-cart-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-add-to-cart4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-button-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-button4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-categories-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-categories4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-image-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-image4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-image-gallery-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-image-gallery4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-query-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-query4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-results-count-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-results-count4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-reviews-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-reviews4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-sale-badge-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-sale-badge4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-search-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-search4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-sku-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-sku4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-stock-indicator-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-stock-indicator4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-summary-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-summary4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-title-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-title4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-rating-filter-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/rating-filter4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-reviews-by-category-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/reviews-by-category4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-reviews-by-product-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/reviews-by-product4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-details-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-details4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-single-product-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/single-product4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-stock-filter-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/stock-filter4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-cart-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/cart4031.css?ver=10.6.5' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-checkout-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/checkout4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-mini-cart-contents-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/mini-cart-contents4031.css?ver=10.6.5'
                        type='text/css' media='all' />

                    <link rel='stylesheet' id='redux-extendify-styles-css'
                        href='wp-content/plugins/redux-framework/redux-core/assets/css/extendify-utilities4235.css?ver=4.4.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='contact-form-7-css'
                        href='wp-content/plugins/contact-form-7/includes/css/styles41a3.css?ver=5.8' type='text/css' media='all' />

                    <link rel='stylesheet' id='slick-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/libs/slick/slicke35d.css?ver=6.3.2' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='perfect-scrollbar-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/libs/perfect-scrollbar/css/perfect-scrollbar.mine35d.css?ver=6.3.2'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='perfect-scrollbar-wpc-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/libs/perfect-scrollbar/css/custom-themee35d.css?ver=6.3.2'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='magnific-popup-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/libs/magnific-popup/magnific-popupe35d.css?ver=6.3.2'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='woosq-feather-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/libs/feather/feathere35d.css?ver=6.3.2' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='woosq-frontend-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/css/frontendb045.css?ver=3.4.8' type='text/css'
                        media='all' />
                    <link rel='preload' as='font' type='font/woff2' crossorigin='anonymous' id='tinvwl-webfont-font-css'
                        href='wp-content/plugins/ti-woocommerce-wishlist/assets/fonts/tinvwl-webfontffc1.woff2?ver=xu2uyi'
                        media='all' />
                    <link rel='stylesheet' id='tinvwl-webfont-css'
                        href='wp-content/plugins/ti-woocommerce-wishlist/assets/css/webfont.minae29.css?ver=2.7.4' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='tinvwl-css'
                        href='wp-content/plugins/ti-woocommerce-wishlist/assets/css/public.minae29.css?ver=2.7.4' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='elementor-icons-css'
                        href='wp-content/plugins/elementor/assets/lib/eicons/css/elementor-icons.min192d.css?ver=5.23.0' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='elementor-frontend-legacy-css'
                        href='wp-content/plugins/elementor/assets/css/frontend-legacy.min8420.css?ver=3.16.1' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='elementor-frontend-css'
                        href='wp-content/plugins/elementor/assets/css/frontend.min8420.css?ver=3.16.1' type='text/css' media='all' />
                    <link rel='stylesheet' id='swiper-css'
                        href='wp-content/plugins/elementor/assets/lib/swiper/css/swiper.min48f5.css?ver=5.3.6' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='elementor-post-7-css'
                        href='wp-content/uploads/elementor/css/post-7760a.css?ver=1692473830' type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-flaticons-css'
                        href='wp-content/plugins/agrul-core/assets/fonts/flaticon-sete35d.css?ver=6.3.2' type='text/css' media='all' />
                    <link rel='stylesheet' id='elementor-global-css'
                        href='wp-content/uploads/elementor/css/global760a.css?ver=1692473830' type='text/css' media='all' />
                    <link rel='stylesheet' id='elementor-post-9-css'
                        href='wp-content/uploads/elementor/css/post-9760a.css?ver=1692473830' type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-style-css' href='wp-content/themes/agrul/stylef269.css?ver=1.0.1' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='agrul-fonts-css'
                        href='https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&amp;family=Handlee&amp;display=swap'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='swiper-bundle-css'
                        href='wp-content/themes/agrul/assets/css/swiper-bundle.min5152.css?ver=1.0' type='text/css' media='all' />
                    <link rel='stylesheet' id='shop-css' href='wp-content/themes/agrul/assets/css/shop5152.css?ver=1.0' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='agrul-animate-css' href='wp-content/themes/agrul/assets/css/animate5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='bootstrap-css' href='wp-content/themes/agrul/assets/css/bootstrap.min5b31.css?ver=4.3.1'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='elegant-css' href='wp-content/themes/agrul/assets/css/elegant-icons5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='flaticon-css' href='wp-content/themes/agrul/assets/css/flaticon-set5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='fontawesome-css'
                        href='wp-content/themes/agrul/assets/css/font-awesome.minad76.css?ver=5.9.0' type='text/css' media='all' />
                    <link rel='stylesheet' id='themify-css' href='wp-content/themes/agrul/assets/css/themify-icons5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-validnavs-css' href='wp-content/themes/agrul/assets/css/validnavs5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-helper-css' href='wp-content/themes/agrul/assets/css/helper5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-main-style-css' href='wp-content/themes/agrul/assets/css/stylef269.css?ver=1.0.1'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-unittest-css' href='wp-content/themes/agrul/assets/css/unit-test5152.css'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-color-schemes-css'
                        href='wp-content/themes/agrul/assets/css/color.schemese35d.css?ver=6.3.2' type='text/css' media='all' />

                    <link rel='stylesheet' id='google-fonts-1-css'
                        href='https://fonts.googleapis.com/css?family=Roboto%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CRoboto+Slab%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&amp;display=auto&amp;ver=6.3.2'
                        type='text/css' media='all' />
                    <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />

                    <script type="text/template" id="tmpl-unavailable-variation-template">
                        <p>Sorry, this product is unavailable. Please choose a different combination.</p>
                    </script>
                    <script type='text/javascript' src='wp-includes/js/jquery/jquery.min3088.js?ver=3.7.0' id='jquery-core-js'></script>
                    <script type='text/javascript' src='wp-includes/js/jquery/jquery-migrate.min5589.js?ver=3.4.1'
                        id='jquery-migrate-js'></script>
                    <link rel="https://api.w.org/" href="wp-json/index.html" />
                    <link rel="alternate" type="application/json" href="wp-json/wp/v2/pages/9.json" />
                    <link rel="EditURI" type="application/rsd+xml" title="RSD" href="xmlrpc0db0.php?rsd" />
                    <meta name="generator" content="WordPress 6.3.2" />
                    <meta name="generator" content="WooCommerce 8.0.2" />
                    <link rel="canonical" href="index.html" />
                    <link rel='shortlink' href='index.html' />
                    <link rel="alternate" type="application/json+oembed"
                        href="wp-json/oembed/1.0/embed751c.json?url=https%3A%2F%2Fvalidthemes.net%2Fthemeforest%2Fwp%2Fagrul%2F" />
                    <link rel="alternate" type="text/xml+oembed"
                        href="wp-json/oembed/1.0/embeda759?url=https%3A%2F%2Fvalidthemes.net%2Fthemeforest%2Fwp%2Fagrul%2F&amp;format=xml" />
                    <meta name="generator" content="Redux 4.4.5" /> <noscript>

                    </noscript>
                    <meta name="generator"
                        content="Elementor 3.16.1; settings: css_print_method-external, google_font-enabled, font_display-auto" />
                    <link rel="icon" href="wp-content/uploads/2023/04/favicon.png" sizes="32x32" />
                    <link rel="icon" href="wp-content/uploads/2023/04/favicon.png" sizes="192x192" />
                    <link rel="apple-touch-icon" href="wp-content/uploads/2023/04/favicon.png" />
                    <meta name="msapplication-TileImage"
                        content="https://validthemes.net/themeforest/wp/agrul/wp-content/uploads/2023/04/favicon.png" />

                </head>


                {loading ?
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#4fa94d"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass='ball-triangle'
                        wrapperStyle=""
                        visible={loading}
                    />
                    :
                    <>
                        <TopHeader />
                        <div class="breadcrumb-area custom-breadcrumb shadow dark bg-cover text-center text-light" style={{ backgroundImage: 'url(' +bg+ ')' }}>
                            <Navbar />
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <h1 class="breadcumb-title">Blogs</h1>
                                        <nav class="woocommerce-breadcrumb">Home&nbsp;&#47;&nbsp;Blogs</nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="blog-area right-sidebar full-blog default-padding">
                            <div class="container">
                                <div class="blog-items">
                                    <div class="row">
                                        <div class="blog-content col-lg-8 col-md-12">
                                            <div class="blog-item-box">
                                                <div class="single-item">
                                                    <div class="col-lg-12">
                                                        <div
                                                            class="post-2081 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized tag-fresh tag-vegetables single-item">
                                                            <div class="item">
                                                                <div class="thumb"><a
                                                                    href='https://currentaffairs.adda247.com/government-launches-tradable-green-credit-program-to-promote-eco-friendly-actions/' target='_blank'><img
                                                                        width="1300" height="500"
                                                                        src={blog1}
                                                                        class="attachment-agrul_1300X500 size-agrul_1300X500 wp-post-image"
                                                                        alt="" decoding="async" fetchpriority="high" /></a></div>

                                                                <div class="info">
                                                                    {/* <div class="meta">
                                                            <ul>
                                                                <li><Link to='/blogs-details'><i
                                                                    class="fas fa-calendar-alt"></i><time
                                                                        datetime="2023-04-14T20:56:52+00:00">April 14,
                                                                        2023</time></Link></li>
                                                                <li><Link to='/blogs-details'><i
                                                                    class="fas fa-user-circle"></i><span>Kivisa</span></Link>
                                                                </li>
                                                                <li><Link
                                                                    to='/blogs-details'><i
                                                                        class="fal fa-eye"></i><span>344 Views</span></Link>
                                                                </li>
                                                            </ul>
                                                        </div> */}
                                                                    <div class="content">
                                                                        <h3><a
                                                                            href='https://currentaffairs.adda247.com/government-launches-tradable-green-credit-program-to-promote-eco-friendly-actions/' target='_blank'>Government Launches Tradable Green Credit Program to Promote Eco-Friendly Actions</a></h3>
                                                                        <p>The government has initiated a novel program that allows individuals and entities to earn and trade green credits, which are units of incentives for environmentally beneficial actions. This program aims to encourage positive environmental contributions through market-based incentives.</p>
                                                                        <a
                                                                            href='https://currentaffairs.adda247.com/government-launches-tradable-green-credit-program-to-promote-eco-friendly-actions/' target='_blank'
                                                                            class="btn btn-theme effect btn-md">Read
                                                                            More</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="single-item">
                                                    <div class="col-lg-12">
                                                        <div
                                                            class="post-2076 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized tag-fresh tag-vegetables single-item">
                                                            <div class="item">
                                                                <div class="thumb"><a
                                                                    href='https://www.livemint.com/news/india/lower-prices-trigger-fertilizer-subsidy-cut-11698256763475.html' target='_blank'><img
                                                                        width="1300" height="500"
                                                                        src={blog2}
                                                                        class="attachment-agrul_1300X500 size-agrul_1300X500 wp-post-image"
                                                                        alt="" decoding="async" /></a></div>
                                                                <div class="info">
                                                                    {/* <div class="meta">
                                                            <ul>
                                                                <li><Link to='/blogs-details'><i
                                                                    class="fas fa-calendar-alt"></i><time
                                                                        datetime="2023-04-14T20:54:01+00:00">April 14,
                                                                        2023</time></Link></li>
                                                                <li><Link to='/blogs-details'><i
                                                                    class="fas fa-user-circle"></i><span>Kivisa</span></Link>
                                                                </li>
                                                                <li><Link
                                                                    to='/blogs-details'><i
                                                                        class="fal fa-eye"></i><span>254 Views</span></Link>
                                                                </li>
                                                            </ul>
                                                        </div> */}
                                                                    <div class="content">
                                                                        <h3><a
                                                                            href='https://www.livemint.com/news/india/lower-prices-trigger-fertilizer-subsidy-cut-11698256763475.html' target='_blank'>Lower prices trigger fertilizer subsidy cut</a></h3>


                                                                        <p>The Union cabinet on Wednesday cleared a ₹22,303-crore subsidy for phosphatic and potassic fertilizers for the rabi season that runs till the end of March. While the amount is sharply lower than the previous year, the government assured that farmers will remain protected since international fertilizer prices have fallen, reducing the need for hefty support.</p>
                                                                        <a
                                                                            href='https://www.livemint.com/news/india/lower-prices-trigger-fertilizer-subsidy-cut-11698256763475.html' target='_blank'
                                                                            class="btn btn-theme effect btn-md">Read
                                                                            More</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="sidebar col-lg-4 col-md-12">
                                            <aside>
                                                <div id="agrul-recent-posts-2" class="sidebar-item widget recent-post">
                                                    <div class="title">
                                                        <h4>Recent Post</h4>
                                                    </div>
                                                    <ul>

                                                        <li>
                                                            <div class="">
                                                                <a
                                                                    href='https://www.forbesindia.com/article/innovation/celesta-capital-at-a-sweet-spot-in-indias-deep-tech-startup-landscape/89223/1' target='_blank'>
                                                                    <img src={blog3} alt="kivisa" />
                                                                </a>
                                                            </div>
                                                            <div class="">
                                                                <div class="meta-title">
                                                                    <span class="post-date">November 09, 2023</span>
                                                                </div>
                                                                <a
                                                                    href='https://www.forbesindia.com/article/innovation/celesta-capital-at-a-sweet-spot-in-indias-deep-tech-startup-landscape/89223/1' target='_blank'>Celesta Capital: At a sweet spot in India's deep tech startup landscape</a>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div class="">
                                                                <a href='https://theleaflet.in/cooperatives-nabard-and-the-hegemony-of-capital-part-1/' target='_blank'>
                                                                    <img src={blog4} alt="kivisa" />
                                                                </a>
                                                            </div>
                                                            <div class="">
                                                                <div class="meta-title">
                                                                    <span class="post-date">November 09, 2023</span>
                                                                </div>
                                                                <a href='https://theleaflet.in/cooperatives-nabard-and-the-hegemony-of-capital-part-1/' target='_blank'>
                                                                    Cooperatives, NABARD and the hegemony of capital— Part 1</a>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div class="">
                                                                <a href='https://economictimes.indiatimes.com/news/india/carbon-emission-reducing-practices-under-green-credit-programme-will-earn-carbon-credits-too-government/articleshow/104406473.cms' target='_blank'>
                                                                    <img src={blog5} alt="kivisa" />
                                                                </a>
                                                            </div>
                                                            <div class="">
                                                                <div class="meta-title">
                                                                    <span class="post-date">November 09, 2023</span>
                                                                </div>
                                                                <a href='https://economictimes.indiatimes.com/news/india/carbon-emission-reducing-practices-under-green-credit-programme-will-earn-carbon-credits-too-government/articleshow/104406473.cms' target='_blank'>Overcame
                                                                    Carbon emission reducing practices under Green Credit Programme will earn carbon credits too: Government</a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </aside>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer1 /></>}

                <link rel='stylesheet' id='elementor-post-1175-css'
                    href='wp-content/uploads/elementor/css/post-1175760a.css?ver=1692473830' type='text/css' media='all' />
                <link rel='stylesheet' id='elementor-post-317-css'
                    href='wp-content/uploads/elementor/css/post-317760a.css?ver=1692473830' type='text/css' media='all' />
                <link rel='stylesheet' id='e-animations-css'
                    href='wp-content/plugins/elementor/assets/lib/animations/animations.min8420.css?ver=3.16.1' type='text/css'
                    media='all' />

                <script type='text/javascript' src='wp-content/plugins/agrul-core/assets/js/agrul.ajax5152.js?ver=1.0'
                    id='agrul-ajax-js'></script>
                <script type='text/javascript' src='wp-content/plugins/contact-form-7/includes/swv/js/index41a3.js?ver=5.8'
                    id='swv-js'></script>

                <script type='text/javascript' src='wp-content/plugins/contact-form-7/includes/js/index41a3.js?ver=5.8'
                    id='contact-form-7-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/jquery-blockui/jquery.blockUI.min144d.js?ver=2.7.0-wc.8.0.2'
                    id='jquery-blockui-js'></script>

                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/frontend/add-to-cart.min76d9.js?ver=8.0.2'
                    id='wc-add-to-cart-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/js-cookie/js.cookie.min1ff0.js?ver=2.1.4-wc.8.0.2'
                    id='js-cookie-js'></script>

                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/frontend/woocommerce.min76d9.js?ver=8.0.2'
                    id='woocommerce-js'></script>
                <script type='text/javascript' src='wp-includes/js/underscore.mind584.js?ver=1.13.4' id='underscore-js'></script>

                <script type='text/javascript' src='wp-includes/js/wp-util.mine35d.js?ver=6.3.2' id='wp-util-js'></script>

                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/frontend/add-to-cart-variation.min76d9.js?ver=8.0.2'
                    id='wc-add-to-cart-variation-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/woo-smart-quick-view/assets/libs/slick/slick.minb045.js?ver=3.4.8'
                    id='slick-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/woo-smart-quick-view/assets/libs/perfect-scrollbar/js/perfect-scrollbar.jquery.minb045.js?ver=3.4.8'
                    id='perfect-scrollbar-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/woo-smart-quick-view/assets/libs/magnific-popup/jquery.magnific-popup.minb045.js?ver=3.4.8'
                    id='magnific-popup-js'></script>

                <script type='text/javascript' src='wp-content/plugins/woo-smart-quick-view/assets/js/frontendb045.js?ver=3.4.8'
                    id='woosq-frontend-js'></script>

                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/frontend/cart-fragments.min76d9.js?ver=8.0.2'
                    id='wc-cart-fragments-js'></script>

                <script type='text/javascript'
                    src='wp-content/plugins/ti-woocommerce-wishlist/assets/js/public.minae29.js?ver=2.7.4' id='tinvwl-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/bootstrap.min8a54.js?ver=1.0.0'
                    id='bootstrap-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/count-to8a54.js?ver=1.0.0'
                    id='count-to-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/swiper-bundle.min8a54.js?ver=1.0.0'
                    id='swiper-bundle-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/circle-progress8a54.js?ver=1.0.0'
                    id='circle-progress-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/jquery.nice-select.min8a54.js?ver=1.0.0'
                    id='nice-select-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/TweenMax.min8a54.js?ver=1.0.0'
                    id='TweenMax-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/jquery.scrolla.min8a54.js?ver=1.0.0'
                    id='scrolla-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/isotope.pkgd.min8a54.js?ver=1.0.0'
                    id='isotope-pkgd-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/jquery.appear8a54.js?ver=1.0.0'
                    id='appear-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/jquery.easing.min8a54.js?ver=1.0.0'
                    id='easing-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/modernizr8a54.js?ver=1.0.0'
                    id='modernizr-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/progress-bar.min8a54.js?ver=1.0.0'
                    id='progress-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/loopcounter8a54.js?ver=1.0.0'
                    id='loopcounter-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/validnavs8a54.js?ver=1.0.0'
                    id='agrul-validnavs-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/wow.min8a54.js?ver=1.0.0'
                    id='wow-js'></script>
                <script type='text/javascript' src='wp-includes/js/imagesloaded.mineda1.js?ver=4.1.4' id='imagesloaded-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/YTPlayer.min8a54.js?ver=1.0.0'
                    id='YTPlayer-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/mainf269.js?ver=1.0.1'
                    id='agrul-main-script-js'></script>
                <script type='text/javascript' src='wp-content/plugins/elementor/assets/js/webpack.runtime.min8420.js?ver=3.16.1'
                    id='elementor-webpack-runtime-js'></script>
                <script type='text/javascript' src='wp-content/plugins/elementor/assets/js/frontend-modules.min8420.js?ver=3.16.1'
                    id='elementor-frontend-modules-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/elementor/assets/lib/waypoints/waypoints.min05da.js?ver=4.0.2'
                    id='elementor-waypoints-js'></script>
                <script type='text/javascript' src='wp-includes/js/jquery/ui/core.min3f14.js?ver=1.13.2'
                    id='jquery-ui-core-js'></script>
                <script type='text/javascript' src='wp-content/plugins/elementor/assets/lib/swiper/swiper.min48f5.js?ver=5.3.6'
                    id='swiper-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/elementor/assets/lib/share-link/share-link.min8420.js?ver=3.16.1'
                    id='share-link-js'></script>
                <script type='text/javascript' src='wp-content/plugins/elementor/assets/lib/dialog/dialog.mind227.js?ver=4.9.0'
                    id='elementor-dialog-js'></script>

                <script type='text/javascript' src='wp-content/plugins/elementor/assets/js/frontend.min8420.js?ver=3.16.1'
                    id='elementor-frontend-js'></script>
                <script type='text/javascript' src='wp-content/plugins/elementor/assets/js/preloaded-modules.min8420.js?ver=3.16.1'
                    id='preloaded-modules-js'></script>
                <script type='text/javascript' src='wp-content/plugins/agrul-core/assets/js/agrul-core5152.js?ver=1.0'
                    id='agrul-core-js'></script>
                <script type='text/javascript' src='wp-content/plugins/agrul-core/assets/js/agrul-frontende35d.js?ver=6.3.2'
                    id='agrul-frontend-script-js'></script>
            </html>
        </>
    )
}
export default BlogsPage