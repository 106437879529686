import React, { Suspense } from 'react'
import img1 from '../assets/images/section5a.png'
import img2 from '../assets/images/section5b.png'
import img3 from '../assets/images/section5c.png'
import img4 from '../assets/images/farmer-phone.webp'
import wheat from '../assets/images/wheat-img.webp'
const FifthPanel = () => {
    return (
        <>
            <section className='mt-4 pt-4 mb-4 pb-5' style={{position: 'relative'}}>
                <div className='container'>
                    <div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className=''>
                                    
                                            <img loading='lazy' className='why-chose' src={img4} />
                                        
                                </div>
                            </div>
                            <div className='col-md-6 text-left mobile-margin-1'>
                                <img src={wheat} style={{position: 'absolute', right: '0', opacity: '0.4'}} />
                                <div className='text-left'>
                                    <div className='primary-color hover-effect' style={{ border: '1px solid #37A033', borderRadius: '25px', width: '200px', height: '40px', fontWeight: '400', fontSize: '23px', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "-9.604px 7.683px 0px 0px rgba(0, 0, 0, 0.06)" }}>Why Choose Us</div>
                                    <h3 className='primary-color mt-4 mb-0'> Kivisa stands at the forefront of agricultural innovation </h3>
                                    <p className='primary-color mt-3'> Kivisa believes in empowering farmers and stakeholders across the agricultural value chain by providing cutting-edge solutions that enhance efficiency, sustainability and productivity.</p>
                                    <a className='choose-us-div btn btn-theme animation' style={{ padding: '6px 15px', width: 'max-content', display: 'flex', alignItems: 'center' }}><img src={img1} style={{ height: '40px', marginRight: '20px' }} />Tailored for Farmers</a>
                                    <div className='choose-us-div btn btn-theme animation' style={{ padding: '6px 15px', width: 'max-content', display: 'flex', alignItems: 'center' }}><img src={img2} style={{ height: '40px', marginRight: '20px' }} />Sustainability at the Core</div>
                                    <div className='choose-us-div btn btn-theme animation' style={{ padding: '6px 15px', width: 'max-content', display: 'flex', alignItems: 'center' }}><img src={img3} style={{ height: '40px', marginRight: '20px' }} />Innovative Solutions</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
export default FifthPanel