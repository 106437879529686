import React from 'react'
import Navbar from './Navbar'
import TopHeader from './TopHeader'
import img1 from '../assets/images/about-us-inner.png'
import Footer1 from './Footer1'

const PrivacyPolicy = () => {
    return (
        <>
            <html lang="en-US">
                <meta http-equiv="content-type" content="text/html;charset=UTF-8" />
                <head>
                    <meta charset="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <title>Agrul &#8211; Organic Farm Agriculture Theme</title>
                    <meta name='robots' content='max-image-preview:large' />
                    <link rel='dns-prefetch' href='http://fonts.googleapis.com/' />
                    <link rel="alternate" type="application/rss+xml" title="Agrul &raquo; Feed" href="feed/index.html" />
                    <link rel="alternate" type="application/rss+xml" title="Agrul &raquo; Comments Feed"
                        href="comments/feed/index.html" />
                    <script type="text/javascript">

                    </script>

                    <link rel='stylesheet' id='wc-blocks-style-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/wc-blocks4031.css'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-active-filters-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/active-filters4031.css'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-add-to-cart-form-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/add-to-cart-form4031.css'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-packages-style-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/packages-style4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-all-products-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/all-products4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-all-reviews-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/all-reviews4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-attribute-filter-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/attribute-filter4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-breadcrumbs-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/breadcrumbs4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-catalog-sorting-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/catalog-sorting4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-customer-account-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/customer-account4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-featured-category-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/featured-category4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-featured-product-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/featured-product4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-mini-cart-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/mini-cart4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-price-filter-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/price-filter4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-add-to-cart-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-add-to-cart4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-button-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-button4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-categories-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-categories4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-image-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-image4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-image-gallery-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-image-gallery4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-query-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-query4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-results-count-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-results-count4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-reviews-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-reviews4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-sale-badge-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-sale-badge4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-search-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-search4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-sku-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-sku4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-stock-indicator-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-stock-indicator4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-summary-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-summary4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-title-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-title4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-rating-filter-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/rating-filter4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-reviews-by-category-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/reviews-by-category4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-reviews-by-product-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/reviews-by-product4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-product-details-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/product-details4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-single-product-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/single-product4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-stock-filter-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/stock-filter4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-cart-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/cart4031.css?ver=10.6.5' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-checkout-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/checkout4031.css?ver=10.6.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='wc-blocks-style-mini-cart-contents-css'
                        href='wp-content/plugins/woocommerce/packages/woocommerce-blocks/build/mini-cart-contents4031.css?ver=10.6.5'
                        type='text/css' media='all' />

                    <link rel='stylesheet' id='redux-extendify-styles-css'
                        href='wp-content/plugins/redux-framework/redux-core/assets/css/extendify-utilities4235.css?ver=4.4.5'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='contact-form-7-css'
                        href='wp-content/plugins/contact-form-7/includes/css/styles41a3.css?ver=5.8' type='text/css' media='all' />

                    <link rel='stylesheet' id='slick-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/libs/slick/slicke35d.css?ver=6.3.2' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='perfect-scrollbar-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/libs/perfect-scrollbar/css/perfect-scrollbar.mine35d.css?ver=6.3.2'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='perfect-scrollbar-wpc-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/libs/perfect-scrollbar/css/custom-themee35d.css?ver=6.3.2'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='magnific-popup-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/libs/magnific-popup/magnific-popupe35d.css?ver=6.3.2'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='woosq-feather-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/libs/feather/feathere35d.css?ver=6.3.2' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='woosq-frontend-css'
                        href='wp-content/plugins/woo-smart-quick-view/assets/css/frontendb045.css?ver=3.4.8' type='text/css'
                        media='all' />
                    <link rel='preload' as='font' type='font/woff2' crossorigin='anonymous' id='tinvwl-webfont-font-css'
                        href='wp-content/plugins/ti-woocommerce-wishlist/assets/fonts/tinvwl-webfontffc1.woff2?ver=xu2uyi'
                        media='all' />
                    <link rel='stylesheet' id='tinvwl-webfont-css'
                        href='wp-content/plugins/ti-woocommerce-wishlist/assets/css/webfont.minae29.css?ver=2.7.4' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='tinvwl-css'
                        href='wp-content/plugins/ti-woocommerce-wishlist/assets/css/public.minae29.css?ver=2.7.4' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='elementor-icons-css'
                        href='wp-content/plugins/elementor/assets/lib/eicons/css/elementor-icons.min192d.css?ver=5.23.0' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='elementor-frontend-legacy-css'
                        href='wp-content/plugins/elementor/assets/css/frontend-legacy.min8420.css?ver=3.16.1' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='elementor-frontend-css'
                        href='wp-content/plugins/elementor/assets/css/frontend.min8420.css?ver=3.16.1' type='text/css' media='all' />
                    <link rel='stylesheet' id='swiper-css'
                        href='wp-content/plugins/elementor/assets/lib/swiper/css/swiper.min48f5.css?ver=5.3.6' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='elementor-post-7-css'
                        href='wp-content/uploads/elementor/css/post-7760a.css?ver=1692473830' type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-flaticons-css'
                        href='wp-content/plugins/agrul-core/assets/fonts/flaticon-sete35d.css?ver=6.3.2' type='text/css' media='all' />
                    <link rel='stylesheet' id='elementor-global-css'
                        href='wp-content/uploads/elementor/css/global760a.css?ver=1692473830' type='text/css' media='all' />
                    <link rel='stylesheet' id='elementor-post-9-css'
                        href='wp-content/uploads/elementor/css/post-9760a.css?ver=1692473830' type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-style-css' href='wp-content/themes/agrul/stylef269.css?ver=1.0.1' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='agrul-fonts-css'
                        href='https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&amp;family=Handlee&amp;display=swap'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='swiper-bundle-css'
                        href='wp-content/themes/agrul/assets/css/swiper-bundle.min5152.css?ver=1.0' type='text/css' media='all' />
                    <link rel='stylesheet' id='shop-css' href='wp-content/themes/agrul/assets/css/shop5152.css?ver=1.0' type='text/css'
                        media='all' />
                    <link rel='stylesheet' id='agrul-animate-css' href='wp-content/themes/agrul/assets/css/animate5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='bootstrap-css' href='wp-content/themes/agrul/assets/css/bootstrap.min5b31.css?ver=4.3.1'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='elegant-css' href='wp-content/themes/agrul/assets/css/elegant-icons5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='flaticon-css' href='wp-content/themes/agrul/assets/css/flaticon-set5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='fontawesome-css'
                        href='wp-content/themes/agrul/assets/css/font-awesome.minad76.css?ver=5.9.0' type='text/css' media='all' />
                    <link rel='stylesheet' id='themify-css' href='wp-content/themes/agrul/assets/css/themify-icons5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-validnavs-css' href='wp-content/themes/agrul/assets/css/validnavs5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-helper-css' href='wp-content/themes/agrul/assets/css/helper5152.css?ver=1.0'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-main-style-css' href='wp-content/themes/agrul/assets/css/stylef269.css?ver=1.0.1'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-unittest-css' href='wp-content/themes/agrul/assets/css/unit-test5152.css'
                        type='text/css' media='all' />
                    <link rel='stylesheet' id='agrul-color-schemes-css'
                        href='wp-content/themes/agrul/assets/css/color.schemese35d.css?ver=6.3.2' type='text/css' media='all' />

                    <link rel='stylesheet' id='google-fonts-1-css'
                        href='https://fonts.googleapis.com/css?family=Roboto%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CRoboto+Slab%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&amp;display=auto&amp;ver=6.3.2'
                        type='text/css' media='all' />
                    <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />

                    <script type="text/template" id="tmpl-unavailable-variation-template">
                        <p>Sorry, this product is unavailable. Please choose a different combination.</p>
                    </script>
                    <script type='text/javascript' src='wp-includes/js/jquery/jquery.min3088.js?ver=3.7.0' id='jquery-core-js'></script>
                    <script type='text/javascript' src='wp-includes/js/jquery/jquery-migrate.min5589.js?ver=3.4.1'
                        id='jquery-migrate-js'></script>
                    <link rel="https://api.w.org/" href="wp-json/index.html" />
                    <link rel="alternate" type="application/json" href="wp-json/wp/v2/pages/9.json" />
                    <link rel="EditURI" type="application/rsd+xml" title="RSD" href="xmlrpc0db0.php?rsd" />
                    <meta name="generator" content="WordPress 6.3.2" />
                    <meta name="generator" content="WooCommerce 8.0.2" />
                    <link rel="canonical" href="index.html" />
                    <link rel='shortlink' href='index.html' />
                    <link rel="alternate" type="application/json+oembed"
                        href="wp-json/oembed/1.0/embed751c.json?url=https%3A%2F%2Fvalidthemes.net%2Fthemeforest%2Fwp%2Fagrul%2F" />
                    <link rel="alternate" type="text/xml+oembed"
                        href="wp-json/oembed/1.0/embeda759?url=https%3A%2F%2Fvalidthemes.net%2Fthemeforest%2Fwp%2Fagrul%2F&amp;format=xml" />
                    <meta name="generator" content="Redux 4.4.5" /> <noscript>

                    </noscript>
                    <meta name="generator"
                        content="Elementor 3.16.1; settings: css_print_method-external, google_font-enabled, font_display-auto" />
                    <link rel="icon" href="wp-content/uploads/2023/04/favicon.png" sizes="32x32" />
                    <link rel="icon" href="wp-content/uploads/2023/04/favicon.png" sizes="192x192" />
                    <link rel="apple-touch-icon" href="wp-content/uploads/2023/04/favicon.png" />
                    <meta name="msapplication-TileImage"
                        content="https://validthemes.net/themeforest/wp/agrul/wp-content/uploads/2023/04/favicon.png" />

                </head>
                <TopHeader />
                <div class="breadcrumb-area custom-breadcrumb shadow dark bg-cover text-center text-light" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                    <Navbar />
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <h1 class="breadcumb-title">Privacy Policy</h1>
                                <nav class="woocommerce-breadcrumb">Home&nbsp;&#47;&nbsp;Privacy Policy</nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <h2 className='secondary-color text-left mt-5 mb-5'>Privacy Policy</h2>
                    <h5 className='primary-color text-left'>Effective Date : 10-11-2023</h5>
                    <h5 className='primary-color text-left'>Your privacy is important to us, Protecting Your Privacy Is Our Top Priority.</h5>


                    <p className='text-left mt-5'>KIVISA Agro Private Limited is located at:</p>
                    <div className='text-left'>
                        <p className='primary-color'>KIVISA Agro Private Limited</p>
                        <p className='primary-color'>Unit No-1027, 10th Floor, Tower A, Spaze IT Park Sector-49, Gurgaon</p>
                        <p className='primary-color'>Haryana-122018, India</p>
                        <p className='primary-color'>0124 4987286</p>
                    </div>

                    <p className='text-left mt-5'>It is KIVISA Agro Private Limited's policy to respect your privacy regarding any information
                        we may collect while operating our website. This Privacy Policy applies to kivisa.in
                        (hereinafter, "us", "we", or "kivisa.in"). We respect your privacy and are committed to
                        protecting personally identifiable information you may provide us through the Website. We
                        have adopted this privacy policy ("Privacy Policy") to explain what information may be
                        collected on our Website, how we use this information, and under what circumstances we
                        may disclose the information to third parties. This Privacy Policy applies only to information
                        we collect through the Website and does not apply to our collection of information from other
                        sources.</p>

                    <p className='text-left'>This Privacy Policy, together with the Terms of service posted on our Website, set forth the
                        general rules and policies governing your use of our Website. Depending on your activities
                        when visiting our Website, you may be required to agree to additional terms of service.</p>

                    <ul class="check-solid-list mt-4">
                        <li>Website Visitors</li>
                        <li>Personally-Identifying Information</li>
                        <li>Security</li>
                        <li>Protection of Certain Personally-Identifying Information</li>
                        <li>Aggregated Statistics</li>
                        <li>Affiliate Disclosure</li>
                        <li>Cookies</li>
                        <li>E-commerce</li>
                        <li>Privacy Policy Changes</li>
                        <li>Contact Information & Credit</li>
                    </ul>
                    <div className='text-left mt-5'>
                        <h3 className='secondary-color'>1. Website Visitors</h3>
                        <p>Like most website operators, KIVISA Agro Private Limited collects non-personally-identifying
                            information of the sort that web browsers and servers typically make available, such as the
                            browser type, language preference, referring site, and the date and time of each visitor
                            request. KIVISA Agro Private Limited's purpose in collecting non-personally identifying
                            information is to better understand how KIVISA Agro Private Limited's visitors use its
                            website. From time to time, KIVISA Agro Private Limited may release
                            non-personally-identifying information in the aggregate, e.g., by publishing a report on trends
                            in the usage of its website.</p>

                        <p>KIVISA Agro Private Limited also collects potentially personally-identifying information like
                            Internet Protocol (IP) addresses for logged in users and for users leaving comments on
                            https://kivisa.in blog posts. KIVISA Agro Private Limited only discloses logged in user and
                            commenter IP addresses under the same circumstances that it uses and discloses
                            personally-identifying information as described below.
                        </p>
                    </div>
                    <div className='text-left'>
                        <h3 className='secondary-color'>2. Personally-Identifying Information</h3>
                        <p>Certain visitors to KIVISA Agro Private Limited's websites choose to interact with KIVISA
                            Agro Private Limited in ways that require KIVISA Agro Private Limited to gather
                            personally-identifying information. The amount and type of information that KIVISA Agro
                            Private Limited gathers depends on the nature of the interaction. For example, we ask
                            visitors who leave a comment at https://kivisa.in to provide a username and email address.
                        </p>
                    </div>
                    <div className='text-left'>
                        <h3 className='secondary-color'>3. Security</h3>
                        <p>The security of your Personal Information is important to us, but remember that no method of
                            transmission over the Internet, or method of electronic storage is 100% secure. While we
                            strive to use commercially acceptable means to protect your Personal Information, we
                            cannot guarantee its absolute security.

                        </p>
                    </div>

                    <div className='text-left'>
                        <h3 className='secondary-color'>4. Protection of Certain Personally-Identifying Information</h3>
                        <p>KIVISA Agro Private Limited discloses potentially personally-identifying and
                            personally-identifying information only to those of its employees, contractors and affiliated
                            organisations that (i) need to know that information in order to process it on KIVISA Agro
                            Private Limited's behalf or to provide services available at KIVISA Agro Private Limited's
                            website, and (ii) that have agreed not to disclose it to others. Some of those employees,
                            contractors and affiliated organisations may be located outside of your home country; by
                            using KIVISA Agro Private Limited's website, you consent to the transfer of such information
                            to them. KIVISA Agro Private Limited will not rent or sell potentially personally-identifying
                            and personally-identifying information to anyone. Other than to its employees, contractors
                            and affiliated organisations, as described above, KIVISA Agro Private Limited discloses
                            potentially personally-identifying and personally-identifying information only in response to a
                            subpoena, court order or other governmental request, or when KIVISA Agro Private Limited
                            believes in good faith that disclosure is reasonably necessary to protect the property or
                            rights of KIVISA Agro Private Limited, third parties or the public at large.
                        </p>
                        <p>If you are a registered user of https://kivisa.in and have supplied your email address, KIVISA
                            Agro Private Limited may occasionally send you an email to tell you about new features,
                            solicit your feedback, or just keep you up to date with what's going on with KIVISA Agro
                            Private Limited and our products. We primarily use our blog to communicate this type of
                            information, so we expect to keep this type of email to a minimum. If you send us a request
                            (for example via a support email or via one of our feedback mechanisms), we reserve the
                            right to publish it in order to help us clarify or respond to your request or to help us support
                            other users. KIVISA Agro Private Limited takes all measures reasonably necessary to
                            protect against the unauthorised access, use, alteration or destruction of potentially
                            personally-identifying and personally-identifying information.
                        </p>
                    </div>

                    <div className='text-left'>
                        <h3 className='secondary-color'>5. Aggregated Statistics</h3>
                        <p>KIVISA Agro Private Limited may collect statistics about the behaviour of visitors to its
                            website. KIVISA Agro Private Limited may display this information publicly or provide it to
                            others. However, KIVISA Agro Private Limited does not disclose your personally-identifying
                            information.
                        </p>
                    </div>
                    <div className='text-left'>
                        <h3 className='secondary-color'>6. Affiliate Disclosure</h3>
                        <p>This site uses affiliate links and does earn a commission from certain links. This does not
                            affect your purchases or the price you may pay.
                        </p>
                    </div>

                    <div className='text-left'>
                        <h3 className='secondary-color'>7. Cookies</h3>
                        <p>To enrich and perfect your online experience, KIVISA Agro Private Limited uses "Cookies",
                            similar technologies and services provided by others to display personalised content,
                            appropriate advertising and store your preferences on your computer.
                        </p>

                        <p>A cookie is a string of information that a website stores on a visitor's computer, and that the
                            visitor's browser provides to the website each time the visitor returns. KIVISA Agro Private
                            Limited uses cookies to help KIVISA Agro Private Limited identify and track visitors, their
                            usage of https://kivisa.in, and their website access preferences. KIVISA Agro Private Limited
                            visitors who do not wish to have cookies placed on their computers should set their browsers
                            to refuse cookies before using KIVISA Agro Private Limited's websites, with the drawback
                            that certain features of KIVISA Agro Private Limited's websites may not function properly
                            without the aid of cookies.
                        </p>
                        <p>By continuing to navigate our website without changing your cookie settings, you hereby
                            acknowledge and agree to KIVISA Agro Private Limited's use of cookies.
                        </p>
                    </div>
                    <div className='text-left'>
                        <h3 className='secondary-color'>8. E-commerce</h3>
                        <p>Those who engage in transactions with KIVISA Agro Private Limited – by purchasing KIVISA
                            Agro Private Limited's services or products, are asked to provide additional information,
                            including as necessary the personal and financial information required to process those
                            transactions. In each case, KIVISA Agro Private Limited collects such information only
                            insofar as is necessary or appropriate to fulfil the purpose of the visitor's interaction with
                            KIVISA Agro Private Limited. KIVISA Agro Private Limited does not disclose
                            personally-identifying information other than as described below. And visitors can always
                            refuse to supply personally-identifying information, with the caveat that it may prevent them
                            from engaging in certain website-related activities.
                        </p>
                    </div>

                    <div className='text-left'>
                        <h3 className='secondary-color'>9.  Privacy Policy Changes</h3>
                        <p>Although most changes are likely to be minor, KIVISA Agro Private Limited may change its
                            Privacy Policy from time to time, and at KIVISA Agro Private Limited's sole discretion.
                            KIVISA Agro Private Limited encourages visitors to frequently check this page for any
                            changes to its Privacy Policy. Your continued use of this site after any change in this Privacy
                            Policy will constitute your acceptance of such change
                        </p>
                    </div>
                    <div className='text-left mb-5'>
                        <h3 className='secondary-color'>10. Contact Information</h3>
                        <p>If you have any questions or concerns regarding our Privacy Policy of our Platform,
                            please contact us at <a href='mailto:contact@kivisa.in'>contact@kivisa.in</a> We will address your inquiries to the best of
                            our ability.
                        </p>
                    </div>
                </div>
                <Footer1 />
                <link rel='stylesheet' id='elementor-post-1175-css'
                    href='wp-content/uploads/elementor/css/post-1175760a.css?ver=1692473830' type='text/css' media='all' />
                <link rel='stylesheet' id='elementor-post-317-css'
                    href='wp-content/uploads/elementor/css/post-317760a.css?ver=1692473830' type='text/css' media='all' />
                <link rel='stylesheet' id='e-animations-css'
                    href='wp-content/plugins/elementor/assets/lib/animations/animations.min8420.css?ver=3.16.1' type='text/css'
                    media='all' />

                <script type='text/javascript' src='wp-content/plugins/agrul-core/assets/js/agrul.ajax5152.js?ver=1.0'
                    id='agrul-ajax-js'></script>
                <script type='text/javascript' src='wp-content/plugins/contact-form-7/includes/swv/js/index41a3.js?ver=5.8'
                    id='swv-js'></script>

                <script type='text/javascript' src='wp-content/plugins/contact-form-7/includes/js/index41a3.js?ver=5.8'
                    id='contact-form-7-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/jquery-blockui/jquery.blockUI.min144d.js?ver=2.7.0-wc.8.0.2'
                    id='jquery-blockui-js'></script>

                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/frontend/add-to-cart.min76d9.js?ver=8.0.2'
                    id='wc-add-to-cart-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/js-cookie/js.cookie.min1ff0.js?ver=2.1.4-wc.8.0.2'
                    id='js-cookie-js'></script>

                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/frontend/woocommerce.min76d9.js?ver=8.0.2'
                    id='woocommerce-js'></script>
                <script type='text/javascript' src='wp-includes/js/underscore.mind584.js?ver=1.13.4' id='underscore-js'></script>

                <script type='text/javascript' src='wp-includes/js/wp-util.mine35d.js?ver=6.3.2' id='wp-util-js'></script>

                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/frontend/add-to-cart-variation.min76d9.js?ver=8.0.2'
                    id='wc-add-to-cart-variation-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/woo-smart-quick-view/assets/libs/slick/slick.minb045.js?ver=3.4.8'
                    id='slick-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/woo-smart-quick-view/assets/libs/perfect-scrollbar/js/perfect-scrollbar.jquery.minb045.js?ver=3.4.8'
                    id='perfect-scrollbar-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/woo-smart-quick-view/assets/libs/magnific-popup/jquery.magnific-popup.minb045.js?ver=3.4.8'
                    id='magnific-popup-js'></script>

                <script type='text/javascript' src='wp-content/plugins/woo-smart-quick-view/assets/js/frontendb045.js?ver=3.4.8'
                    id='woosq-frontend-js'></script>

                <script type='text/javascript'
                    src='wp-content/plugins/woocommerce/assets/js/frontend/cart-fragments.min76d9.js?ver=8.0.2'
                    id='wc-cart-fragments-js'></script>

                <script type='text/javascript'
                    src='wp-content/plugins/ti-woocommerce-wishlist/assets/js/public.minae29.js?ver=2.7.4' id='tinvwl-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/bootstrap.min8a54.js?ver=1.0.0'
                    id='bootstrap-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/count-to8a54.js?ver=1.0.0'
                    id='count-to-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/swiper-bundle.min8a54.js?ver=1.0.0'
                    id='swiper-bundle-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/circle-progress8a54.js?ver=1.0.0'
                    id='circle-progress-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/jquery.nice-select.min8a54.js?ver=1.0.0'
                    id='nice-select-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/TweenMax.min8a54.js?ver=1.0.0'
                    id='TweenMax-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/jquery.scrolla.min8a54.js?ver=1.0.0'
                    id='scrolla-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/isotope.pkgd.min8a54.js?ver=1.0.0'
                    id='isotope-pkgd-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/jquery.appear8a54.js?ver=1.0.0'
                    id='appear-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/jquery.easing.min8a54.js?ver=1.0.0'
                    id='easing-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/modernizr8a54.js?ver=1.0.0'
                    id='modernizr-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/progress-bar.min8a54.js?ver=1.0.0'
                    id='progress-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/loopcounter8a54.js?ver=1.0.0'
                    id='loopcounter-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/validnavs8a54.js?ver=1.0.0'
                    id='agrul-validnavs-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/wow.min8a54.js?ver=1.0.0'
                    id='wow-js'></script>
                <script type='text/javascript' src='wp-includes/js/imagesloaded.mineda1.js?ver=4.1.4' id='imagesloaded-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/YTPlayer.min8a54.js?ver=1.0.0'
                    id='YTPlayer-js'></script>
                <script type='text/javascript' src='wp-content/themes/agrul/assets/js/mainf269.js?ver=1.0.1'
                    id='agrul-main-script-js'></script>
                <script type='text/javascript' src='wp-content/plugins/elementor/assets/js/webpack.runtime.min8420.js?ver=3.16.1'
                    id='elementor-webpack-runtime-js'></script>
                <script type='text/javascript' src='wp-content/plugins/elementor/assets/js/frontend-modules.min8420.js?ver=3.16.1'
                    id='elementor-frontend-modules-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/elementor/assets/lib/waypoints/waypoints.min05da.js?ver=4.0.2'
                    id='elementor-waypoints-js'></script>
                <script type='text/javascript' src='wp-includes/js/jquery/ui/core.min3f14.js?ver=1.13.2'
                    id='jquery-ui-core-js'></script>
                <script type='text/javascript' src='wp-content/plugins/elementor/assets/lib/swiper/swiper.min48f5.js?ver=5.3.6'
                    id='swiper-js'></script>
                <script type='text/javascript'
                    src='wp-content/plugins/elementor/assets/lib/share-link/share-link.min8420.js?ver=3.16.1'
                    id='share-link-js'></script>
                <script type='text/javascript' src='wp-content/plugins/elementor/assets/lib/dialog/dialog.mind227.js?ver=4.9.0'
                    id='elementor-dialog-js'></script>

                <script type='text/javascript' src='wp-content/plugins/elementor/assets/js/frontend.min8420.js?ver=3.16.1'
                    id='elementor-frontend-js'></script>
                <script type='text/javascript' src='wp-content/plugins/elementor/assets/js/preloaded-modules.min8420.js?ver=3.16.1'
                    id='preloaded-modules-js'></script>
                <script type='text/javascript' src='wp-content/plugins/agrul-core/assets/js/agrul-core5152.js?ver=1.0'
                    id='agrul-core-js'></script>
                <script type='text/javascript' src='wp-content/plugins/agrul-core/assets/js/agrul-frontende35d.js?ver=6.3.2'
                    id='agrul-frontend-script-js'></script>
            </html>
        </>
    )
}
export default PrivacyPolicy