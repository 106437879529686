import React from 'react'
import blog1 from '../assets/images/blogs-1.webp'
import blog2 from '../assets/images/blogs-2.webp'
import blog3 from '../assets/images/blogs-3.webp'
import blog4 from '../assets/images/new-blog.webp'
import img3 from '../assets/images/team3.png'
import blog5 from '../assets/images/blogs-5.webp'
import leave from '../assets/images/leaves2.webp'

import Footer1 from './Footer1'

const Blogs = () => {
    return (
        <>
        
                <section className='mt-4 pt-4 mb-4 pb-5' style={{position: 'relative'}}>
                    <img src={leave} style={{position: 'absolute', left: '0'}} />
                    <div className='container'>
                        <div>
                            <div className='text-center'>
                                <div className='primary-color hover-effect' style={{ textAlign: 'center', border: '1px solid #81C38A', borderRadius: '25px', width: '200px', height: '40px', color: '#1C461A', fontWeight: '600', fontSize: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto', backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.06) -9.604px 7.683px 0px 0px' }}>Our Blog</div>
                                <h3 className='mt-4' style={{ color: '#81C38A' }}>Blog & Articles</h3>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-4 mobile-margin-2'>
                                    <a href='https://currentaffairs.adda247.com/government-launches-tradable-green-credit-program-to-promote-eco-friendly-actions/' target='_blank'>
                                        <div className='download-button'>
                                            <div className='text-left position-relative'>
                                                <img className='img-size-mobile' src={blog4} style={{ width: '100%', height: '449px', borderRadius: '10px', opacity: '0.9' }} />
                                                <div className='position-absolute change-position-diff' style={{ top: '75%', left: '20px', padding: '14px', borderRadius: '5px' }}>
                                                    <p className='mb-0' style={{ fontWeight: '700', fontSize: '14px', color: 'white', fontSize: '14px' }}>Government Launches Tradable Green Credit Program to Promote Eco-Friendly Actions</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-8'>
                                    <div className='row mobile-margin-2'>
                                        <div className='col-md-6 mobile-margin-2'>
                                            <a href='https://www.livemint.com/news/india/lower-prices-trigger-fertilizer-subsidy-cut-11698256763475.html' target='_blank'>
                                                <div className='download-button'>
                                                    <div className='text-left position-relative'>
                                                        <img src={blog1} style={{ width: '100%', height: '100%', borderRadius: '10px', opacity: '0.9' }} />
                                                        <div className='position-absolute change-position-fixed' style={{ top: '60%', left: '20px', padding: '14px', borderRadius: '5px' }}>
                                                            <p className='mb-0' style={{ fontWeight: '700', fontSize: '14px', color: 'white', fontSize: '14px' }}>The subsidy will be provided to the fertilizer companies as per approved and notified rates. (HT)</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='col-md-6 mobile-margin-2'>
                                            <a href='https://www.forbesindia.com/article/innovation/celesta-capital-at-a-sweet-spot-in-indias-deep-tech-startup-landscape/89223/1' target='_blank'>
                                                <div className='download-button'>
                                                    <div className='text-left position-relative'>
                                                        <img src={blog5} style={{ width: '100%', height: '100%', borderRadius: '10px', opacity: '0.9' }} />
                                                        <div className='position-absolute change-position-fixed' style={{ top: '60%', left: '20px', padding: '14px', borderRadius: '5px' }}>
                                                            <p className='mb-0' style={{ fontWeight: '700', fontSize: '14px', color: 'white', fontSize: '14px' }}>Celesta Capital: At a sweet spot in India's deep tech startup landscape</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-md-6 mobile-margin-2'>
                                            <a href='https://theleaflet.in/cooperatives-nabard-and-the-hegemony-of-capital-part-1/' target='_blank'>
                                                <div className='download-button'>
                                                    <div className='text-left position-relative'>
                                                        <img src={blog2} style={{ width: '100%', height: '100%', borderRadius: '10px', opacity: '0.9' }} />
                                                        <div className='position-absolute change-position-fixed' style={{ top: '60%', left: '20px', padding: '14px', borderRadius: '5px' }}>
                                                            <p className='mb-0' style={{ fontWeight: '700', fontSize: '14px', color: 'white', fontSize: '14px' }}>Cooperatives, NABARD and the hegemony of capital— Part 1</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='col-md-6 mobile-margin-2'>
                                            <a href='https://economictimes.indiatimes.com/news/india/carbon-emission-reducing-practices-under-green-credit-programme-will-earn-carbon-credits-too-government/articleshow/104406473.cms' target='_blank'>
                                                <div className='download-button'>
                                                    <div className='text-left position-relative'>
                                                        <img src={blog3} style={{ width: '100%', height: '100%', borderRadius: '10px', opacity: '0.9' }} />
                                                        <div className='position-absolute change-position-fixed' style={{ top: '60%', left: '20px', padding: '14px', borderRadius: '5px' }}>
                                                            <p className='mb-0' style={{ fontWeight: '700', fontSize: '14px', color: 'white', fontSize: '14px' }}>Carbon emission reducing practices under Green Credit Programme will earn carbon credits too: Government</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer1 />
        </>
    )
}
export default Blogs