import React from 'react'
import Navbar from './Navbar'
import TopHeader from './TopHeader'
import img1 from '../assets/images/about-us-inner.png'

const TermsConditions = () => {
    return (
        <>
            <div className='container'>
                <h2 className='secondary-color text-left mt-5 mb-5'>Terms & Conditions</h2>
                <h5 className='primary-color text-left'>Effective Date : 10-11-2023</h5>
                <h5 className='primary-color text-left'>Welcome to KIVISA Agro Private Limited</h5>


                <p className='text-left mt-5'>These terms of service outline the rules and regulations for the use of KIVISA Agro Private Limited's Website.</p>
                <p className='text-left mt-3'>KIVISA Agro Private Limited is located at:</p>
                <div className='text-left'>
                    <p className='primary-color'>Unit No-1027, 10th Floor, Tower A, Spaze IT Park Sector-49, Gurgaon</p>
                    <p className='primary-color'>Haryana-122018, India</p>
                </div>

                <p className='text-left mt-5'>By accessing this website we assume you accept these terms of service in full. Do
                    not continue to use KIVISA Agro Private Limited's website if you do not accept all of
                    the terms of service stated on this page.
                </p>

                <p className='text-left'>The following terminology applies to these Terms of Service, Privacy Statement and
                    Disclaimer Notice and any or all Agreements: "Client", "You" and "Your" refers to you,
                    the person accessing this website and accepting the Company's terms of service.
                    "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party",
                    "Parties", or "Us", refers to both the Client and ourselves, or either the Client or
                    ourselves. All terms refer to the offer, acceptance and consideration of payment
                    necessary to undertake the process of our assistance to the Client in the most
                    appropriate manner, whether by formal meetings of a fixed duration, or any other
                    means, for the express purpose of meeting the Client's needs in respect of provision
                    of the Company's stated services/products, in accordance with and subject to,
                    prevailing law of India. Any use of the above terminology or other words in the
                    singular, plural, capitalisation and/or he/she or they, are taken as interchangeable
                    and therefore as referring to the same.</p>

                {/* <ul class="check-solid-list mt-4">
                    <li>Website Visitors</li>
                    <li>Personally-Identifying Information</li>
                    <li>Security</li>
                    <li>Protection of Certain Personally-Identifying Information</li>
                    <li>Aggregated Statistics</li>
                    <li>Affiliate Disclosure</li>
                    <li>Cookies</li>
                    <li>E-commerce</li>
                    <li>Privacy Policy Changes</li>
                    <li>Contact Information & Credit</li>
                </ul> */}
                <div className='text-left mt-5'>
                    <h3 className='secondary-color'>1. Cookies</h3>
                    <p>We employ the use of cookies. By using KIVISA Agro Private Limited's website you
                        consent to the use of cookies in accordance with KIVISA Agro Private Limited's
                        privacy policy.</p>

                    <p>Most of the modern day interactive websites use cookies to enable us to retrieve
                        user details for each visit. Cookies are used in some areas of our site to enable the
                        functionality of this area and ease of use for those people visiting. Some of our
                        affiliate / advertising partners may also use cookies.
                    </p>
                </div>
                <div className='text-left'>
                    <h3 className='secondary-color'>2. License</h3>
                    <p>Unless otherwise stated, KIVISA Agro Private Limited and/or its licensors own the
                        intellectual property rights for all material on KIVISA Agro Private Limited including
                        trademarks, copyrights, and proprietary information, associated with our Platform
                        and its content are owned by us or our licensors. These rights are protected by
                        applicable intellectual property laws.
                    </p>
                    <p>Limited License: We grant you a limited, non-exclusive, non-transferable, and
                        revocable license to access and use our Platform for its intended purposes. This
                        license does not grant you any rights to use our intellectual property except as
                        expressly permitted by these Terms.
                    </p>
                    <p>You must not:</p>
                    <ul class="check-solid-list mt-2 mb-4">
                        <li>Republish material from kivisa.in</li>
                        <li>Sell, rent or sub-license material from kivisa.in</li>
                        <li>Reproduce, duplicate or copy material from kivisa.in</li>
                    </ul>
                    <p>Redistribute content from KIVISA Agro Private Limited (unless content is specifically
                        made for redistribution).</p>
                </div>
                <div className='text-left'>
                    <h3 className='secondary-color'>3. User Comments</h3>
                    <p>This Agreement shall begin on the date 10/11/2023 hereof.
                        Certain parts of this website offer the opportunity for users to post and
                        exchange opinions, information, material and data in areas of the website.
                        KIVISA Agro Private Limited does not screen, edit, publish or review
                        Comments prior to their appearance on the website and Comments do not
                        reflect the views or opinions of KIVISA Agro Private Limited, its agents or
                        affiliates. Comments reflect the view and opinion of the person who posts
                        such a view or opinion. To the extent permitted by applicable laws, KIVISA
                        Agro Private Limited shall not be responsible or liable for the Comments or for
                        any loss cost, liability, damages or expenses caused and or suffered as a
                        result of any use of and/or posting of and/or appearance of the Comments on
                        this website.
                    </p>
                    <p>KIVISA Agro Private Limited reserves the right to monitor all Comments and to
                        remove any Comments which it considers in its absolute discretion to be
                        inappropriate, offensive or otherwise in breach of these Terms of Service.
                        You warrant and represent that:
                    </p>
                    <ul class="check-solid-list mt-2 mb-4">
                        <li>You are entitled to post the Comments on our website and have all
                            necessary licenses and consents to do so;</li>
                        <li>The Comments do not infringe any intellectual property right, including
                            without limitation copyright, patent or trademark, or other proprietary
                            right of any third party;
                        </li>
                        <li>The Comments do not contain any defamatory, libelous, offensive,
                            indecent or otherwise unlawful material or material which is an
                            invasion of privacy</li>
                        <li>The Comments will not be used to solicit or promote business or
                            custom or present commercial activities or unlawful activity.
                        </li>
                    </ul>
                    <p>You hereby grant to KIVISA Agro Private Limited a non-exclusive royalty-free
                        license to use, reproduce, edit and authorise others to use, reproduce and edit
                        any of your Comments in any and all forms, formats or media.
                    </p>
                </div>

                <div className='text-left'>
                    <h3 className='secondary-color'>4. Hyperlinking to our Content</h3>
                    <p>The following organisations may link to our Web site without prior written
                        approval:
                    </p>
                    <ul class="check-solid-list mt-2 mb-4">
                        <li>Government agencies;</li>
                        <li>Search engines;
                        </li>
                        <li>News organisations;</li>
                        <li>Online directory distributors when they list us in the directory may link
                            to our Web site in the same manner as they hyperlink to the Web sites
                            of other listed businesses; and
                        </li>
                        <li>Systemwide Accredited Businesses except soliciting non-profit
                            organisations, charity shopping malls, and charity fundraising groups
                            which may not hyperlink to our Website.</li>
                    </ul>
                    <p>These organisations may link to our home page, to publications or to other
                        Web site information so long as the link: (a) is not in any way misleading; (b)
                        does not falsely imply sponsorship, endorsement or approval of the linking
                        party and its products or services; and (c) fits within the context of the linking
                        party's site.
                    </p>
                    <p>These organisations may link to our home page, to publications or to other
                        Web site information so long as the link:
                    </p>
                    <ul class="check-solid-list mt-2 mb-4">
                        <li>is not in any way misleading;</li>
                        <li>does not falsely imply sponsorship, endorsement or approval of the linking
                            party and its products or services; and
                        </li>
                        <li>fits within the context of the linking
                            party's site.</li>
                    </ul>
                    <p>We may consider and approve in our sole discretion other link requests from
                        the following types of organisations:
                    </p>
                    <ul class="check-solid-list mt-2 mb-4">
                        <li>commonly-known consumer and/or business information sources
                            such as Chambers of Commerce, American Automobile Association,
                            AARP and Consumers Union;
                        </li>
                        <li>dot.com community sites;
                        </li>
                        <li>associations or other groups representing charities, including charity
                            giving sites,
                        </li>
                        <li>online directory distributors;</li>
                        <li>internet portals;
                        </li>
                        <li>accounting, law and consulting firms whose primary clients are
                            businesses; and</li>
                        <li>educational institutions and trade associations</li>
                    </ul>

                    <p>We will approve link requests from these organisations if we determine that:
                    </p>
                    <ul class="check-solid-list mt-2 mb-4">
                        <li>the
                            link would not reflect unfavourably on us or our accredited businesses (for example,
                            trade associations or other organisations representing inherently suspect types of
                            business, such as work-at-home opportunities, shall not be allowed to link);
                        </li>
                        <li>the
                            organisation does not have an unsatisfactory record with us;
                        </li>
                        <li>the benefit to us
                            from the visibility associated with the hyperlink outweighs the absence of KIVISA
                            Agro Private Limited; and
                        </li>
                        <li>where the link is in the context of general resource
                            information or is otherwise consistent with editorial content in a newsletter or similar
                            product furthering the mission of the organisation.</li>
                    </ul>


                    <p>These organisations may link to our home page, to publications or to other Web site
                        information so long as the link
                    </p>
                    <ul class="check-solid-list mt-2 mb-4">
                        <li>is not in any way misleading;
                        </li>
                        <li>does not falsely
                            imply sponsorship, endorsement or approval of the linking party and it products or
                            services; and
                        </li>
                        <li> fits within the context of the linking party's site.
                        </li>
                    </ul>
                    <p>If you are among the organisations listed in paragraph 2 above and are interested in
                        linking to our website, you must notify us by sending an email to contact@kivisa.in .
                        Please include your name, your organisation name, contact information (such as a
                        phone number and/or e-mail address) as well as the URL of your site, a list of any
                        URLs from which you intend to link to our Web site, and a list of the URL(s) on our
                        site to which you would like to link. Allow 2-3 weeks for a response.</p>

                    <p>Approved organisations may hyperlink to our Web site as follows:
                    </p>
                    <ul class="check-solid-list mt-2 mb-4">
                        <li>By use of our corporate name; or
                        </li>
                        <li>By use of the uniform resource locator (Web address) being linked to; or
                        </li>
                        <li>By use of any other description of our Web site or material being linked to that
                            makes sense within the context and format of content on the linking party's
                            site.
                        </li>
                    </ul>
                    <p>No use of KIVISA Agro Private Limited's logo or other artwork will be allowed for
                        linking absent a trademark license agreement.</p>
                </div>

                <div className='text-left'>
                    <h3 className='secondary-color'>5. Iframes</h3>
                    <p>Without prior approval and express written permission, you may not create frames
                        around our Web pages or use other techniques that alter in any way the visual
                        presentation or appearance of our Web site.
                    </p>
                </div>
                <div className='text-left'>
                    <h3 className='secondary-color'>7. Content Liability</h3>
                    <p>We shall have no responsibility or liability for any content appearing on your Web
                        site. You agree to indemnify and defend us against all claims arising out of or based
                        upon your Website. No link(s) may appear on any page on your Web site or within
                        any context containing content or materials that may be interpreted as libelous,
                        obscene or criminal, or which infringes, otherwise violates, or advocates the
                        infringement or other violation of, any third party rights.
                    </p>
                </div>

                <div className='text-left'>
                    <h3 className='secondary-color'>8. Reservation of Rights</h3>
                    <p>We reserve the right at any time and in its sole discretion to request that you remove
                        all links or any particular link to our Web site. You agree to immediately remove all
                        links to our Web site upon such request. We also reserve the right to amend these
                        terms of service and its linking policy at any time. By continuing to link to our Web
                        site, you agree to be bound to and abide by these linking terms of service.
                    </p>
                </div>
                <div className='text-left'>
                    <h3 className='secondary-color'>9. Removal of links from our website</h3>
                    <p>If you find any link on our Web site or any linked web site objectionable for any
                        reason, you may contact us about this. We will consider requests to remove links but
                        will have no obligation to do so or to respond directly to you.
                    </p>
                    <p>Whilst we endeavour to ensure that the information on this website is correct, we do
                        not warrant its completeness or accuracy; nor do we commit to ensuring that the
                        website remains available or that the material on the website is kept up to date.
                    </p>
                </div>

                <div className='text-left'>
                    <h3 className='secondary-color'>10.  Disclaimer</h3>
                    <p>To the maximum extent permitted by applicable law, we exclude all representations,
                        warranties and conditions relating to our website and the use of this website
                        (including, without limitation, any warranties implied by law in respect of satisfactory
                        quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in
                        this disclaimer will:
                    </p>
                    <ul class="check-solid-list mt-2 mb-4">
                        <li>limit or exclude our or your liability for death or personal injury resulting from
                            negligence;
                        </li>
                        <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;
                        </li>
                        <li>limit any of our or your liabilities in any way that is not permitted under
                            applicable law; or
                        </li>
                        <li>exclude any of our or your liabilities that may not be excluded under
                            applicable law.
                        </li>
                    </ul>
                    <p>The limitations and exclusions of liability set out in this Section and elsewhere in this
                        disclaimer
                    </p>
                    <ul class="check-solid-list mt-2 mb-4">
                        <li>are subject to the preceding paragraph; and
                        </li>
                        <li>govern all liabilities
                            arising under the disclaimer or in relation to the subject matter of this disclaimer,
                            including liabilities arising in contract, in tort (including negligence) and for breach of
                            statutory duty.
                        </li>
                    </ul>
                    <p>To the extent that the website and the information and services on the website are
                        provided free of charge, we will not be liable for any loss or damage of any nature.
                    </p>
                    <p>Product Listings and Transactions:</p>
                    <ul class="check-solid-list mt-2 mb-4">
                        <li><strong>Product Listings:</strong> We strive to provide accurate and up-to-date product
                            information on our Platform. However, we do not guarantee the accuracy,
                            completeness, or reliability of product listings. You are responsible for
                            verifying the accuracy and suitability of any product before making a
                            purchase.
                        </li>
                        <li><strong>Transactions:</strong> Your interactions and transactions with other users or
                            third-party sellers on our Platform are solely between you and the
                            respective party. We are not a party to those transactions and do not
                            guarantee the quality, safety, or legality of the products or services offered
                            by others
                        </li>
                    </ul>
                </div>
                <div className='text-left mb-5'>
                    <h3 className='secondary-color'>11. Lender Onboarding Terms and Conditions</h3>
                    <h4 className='primary-color'>Effective Date: 10/10/2023</h4>
                    <h5>1. Acceptance of Terms
                    </h5>
                    <p>By using this website and its lending services, you agree to be bound by these Lender
                        Onboarding Terms and Conditions ("Terms"). If you do not agree to these Terms, you
                        may not use the lending services provided by this website.
                    </p>
                    <h5>2. Eligibility
                    </h5>
                    <p>You must meet the following eligibility criteria to become a lender on this platform:
                    </p><ul class="check-solid-list mt-2 mb-4">
                        <li>You must be of legal age in your jurisdiction.
                        </li>
                        <li> You must have a valid bank account.
                        </li>
                        <li>You must comply with all applicable laws and regulations in your jurisdiction.
                        </li>
                    </ul>

                    <h5>3. Registration and Know Your Customer (KYC)
                    </h5>
                    <ul class="check-solid-list mt-2 mb-4">
                        <li>To become a lender on this platform, you must complete the registration process,
                            which includes providing accurate and up-to-date information.
                        </li>
                        <li> You agree to undergo the KYC verification process, and you consent to the
                            collection and processing of your personal and financial information for KYC and
                            anti-money laundering (AML) purposes.
                        </li>
                    </ul>
                    <h5>4. Investment Limits
                    </h5>
                    <p>The platform may establish minimum and maximum investment limits. These limits
                        may vary based on the type of loans and your account status.
                    </p>
                    <h5>5.  Acknowledgment of Risks
                    </h5>
                    <p>You acknowledge and understand that lending involves financial risks, including the
                        potential loss of your capital. You are solely responsible for evaluating and managing
                        these risks.

                    </p>
                    <h5>6. Interest Rates and Terms
                    </h5>
                    <p>Lender interest rates and loan terms may vary based on individual loans. You agree
                        to abide by the specific terms associated with each loan you choose to fund.
                    </p>
                    <h5>7. Fees and Commissions
                    </h5>
                    <p>The platform may charge fees or commissions for using its services, including but
                        not limited to origination fees, servicing fees, and withdrawal fees. These fees will be
                        clearly communicated to you.
                    </p>
                    <h5>8. Withdrawal and Liquidity Terms
                    </h5>
                    <p>Withdrawal of your funds may be subject to specific terms, such as lock-in periods,
                        withdrawal restrictions, and liquidity provisions.
                    </p>
                    <h5>9. Compliance with Applicable Laws
                    </h5>
                    <p>You are responsible for complying with all laws and regulations relevant to your
                        lending activities, including tax reporting and obligations.
                    </p>
                    <h5>10. Data Privacy and Security
                    </h5>
                    <p>We take data privacy and security seriously. Please refer to our Privacy Policy for
                        details on how we handle your personal and financial information.
                    </p>
                    <h5>11. Termination of the Agreement
                    </h5>
                    <p>This agreement may be terminated by either party under the conditions specified in
                        the full Terms and Conditions.
                    </p>
                    <h5>12. Change in Terms
                    </h5>
                    <p>We reserve the right to change these Terms. Notice of any changes will be provided
                        to you, and your continued use of the platform will indicate your acceptance of the
                        revised Terms.
                    </p>
                    <h5>13. Communication and Notifications
                    </h5>
                    <p>We will communicate with you through the contact information provided during
                        registration. It is your responsibility to keep your contact information updated.
                    </p>
                    <h5>14.  Dispute Resolution
                    </h5>
                    <p>Any disputes arising from this agreement will be resolved in accordance with the
                        dispute resolution process outlined in the full Terms and Conditions.
                    </p>
                </div>

                <div className='text-left mb-5'>
                    <h3 className='secondary-color'>12. Governing Law and Dispute Resolution:</h3>
                    <p>These Terms shall be governed by and construed in accordance with the laws of
                        Haryana. Any disputes arising out of or in connection with these Terms shall be
                        resolved through arbitration in accordance with the Rules of Arbitration and
                        Conciliation Act 1996.
                    </p>
                    <h5>1. Termination:
                    </h5>
                    <p> We reserve the right to suspend or terminate your access to our
                        Platform, in whole or in part, at any time and for any reason without prior notice or
                        liability.
                    </p>
                    <h5>2. Changes to the Terms:
                    </h5>
                    <p>We may update these Terms periodically to reflect changes in
                        our practices or legal requirements. The revised Terms will be posted on our
                        Platform, and the effective date will be updated accordingly. We encourage you to
                        review these Terms regularly for any updates.
                    </p>
                    <h5>3. Contact Information:
                    </h5>
                    <p>If you have any questions or concerns regarding our Terms or
                        the operation of our Platform, please contact us at contact@kivisa.in We will
                        address your inquiries to the best of our ability.
                    </p>
                </div>
            </div>
        </>
    )
}
export default TermsConditions